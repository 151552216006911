import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { injectStripe } from 'react-stripe-elements';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { PACKAGES_TYPES } from '../../models/packages/packages';
import styles from './ChangeCreditCardDetailsIfNeeded.scss';
import { CreditCardDetailsDialogWithValidation } from '../CreditCardDetailsDialog/CreditCardDetailsDialogWithValidation';

const logger = new MyEventWrapper(
  'change_credit_card_details_dialog_if_needed'
);

const ChangeCreditCardDetailsIfNeededBase = ({
  stripe,
  isFreePlan,
  onClose,
  defaultBlockedUsageCredit,
  onCreditCardUpdated,
}) => {
  const creditCardDetailsDialogRef = useRef(null);
  const [
    isChangeCreditCardDetailsDialogOpen,
    setIsChangeCreditCardDetailsDialogOpen,
  ] = useState(false);

  const [
    isCreditCardDetailsDialogOpen,
    setIsCreditCardDetailsDialogOpen,
  ] = useState(isFreePlan);

  const [
    creditCardDetailsRenderedStep,
    setCreditCardDetailsRenderedStep,
  ] = useState('cardDetailsInput');

  const [accountBalance, setAccountBalance] = useState(null);

  const [userPrice, setUserPrice] = useState(null);

  const onReEnterCreditCardDetails = useCallback(() => {
    logger.log('on_re_enter_credit_card_details');
    setIsCreditCardDetailsDialogOpen(true);
  }, []);

  const resetCreditCardDetailsDialogDefaultState = () => {
    creditCardDetailsDialogRef.current.resetDefaultState();
    setCreditCardDetailsRenderedStep('cardDetailsInput');
  };

  const onCreditCardDetailsDialogConfirm = useCallback(() => {
    logger.log('on_credit_card_details_dialog_confirm', { isFreePlan });
    if (creditCardDetailsRenderedStep === 'cardDetailsInput') {
      const handleSuccessfulRegisterPayment = async () => {
        setCreditCardDetailsRenderedStep('creditCardUpdatedSuccessfully');
      };

      const handleFailedRegisterPayment = () => {
        setCreditCardDetailsRenderedStep('creditCardWasNotUpdated');
      };

      setCreditCardDetailsRenderedStep('loading');
      stripe.createToken().then(async result => {
        if (result.error) {
          if (result.error.type === 'validation_error') {
            setCreditCardDetailsRenderedStep('cardDetailsInput');
          } else {
            handleFailedRegisterPayment();
          }
        } else {
          try {
            // eslint-disable-next-line no-undef
            const data = await fireFunctionPost(
              'billing-registerPaymentMethod',
              {
                details: result,
              }
            );

            if (data.result === true) {
              handleSuccessfulRegisterPayment();
            } else {
              handleFailedRegisterPayment();
            }
          } catch (err) {
            handleFailedRegisterPayment();
          }
        }
      });
    }

    if (creditCardDetailsRenderedStep === 'creditCardWasNotUpdated') {
      resetCreditCardDetailsDialogDefaultState();
    }

    if (creditCardDetailsRenderedStep === 'creditCardUpdatedSuccessfully') {
      setIsCreditCardDetailsDialogOpen(false);
      setIsChangeCreditCardDetailsDialogOpen(false);
      onClose();
      onCreditCardUpdated();
    }
  }, [creditCardDetailsRenderedStep, stripe]);

  useEffect(() => {
    const currentPackageDatabaseRef = database.ref(
      `billing/currentPackages/${sessionStorage.userId}`
    );

    const onCurrentPackageValue = snapshot => {
      const currentPackage = snapshot.val();
      if (
        currentPackage.packageType === PACKAGES_TYPES.HEADSET_PACKAGE &&
        sessionStorage.isDistributor !== 'true' &&
        !currentPackage.distributor &&
        !currentPackage.willMigrate
      ) {
        setIsChangeCreditCardDetailsDialogOpen(true);
      }
    };
    currentPackageDatabaseRef.once('value', onCurrentPackageValue);

    return () => currentPackageDatabaseRef.off('value', onCurrentPackageValue);
  }, []);

  useEffect(() => {
    (async () => {
      if (userPrice === null) {
        try {
          const dormantPlans = await database
            .ref('config/dormantPlans')
            .once('value')
            .then(snapshot => snapshot.val());

          const userPriceRef = await database
            .ref(
              `billing/packages/customNONFPackages/${dormantPlans.groupId}/${dormantPlans.packageIndex}/addOnOptions/nfAddOns/${dormantPlans.nfGroupId}/userPrice`
            )
            .once('value')
            .then(snapshot => snapshot.val());
          setUserPrice(userPriceRef);
        } catch (err) {
          setUserPrice(0);
        }
      }
    })();
  }, [userPrice]);

  useEffect(() => {
    (async () => {
      if (accountBalance === null) {
        try {
          // eslint-disable-next-line no-undef
          const { balance } = await fireFunctionPost('billing-accountBalance');
          setAccountBalance(balance);
        } catch (err) {
          setAccountBalance(0);
        }
      }
    })();
  }, [accountBalance]);

  return (
    stripe && (
      <React.Fragment>
        <Dialog open={isChangeCreditCardDetailsDialogOpen}>
          <DialogContent>
            <div>
              <h3 className={styles.dialog_header}>
                We have changed our billing system
              </h3>
              <p>
                Hey there, we'd appreciate it if you could re-enter your credit
                card details in order to continue using Myndlift. Don't worry,
                we won't change the way we charge or how much you pay.
              </p>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onReEnterCreditCardDetails}
              color="primary"
              variant="contained"
              autoFocus
            >
              <span className={styles.btn}>Re-enter credit card details</span>
            </Button>
          </DialogActions>
        </Dialog>

        <CreditCardDetailsDialogWithValidation
          isOpen={isCreditCardDetailsDialogOpen}
          renderedStep={creditCardDetailsRenderedStep}
          onConfirm={onCreditCardDetailsDialogConfirm}
          ref={creditCardDetailsDialogRef}
          // eslint-disable-next-line no-unneeded-ternary
          onClose={onClose ? onClose : () => {}}
          // eslint-disable-next-line no-unneeded-ternary
          onCancel={onClose ? onClose : () => {}}
          dialogHeader="Enter your credit card to continue"
          isSecondaryBtnShown
          isCloseIconShown={false}
          isFreePlan
          defaultBlockedUsageCredit={defaultBlockedUsageCredit}
          balance={accountBalance}
          userPrice={userPrice}
        />
      </React.Fragment>
    )
  );
};

ChangeCreditCardDetailsIfNeededBase.propTypes = exact({
  stripe: PropTypes.object,
  scriptsLoaded: PropTypes.bool,
});

export const ChangeCreditCardDetailsIfNeeded = React.memo(
  injectStripe(ChangeCreditCardDetailsIfNeededBase)
);
ChangeCreditCardDetailsIfNeeded.displayName = 'ChangeCreditCardDetailsIfNeeded';
