import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import { Paper, Popover, Checkbox, Switch, Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { EegGraph } from './EegGraph';
import eegData from './assessment_eeg.json';
import styles from './EegAnalysis.scss';

// const EegAnalysisLogger = new MyEventWrapper('eeg_analysis');

// const initialValue = Symbol('iv');

const EegAnalysisBase = () => {
  const [data, setData] = useState(null);
  const [channels, setChannels] = useState({});
  const [channelsToSelect, setChannelsToSelect] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModalState, setFilterModalState] = useState('');
  const [eyesClosedChecked, setEyesClosedChecked] = useState(true);
  const [eyesOpenChecked, setEyesOpenChecked] = useState(true);
  const [eyesClosedCheckedToSelect, setEyesClosedCheckedToSelect] = useState(
    true
  );
  const [eyesOpenCheckedToSelect, setEyesOpenCheckedToSelect] = useState(true);
  const [ticksLabel, setTicksLabel] = useState(null);
  const [ticks, setTicks] = useState([]);
  const [shouldLoadGraph, setShouldLoadGraph] = useState(false);

  const filterModalDisplayClicked = (modalName, e) => {
    setAnchorEl(e.currentTarget);
    if (filterModalState === modalName) {
      setFilterModalState('');
    } else {
      setFilterModalState(modalName);
    }
  };

  const clearChannelFilter = () => {
    const copyChannels = { ...channels };
    Object.keys(copyChannels).forEach(channel => {
      copyChannels[channel] = true;
    });
    setChannels(copyChannels);
    setChannelsToSelect(copyChannels);
  };

  const resetEyesStateFilter = () => {
    setEyesClosedChecked(true);
    setEyesClosedCheckedToSelect(true);
    setEyesOpenChecked(true);
    setEyesOpenCheckedToSelect(true);
  };

  useEffect(() => {
    const channelsArray = [];
    eegData.forEach(eegEntry => {
      if (channelsArray.indexOf(eegEntry.channel) === -1) {
        channelsArray.push(eegEntry.channel);
      }
    });
    const channelsCopy = {};
    channelsArray.forEach(channel => {
      channelsCopy[channel] = true;
    });
    setChannels(channelsCopy);
    setChannelsToSelect(channelsCopy);
  }, []);

  useEffect(() => {
    if (channels) {
      const graphData = [];
      const ticksData = [];
      const ticksLabelsData = {};
      let channelIndex = 0;
      eegData.forEach(eegObject => {
        if (
          channels[eegObject.channel] &&
          ((eegObject.state === 'Eyes Closed' && eyesClosedChecked) ||
            (eegObject.state === 'Eyes Open' && eyesOpenChecked))
        ) {
          ticksData.push(channelIndex * 150);
          ticksLabelsData[
            channelIndex * 150
          ] = `${eegObject.channel} ${eegObject.state} ${channelIndex}`;
          eegObject.eeg.forEach((eegVal, index) => {
            graphData.push({
              name: index,
              [`${eegObject.channel} ${eegObject.state} ${channelIndex}`]:
                eegVal + channelIndex * 150,
            });
          });
          channelIndex += 1;
        }
      });
      setData(graphData);
      setTicks(ticksData);
      setTicksLabel(ticksLabelsData);
    }
  }, [channels, eyesClosedChecked, eyesOpenChecked]);

  useEffect(() => {
    setTimeout(() => {
      setShouldLoadGraph(true);
    }, 1000);
  }, []);

  if (!data) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return (
    <div className={styles.page_container}>
      <Paper style={{ padding: 20 }}>
        <div
          className={styles.reset_filter_container}
          onClick={() => {
            clearChannelFilter();
            resetEyesStateFilter();
          }}
        >
          Reset Filters
        </div>
        <div className={styles.filters_container}>
          <div
            onClick={e => {
              filterModalDisplayClicked('channel-filter', e);
            }}
            className={styles.filter_btn}
            style={
              Object.keys(channels).find(channelName => !channels[channelName])
                ? { backgroundColor: '#2196f3', color: 'white' }
                : null
            }
          >
            Channel
            {Object.keys(channels).find(
              channelName => !channels[channelName]
            ) ? (
              <CloseIcon
                onClick={e => {
                  e.stopPropagation();
                  clearChannelFilter();
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="channel-filter"
            open={filterModalState === 'channel-filter'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setChannelsToSelect(channels);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <div className={styles.popover_title}>Channel</div>
            <div className={styles.popover_reset}>
              Select all
              <Switch
                color="primary"
                onClick={e => {
                  const copy = { ...channelsToSelect };
                  // eslint-disable-next-line array-callback-return
                  Object.keys(channelsToSelect).map(channel => {
                    copy[channel] = e.target.checked;
                  });
                  setChannelsToSelect(copy);
                }}
                // value={Object.keys(channelsToSelect).find(channel=> !channelsToSelect[channel])}
              />
            </div>
            {Object.keys(channelsToSelect).map(channel => {
              return (
                <div>
                  <Checkbox
                    onClick={() => {
                      const copy = { ...channelsToSelect };
                      copy[channel] = !channelsToSelect[channel];
                      setChannelsToSelect(copy);
                    }}
                    checked={!!channelsToSelect[channel]}
                    color="primary"
                  />
                  {channel}
                </div>
              );
            })}
            <div className={styles.filter_btn_container}>
              <Button
                onClick={() => {
                  setChannels(channelsToSelect);
                  setFilterModalState('');
                }}
                color="primary"
                variant="contained"
                style={{ fontSize: 14 }}
                disabled={
                  !Object.keys(channelsToSelect).find(
                    channel => channelsToSelect[channel] !== channels[channel]
                  )
                }
              >
                Apply
              </Button>
            </div>
          </Popover>
          <div
            onClick={e => {
              filterModalDisplayClicked('eyes-state', e);
            }}
            className={styles.filter_btn}
            style={
              eyesClosedChecked && eyesOpenChecked
                ? null
                : { backgroundColor: '#2196f3', color: 'white' }
            }
          >
            Eyes state
            {eyesClosedChecked && eyesOpenChecked ? (
              <ArrowDropDownIcon />
            ) : (
              <CloseIcon
                onClick={e => {
                  e.stopPropagation();
                  resetEyesStateFilter();
                }}
              />
            )}
          </div>
          <Popover
            id="eyes-state"
            open={filterModalState === 'eyes-state'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setEyesClosedCheckedToSelect(eyesClosedChecked);
              setEyesOpenCheckedToSelect(eyesOpenChecked);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <div className={styles.popover_title}>Eyes state</div>
            <div>
              <Checkbox
                onClick={() => {
                  setEyesClosedCheckedToSelect(!eyesClosedCheckedToSelect);
                }}
                checked={eyesClosedCheckedToSelect}
                color="primary"
              />
              Eyes closed
            </div>
            <div>
              <Checkbox
                onClick={() => {
                  setEyesOpenCheckedToSelect(!eyesOpenCheckedToSelect);
                }}
                checked={eyesOpenCheckedToSelect}
                color="primary"
              />
              Eyes open
            </div>
            <div className={styles.filter_btn_container}>
              <Button
                onClick={() => {
                  setEyesClosedChecked(eyesClosedCheckedToSelect);
                  setEyesOpenChecked(eyesClosedCheckedToSelect);
                  setFilterModalState('');
                }}
                color="primary"
                variant="contained"
                style={{ fontSize: 14 }}
                disabled={
                  eyesClosedCheckedToSelect === eyesClosedChecked &&
                  eyesOpenCheckedToSelect === eyesOpenChecked
                }
              >
                Apply
              </Button>
            </div>
          </Popover>
        </div>
      </Paper>
      {shouldLoadGraph ? (
        <EegGraph
          data={data}
          ticks={ticks}
          channels={channels}
          ticksLabel={ticksLabel}
        />
      ) : null}
    </div>
  );
};

EegAnalysisBase.propTypes = exact({});

export const EegAnalysis = React.memo(EegAnalysisBase);
EegAnalysis.displayName = 'EegAnalysis';
