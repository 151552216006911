import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Paper } from '@material-ui/core';
import { XAxis, YAxis, ResponsiveContainer, LineChart, Line } from 'recharts';
import { generateColors } from '../SessionToSessionReportGenerator/utils';
import styles from './EegAnalysis.scss';

const EegGraphBase = ({ data, ticks, channels, ticksLabel }) => {
  const colors = generateColors(ticks.length);
  const timeUnitsPerSec = 128;
  const xAxisTicks = [];
  for (let i = 0; i < 50; i += 1) {
    xAxisTicks.push(timeUnitsPerSec * i);
  }

  // eslint-disable-next-line consistent-return
  return (
    <div
      className={styles.graph_container}
      onScroll={e => {
        const axis = document.querySelector('.recharts-yAxis');

        // Get the parent of the <g> element (the <svg> element in this case)
        const parentElement = axis.parentNode;

        // Append the <g> element as the last child of its parent
        parentElement.appendChild(axis);

        // Create the <rect> element
        const rect = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'rect'
        );

        const yAxisheight = axis.getBoundingClientRect().height;

        const hasRect = axis.querySelectorAll('.y-axis-rect').length > 0;

        if (!hasRect) {
          // add the rect
          // Set the attributes for the <rect>
          rect.setAttribute('x', '-20');
          rect.setAttribute('y', '0');
          rect.setAttribute('width', 90);
          rect.setAttribute('height', yAxisheight);
          rect.setAttribute('fill', 'white');
          rect.setAttribute('class', 'y-axis-rect');
          // Insert the <rect> as the first child of the <g> element
          axis.insertBefore(rect, axis.firstChild);
        }

        // eslint-disable-next-line prefer-template
        axis.style = 'transform: translateX(' + e.target.scrollLeft + 'px);';
      }}
    >
      <Paper style={{ padding: 20, marginTop: 20, overflow: 'scroll' }}>
        <ResponsiveContainer
          height={ticks.length * 50}
          width={data.length / 25}
        >
          <LineChart
            data={data}
            // margin={{
            //     top: 10,
            //     right: 30,
            //     left: 20,
            //     bottom: 10,
            // }}
          >
            <XAxis
              dataKey="name"
              type="number"
              ticks={xAxisTicks}
              tickFormatter={tick => `${tick / 128}`}
            />

            <YAxis
              domain={[-150, Object.keys(channels).length + 1 * 150]}
              ticks={ticks}
              tickFormatter={value => ticksLabel[value] || value}
              // yAxisTickFormatter={tick => }
              // interval="preserveEnd"
              // unit={unit}
              // tickFormatter={yAxisTickFormatter}
            />
            {/* <Tooltip/> */}
            {Object.values(ticksLabel).map((channel, i) => {
              return (
                <Line
                  dataKey={channel}
                  stroke={colors[i]}
                  type="monotone"
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </div>
  );
};

EegGraphBase.propTypes = exact({
  data: PropTypes.object,
  ticks: PropTypes.array,
  channels: PropTypes.object,
  ticksLabel: PropTypes.object,
});

export const EegGraph = React.memo(EegGraphBase);
EegGraph.displayName = 'EegGraph';
