import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import {
  Paper,
  Popover,
  Checkbox,
  Switch,
  Button,
  CircularProgress,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import {
  getCurrentURLParams,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';
import { EegGraph } from './EegGraph';
// import eegData from './assessment_eeg.json';
import styles from './EegAnalysis.scss';

// const EegAnalysisLogger = new MyEventWrapper('eeg_analysis');

// const initialValue = Symbol('iv');
const EEG_POINTS_IN_SECOND = 128;

const EegAnalysisBase = () => {
  const [eegData, setEegData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [channels, setChannels] = useState({});
  const [channelsToSelect, setChannelsToSelect] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModalState, setFilterModalState] = useState('');
  const [eyesClosedChecked, setEyesClosedChecked] = useState(true);
  const [eyesOpenChecked, setEyesOpenChecked] = useState(true);
  const [eyesClosedCheckedToSelect, setEyesClosedCheckedToSelect] = useState(
    true
  );
  const [eyesOpenCheckedToSelect, setEyesOpenCheckedToSelect] = useState(true);
  const [options, setOptions] = useState(null);
  const [labels, setLabels] = useState({});
  const [viewport, setViewport] = useState({
    minimum: 0,
    maximum: EEG_POINTS_IN_SECOND * 4,
  });

  const filterModalDisplayClicked = (modalName, e) => {
    setAnchorEl(e.currentTarget);
    if (filterModalState === modalName) {
      setFilterModalState('');
    } else {
      setFilterModalState(modalName);
    }
  };

  const clearChannelFilter = () => {
    const copyChannels = { ...channels };
    Object.keys(copyChannels).forEach(channel => {
      copyChannels[channel] = true;
    });
    setChannels(copyChannels);
    setChannelsToSelect(copyChannels);
  };

  const resetEyesStateFilter = () => {
    setEyesClosedChecked(true);
    setEyesClosedCheckedToSelect(true);
    setEyesOpenChecked(true);
    setEyesOpenCheckedToSelect(true);
  };

  const onScroll = e => {
    const { deltaX } = e; // Get the horizontal scroll amount
    const newMin = (viewport.minimum || 0) + deltaX;
    const newMax = (viewport.maximum || 100) + deltaX;
    if (newMin > 0 && newMax < 60 * EEG_POINTS_IN_SECOND) {
      setViewport({ minimum: newMin, maximum: newMax });
    }
  };

  useEffect(() => {
    (async () => {
      const { customerId, assessmentId } = getCurrentURLParams();
      try {
        const res = await postToFunctionsWithDefaultData({
          path: 'get_assessment_eeg',
          data: {
            userId: sessionStorage.userId,
            patientId: customerId,
            assessmentId,
          },
        });
        setEegData(res);
        setIsFetchingData(false);
      } catch (e) {
        setIsFetchingData(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    const channelsArray = [];
    eegData.forEach(eegEntry => {
      if (channelsArray.indexOf(eegEntry.channel) === -1) {
        channelsArray.push(eegEntry.channel);
      }
    });
    const channelsCopy = {};
    channelsArray.forEach(channel => {
      channelsCopy[channel] = true;
    });
    setChannels(channelsCopy);
    setChannelsToSelect(channelsCopy);
  }, [eegData]);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    let channelIndex = 0;
    const labelsObj = {};
    eegData.forEach(eegObject => {
      if (
        channels[eegObject.channel] &&
        ((eegObject.state === 'Eyes Closed' && eyesClosedChecked) ||
          (eegObject.state === 'Eyes Open' && eyesOpenChecked))
      ) {
        labelsObj[
          channelIndex * 150
        ] = `${eegObject.channel} ${eegObject.state} ${channelIndex}`;
        channelIndex += 1;
      }
    });
    setLabels(labelsObj);
  }, [channels, eyesClosedChecked, eyesOpenChecked]);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    const optionsObj = {
      zoomEnabled: true,
      //   zoomType: "xy",
      height: Object.keys(labels).length
        ? 100 + Object.keys(labels).length * 50
        : 100 + eegData.length * 50,
      //   title: {
      //     text: 'Raw EEG',
      //     fontSize: 18,
      //   },
      toolTip: {
        contentFormatter: e => {
          return `${e.entries[0].dataSeries.name.slice(0, -2)}, ${(
            e.entries[0].dataPoint.x / EEG_POINTS_IN_SECOND
          ).toFixed(2)} seconds`;
        },
      },
      axisY: {
        title: 'Channels',
        titleFontSize: 16,
        interval: 150,
        labelFormatter: e => {
          const { value } = e;
          return labels[value] ? labels[value].slice(0, -2) : '';
        },
        labelFontSize: 12,
        gridThickness: 0,
      },
      axisX: {
        title: 'Seconds',
        titleFontSize: 16,
        interval: EEG_POINTS_IN_SECOND,
        labelFormatter: e => {
          const { value } = e;
          return value / EEG_POINTS_IN_SECOND;
        },
        labelFontSize: 12,
        scrollbar: {
          enabled: true, // Enable the scrollbar on the X-axis
        },
        viewportMinimum: viewport.minimum,
        viewportMaximum: viewport.maximum,
      },
      data: [],
    };
    const labelsObj = {};
    let channelIndex = 0;

    eegData.forEach(eegObject => {
      if (
        channels[eegObject.channel] &&
        ((eegObject.state === 'Eyes Closed' && eyesClosedChecked) ||
          (eegObject.state === 'Eyes Open' && eyesOpenChecked))
      ) {
        const newDataEntry = {
          type: 'line',
          name: `${eegObject.channel} ${eegObject.state} ${channelIndex}`,
          showInLegend: false,
          dataPoints: [],
          yValueFormatString: `${eegObject.channel} ${eegObject.state} ${channelIndex}`,
        };
        eegObject.eeg.forEach((eegVal, index) => {
          newDataEntry.dataPoints.push({
            x: index,
            y: eegVal + channelIndex * 150,
          });
        });
        optionsObj.data.push(newDataEntry);
        labelsObj[
          channelIndex * 150
        ] = `${eegObject.channel} ${eegObject.state} ${channelIndex}`;
        channelIndex += 1;
      }
    });
    setOptions(optionsObj);
  }, [labels, channels, eyesClosedChecked, eyesOpenChecked, viewport, eegData]);

  if (isFetchingData) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
        <div style={{ marginTop: 10 }}>Fetching data</div>
      </div>
    );
  }

  if (!isFetchingData && (!eegData || !eegData.length)) {
    return <div style={{ textAlign: 'center' }}>No eeg records found</div>;
  }
  // eslint-disable-next-line consistent-return
  return (
    <div className={styles.page_container}>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <div
          className={styles.reset_filter_container}
          onClick={() => {
            clearChannelFilter();
            resetEyesStateFilter();
          }}
        >
          Reset Filters
        </div>
        <div className={styles.filters_container}>
          <div
            onClick={e => {
              filterModalDisplayClicked('channel-filter', e);
            }}
            className={styles.filter_btn}
            style={
              Object.keys(channels).find(channelName => !channels[channelName])
                ? { backgroundColor: '#2196f3', color: 'white' }
                : null
            }
          >
            Channel
            {Object.keys(channels).find(
              channelName => !channels[channelName]
            ) ? (
              <CloseIcon
                onClick={e => {
                  e.stopPropagation();
                  clearChannelFilter();
                }}
              />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          <Popover
            id="channel-filter"
            open={filterModalState === 'channel-filter'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setChannelsToSelect(channels);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <div className={styles.popover_title}>Channel</div>
            <div className={styles.popover_reset}>
              Select all
              <Switch
                color="primary"
                onClick={e => {
                  const copy = { ...channelsToSelect };
                  // eslint-disable-next-line array-callback-return
                  Object.keys(channelsToSelect).map(channel => {
                    copy[channel] = e.target.checked;
                  });
                  setChannelsToSelect(copy);
                }}
                // value={Object.keys(channelsToSelect).find(channel=> !channelsToSelect[channel])}
              />
            </div>
            {Object.keys(channelsToSelect).map(channel => {
              return (
                <div>
                  <Checkbox
                    onClick={() => {
                      const copy = { ...channelsToSelect };
                      copy[channel] = !channelsToSelect[channel];
                      setChannelsToSelect(copy);
                    }}
                    checked={!!channelsToSelect[channel]}
                    color="primary"
                  />
                  {channel}
                </div>
              );
            })}
            <div className={styles.filter_btn_container}>
              <Button
                onClick={() => {
                  setChannels(channelsToSelect);
                  setFilterModalState('');
                }}
                color="primary"
                variant="contained"
                style={{ fontSize: 14 }}
                disabled={
                  !Object.keys(channelsToSelect).find(
                    channel => channelsToSelect[channel] !== channels[channel]
                  )
                }
              >
                Apply
              </Button>
            </div>
          </Popover>
          <div
            onClick={e => {
              filterModalDisplayClicked('eyes-state', e);
            }}
            className={styles.filter_btn}
            style={
              eyesClosedChecked && eyesOpenChecked
                ? null
                : { backgroundColor: '#2196f3', color: 'white' }
            }
          >
            Eyes state
            {eyesClosedChecked && eyesOpenChecked ? (
              <ArrowDropDownIcon />
            ) : (
              <CloseIcon
                onClick={e => {
                  e.stopPropagation();
                  resetEyesStateFilter();
                }}
              />
            )}
          </div>
          <Popover
            id="eyes-state"
            open={filterModalState === 'eyes-state'}
            anchorEl={anchorEl}
            onClose={() => {
              setFilterModalState('');
              setEyesClosedCheckedToSelect(eyesClosedChecked);
              setEyesOpenCheckedToSelect(eyesOpenChecked);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ paper: styles.popover_paper }}
          >
            <div className={styles.popover_title}>Eyes state</div>
            <div>
              <Checkbox
                onClick={() => {
                  setEyesClosedCheckedToSelect(!eyesClosedCheckedToSelect);
                }}
                checked={eyesClosedCheckedToSelect}
                color="primary"
              />
              Eyes closed
            </div>
            <div>
              <Checkbox
                onClick={() => {
                  setEyesOpenCheckedToSelect(!eyesOpenCheckedToSelect);
                }}
                checked={eyesOpenCheckedToSelect}
                color="primary"
              />
              Eyes open
            </div>
            <div className={styles.filter_btn_container}>
              <Button
                onClick={() => {
                  setEyesClosedChecked(eyesClosedCheckedToSelect);
                  setEyesOpenChecked(eyesOpenCheckedToSelect);
                  setFilterModalState('');
                }}
                color="primary"
                variant="contained"
                style={{ fontSize: 14 }}
                disabled={
                  eyesClosedCheckedToSelect === eyesClosedChecked &&
                  eyesOpenCheckedToSelect === eyesOpenChecked
                }
              >
                Apply
              </Button>
            </div>
          </Popover>
        </div>
      </Paper>

      <EegGraph options={options} onScroll={onScroll} />
    </div>
  );
};

EegAnalysisBase.propTypes = exact({});

export const EegAnalysis = React.memo(EegAnalysisBase);
EegAnalysis.displayName = 'EegAnalysis';
