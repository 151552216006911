import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import nth from 'lodash/nth';
import get from 'lodash/get';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import {
  Button,
  Paper,
  // Select,
  // MenuItem,
  // FormControl,
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import DownloadIcon from '@material-ui/icons/GetApp';
import { EmptyAssessmentsTab } from './EmptyAssessmentsTab/EmptyAssessmentsTab';
import { withEntryComponent } from '../../../Core/hocs/withEntryComponent/withEntryComponent';
import {
  ScheduleAssessmentDialog,
  SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS,
} from './ScheduleAssessmentDialog/ScheduleAssessmentDialog';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { DoneAssessmentsTable } from './DoneAssessmentsTable/DoneAssessmentsTable';
import styles from './AssessmentsTab.scss';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { ScheduledAssessmentCard } from './ScheduledAssessmentCard/ScheduledAssessmentCard';
import {
  ConfirmCancelingScheduledAssessmentDialog,
  confirmCancelingScheduledAssessmentDialogSteps,
} from './ConfirmCancelingScheduledAssessmentDialog/ConfirmCancelingScheduledAssessmentDialog';
import {
  ConfirmDeletingDoneAssessment,
  confirmDeletingDoneAssessmentDialogRenderedSteps,
} from './ConfirmDeletingDoneAssessment/ConfirmDeletingDoneAssessment';
import { defaultAfterTrainingAssessmentIntervalMinutes } from '../../../models/assessments/assessments';
import { useClinicInfo } from '../../../Core/hooks/useClinicInfo';
import {
  safeParseDate,
  getCurrentURLParams,
  isDeepEqualWithTrueForFunctions,
} from '../../../utils/utils';
import { BlurOverlayIfContinuityCustomer } from '../BlurOverlayIfContinuityCustomer/BlurOverlayIfContinuityCustomer';
import { SymptomTrackerTab } from '../SymptomTrackerTab/SymptomTrackerTab';
import { isAnEEGRegionStage } from '../../../models/symptom-tracker/symptom-tracker';
import {
  androidVersionCompatiableWithSymptomQuestionnaires,
  iOSVersionCompatiableWithSymptomQuestionnaires,
} from '../../../models/symptom-tracker/new-symptom-tracker-app-versions';
import { NeuroPromoDialog } from '../../NeuroPromoDialog/NeuroPromoDialog';
import { OverallSymptomsTrackingGraph } from '../../SessionToSessionReportGenerator/OverallSymptomsTrackingGraph/OverallSymptomsTrackingGraph';

const deployedAssessmentInitialValue = Symbol('IV');

const getDefaultAssessmentScheduleState = assessmentStages => ({
  recurring: false,
  beforeNextSession: true,
  afterTrainingDuration: defaultAfterTrainingAssessmentIntervalMinutes,
  selectedStages: Object.keys(assessmentStages),
  frequency: 0,
  intervalType: 'weeks',
  isAssessmentOpen: true,
  stagesType: 'stages',
});

const doneAssessmentsInitialValue = Symbol('IV');
const didClinicSetupAssessmentForThisCustomerBeforeInitialValue = Symbol('IV');
const userPageLogger = new MyEventWrapper('user_page');

const SCHEDULE_ASSESSMENT_DIALOG_OPENERS = {
  add: 'add',
  edit: 'edit',
};

const AssessmentsTabBase = ({
  assessmentStagesInfo,
  customerId,
  headsetDetails,
  onHeadsetDetailsUpdated,
  customerInfo,
  setCustomerInfo,
  deployedQuestionnaires,
  allSymptomTrackerQuestions,
  customerSymptomTrackerQuestions,
  symptomsTrackingAnswers,
  onDownloadSymptomsTrackingDataAsCSV,
  onDownloadOverallSymptomsTrackingDataAsCSV,
  shouldUseNewSymptomTracker,
  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick,
  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick,
  isSwitchToNewSymptomTrackerDialogOpen,
  onSwitchToNewSymptomTrackerDialogClose,
  onSetupSymptomTracker,
  onSetupQuestionnaires,
  questionnairesShouldBeEnabledInScheduleAssessmentDialog,
  isCustomerAppCompatiableWithSymptomQuestionnaires,
  switchToNewSymptomTracker,
  allQuestionnariesData,
}) => {
  const t = useTranslation();
  const clinicInfo = useClinicInfo();
  const { tab } = getCurrentURLParams();

  const [
    scheduleAssessmentDialogOpener,
    setIsScheduleAssessmentDialogOpener,
  ] = useState(null);

  const [
    scheduleAssessmentDialogRenderedStep,
    setScheduleAssessmentDialogRenderedStep,
  ] = useState(SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions);

  const [
    isConfirmCancelingScheduledAssessmentDialogOpen,
    setIsConfirmCancelingScheduledAssessmentDialogOpen,
  ] = useState(false);
  const [cancelAssessmentDialogStep, setCancelAssessmentDialogStep] = useState(
    null
  );

  const [
    isAnimatingScheduleAssessmentCard,
    setIsAnimatingScheduleAssessmentCard,
  ] = useState(false);

  const [
    isAnimatingDoneAssessmentsTable,
    setIsAnimatingDoneAssessmentsTable,
  ] = useState(false);

  const [
    selectedAssessmentIdToBeDeleted,
    setSelectedAssessmentIdToBeDeleted,
  ] = useState(null);

  const [
    confirmDeletingDoneAssessmentRenderedStep,
    setConfirmDeletingDoneAssessmentRenderedStep,
  ] = useState(null);

  const [deployedAssessment, setDeployedAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${customerId}`,
    initialValue: deployedAssessmentInitialValue,
  });

  const [minsUsage] = useFirebaseDB({
    path: `achievements/usages/${customerId}/totalUsage/minsUsage`,
    defaultValue: 0,
  });

  const [numSessions] = useFirebaseDB({
    path: `achievements/usages/${customerId}/totalUsage/numOfSessions`,
    defaultValue: 0,
  });
  const [userType] = useFirebaseDB({
    path: `clinics/${clinicInfo.id}/currentSessions/${customerId}/status/text`,
  });

  const [doneAssessments] = useFirebaseDB({
    path: `assessments/results/${customerId}`,
    initialValue: doneAssessmentsInitialValue,
  });

  const [currentAssessmentStatus] = useFirebaseDB({
    path: `assessments/currentStage/${customerId}`,
  });

  const [isProcessingAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${customerId}/isProcessing`,
  });

  const [isHeadsetOnlyAvailable] = useFirebaseDB({
    path: `clinicConfig/headsetAssessmentsSensing/${sessionStorage.userId}/Tp9`,
  });

  const [brainDisorders] = useFirebaseDB({
    path: 'brainDisorders',
    onceListener: true,
  });

  const [selectedBrainDisorders, setSelectedBrainDisorders] = useFirebaseDB({
    path: `brainDisordersSymptoms/${customerId}`,
  });

  const [
    didClinicSetupAssessmentForThisCustomerBefore,
    setDidClinicSetupAssessmentForThisCustomerBefore,
  ] = useFirebaseDB({
    path: `assessments/didClinicSetupAssessmentForThisCustomerBefore/${clinicInfo.id}/${customerId}`,
    initialValue: didClinicSetupAssessmentForThisCustomerBeforeInitialValue,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${clinicInfo.id}/nfAddOnPlanId`,
    onceListener: true,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${clinicInfo.id}/packageType`,
    onceListener: true,
  });

  const [usedAssessmentBeforeJune24] = useFirebaseDB({
    path: `clinicConfig/assessments/usedAssessmentBeforeJune24/${clinicInfo.id}`,
    onceListener: true,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  const [customerGender, setCustomerGender] = useState(null);
  const [customerDateOfBirth, setCustomerDateOfBirth] = useState(null);

  const [
    selectedBrainDisordersInEdit,
    setSelectedBrainDisordersInEdit,
  ] = useState(null);

  const [
    scheduleAssessmentDialogErrorMsg,
    setScheduleAssessmentDialogErrorMsg,
  ] = useState(null);

  const [addClientReachedLimitModal, setAddClientReachedLimitModal] = useState(
    false
  );

  const [
    filteredOverallQuestionnaires,
    setFilteredOverallQuestionnaires,
  ] = useState([]);
  const [allOverallQuestionnaires, setAllOverallQuestionnaires] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [
    filteredSymptomsTrackingAnswers,
    setFilteredSymptomsTrackingAnswers,
  ] = useState(symptomsTrackingAnswers);

  useEffect(() => {
    if (doneAssessments !== doneAssessmentsInitialValue) {
      const symptomsTrackingAnswersCopy = JSON.parse(
        JSON.stringify(symptomsTrackingAnswers)
      );
      Object.keys(symptomsTrackingAnswers).forEach(answerId => {
        if (
          symptomsTrackingAnswers &&
          symptomsTrackingAnswers[answerId] &&
          symptomsTrackingAnswers[answerId].assessmentId &&
          doneAssessments[symptomsTrackingAnswers[answerId].assessmentId] &&
          doneAssessments[symptomsTrackingAnswers[answerId].assessmentId]
            .isDeleted
        ) {
          delete symptomsTrackingAnswersCopy[answerId];
        }
      });
      if (
        !isDeepEqualWithTrueForFunctions(
          symptomsTrackingAnswersCopy,
          filteredSymptomsTrackingAnswers
        )
      ) {
        setFilteredSymptomsTrackingAnswers(symptomsTrackingAnswersCopy);
      }
    }
  }, [filteredOverallQuestionnaires]);

  useEffect(() => {
    const questionnairesToDisplay = [];
    const graphDataArr = Object.keys(filteredSymptomsTrackingAnswers).map(
      answerId => {
        // eslint-disable-next-line no-unused-expressions
        filteredSymptomsTrackingAnswers[answerId].totalScores &&
          Object.keys(
            filteredSymptomsTrackingAnswers[answerId].totalScores
          ).forEach(questionnireId => {
            questionnairesToDisplay.push(questionnireId);
          });
        return {
          timestamp: +filteredSymptomsTrackingAnswers[answerId].timestamp,
          ...filteredSymptomsTrackingAnswers[answerId].totalScores,
        };
      }
    );
    setGraphData(graphDataArr.sort((a, b) => a.timestamp - b.timestamp));
    const questionnairesToDisplaySet = [...new Set(questionnairesToDisplay)];
    // setFilteredOverallQuestionnaires([
    //   questionnairesToDisplaySet.find(
    //     id =>
    //       allOverallQuestionnaires[id] &&
    //       !allOverallQuestionnaires[id].hideResult
    //   ),
    // ]);
    const allFilteredQuestioners = questionnairesToDisplaySet.filter(
      questioner => !allOverallQuestionnaires[questioner]?.hideResult
    );
    setFilteredOverallQuestionnaires(allFilteredQuestioners);
    const allQuestionnariesFilteredData = {};
    questionnairesToDisplaySet.forEach(questionnaireId => {
      allQuestionnariesFilteredData[questionnaireId] =
        allQuestionnariesData[questionnaireId];
      allQuestionnariesFilteredData[questionnaireId].id = questionnaireId;
    });
    setAllOverallQuestionnaires(allQuestionnariesFilteredData);
  }, [doneAssessments, filteredSymptomsTrackingAnswers]);

  const onSelectedQuestionnairesDataChange = event => {
    if (event.target.value.length) {
      setFilteredOverallQuestionnaires(event.target.value);
    }
  };

  const onViewPricingPlansClicked = async () => {
    await new MyEvent('view_pricing_plans_clicked').log({
      from_where: 'assessment',
    });
    window.open('new_account.html', '_blank');
  };

  const updateBrainDisordersInEditBasedOnSelectedBrainDisorders = useCallback(() => {
    if (selectedBrainDisorders) {
      setSelectedBrainDisordersInEdit(Object.keys(selectedBrainDisorders));
    } else {
      setSelectedBrainDisordersInEdit([]);
    }
  }, [selectedBrainDisorders]);

  useEffect(() => {
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
  }, [updateBrainDisordersInEditBasedOnSelectedBrainDisorders]);

  useEffect(() => {
    if (customerInfo) {
      setCustomerGender(customerInfo.privateDetails.gender);
      setCustomerDateOfBirth(
        safeParseDate({
          str: get(customerInfo, 'privateDetails.dateOfBirth'),
        })
      );
    }
  }, [customerInfo]);

  const onDisorderClick = useCallback(
    disorder => {
      let newSelectedBrainDisorders;
      if (selectedBrainDisordersInEdit.includes(disorder)) {
        newSelectedBrainDisorders = selectedBrainDisordersInEdit.filter(
          selectedDisorder => selectedDisorder !== disorder
        );
      } else {
        newSelectedBrainDisorders = [...selectedBrainDisordersInEdit, disorder];
      }

      setSelectedBrainDisordersInEdit(newSelectedBrainDisorders);
    },
    [selectedBrainDisordersInEdit]
  );

  const doneAssessmentsSortedByDecreasingDate =
    doneAssessments !== doneAssessmentsInitialValue &&
    fromPairs(toPairs(doneAssessments).reverse());

  const sortedReadyDoneAssessments = pickBy(
    doneAssessmentsSortedByDecreasingDate,
    assessment => assessment.isReady && !assessment.isDeleted
  );

  const doesDoneAssessmentsHasData = !isEmpty(sortedReadyDoneAssessments);

  const isComponentReadyToRender =
    deployedAssessment !== deployedAssessmentInitialValue &&
    doneAssessments !== doneAssessmentsInitialValue &&
    didClinicSetupAssessmentForThisCustomerBefore !==
      didClinicSetupAssessmentForThisCustomerBeforeInitialValue;

  const shouldShowEmptyAssessmentsTab =
    !doesDoneAssessmentsHasData &&
    !deployedAssessment &&
    isEmpty(symptomsTrackingAnswers);

  const allAssessmentStages = customerInfo.shouldUseNewSymptomTracker
    ? assessmentStagesInfo.names
    : omit(assessmentStagesInfo.names, assessmentStagesInfo.ids.QUESTIONNAIRES);
  const [assessmentScheduleState, setAssessmentScheduleState] = useState(
    getDefaultAssessmentScheduleState(allAssessmentStages)
  );
  const [
    areScheduleAssessmentDialogEEGRegionsEnabled,
    setAreScheduleAssessmentDialogEEGRegionsEnabled,
  ] = useState(true);

  useEffect(() => {
    if (
      deployedAssessment &&
      deployedAssessment !== deployedAssessmentInitialValue
    ) {
      setAssessmentScheduleState(deployedAssessment);
      setAreScheduleAssessmentDialogEEGRegionsEnabled(
        (deployedAssessment.stagesType === 'headsetStages' &&
          !!deployedAssessment.selectedStages.find(
            stage => stage === 'Headset'
          )) ||
          (deployedAssessment.selectedStages || []).some(stage =>
            Object.values(assessmentStagesInfo.eegIds).includes(stage)
          )
      );
    }
  }, [deployedAssessment, assessmentStagesInfo]);

  const setSelectedStages = useCallback(
    selectedStages =>
      setAssessmentScheduleState(prvAssessmentScheduleState => ({
        ...prvAssessmentScheduleState,
        selectedStages,
      })),
    []
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      questionnairesShouldBeEnabledInScheduleAssessmentDialog &&
      !(assessmentScheduleState.selectedStages || []).includes(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      )
    ) {
      const prvSelectedStages = assessmentScheduleState.selectedStages || [];
      const newSelectedStages = prvSelectedStages.includes(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      )
        ? prvSelectedStages
        : [assessmentStagesInfo.ids.QUESTIONNAIRES, ...prvSelectedStages];
      return setSelectedStages(newSelectedStages);
    }
  }, [
    assessmentScheduleState,
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setSelectedStages,
    assessmentStagesInfo,
  ]);

  const onSelectedStagesChange = useCallback(
    newSelectedStages => {
      setSelectedStages(newSelectedStages);
    },
    [setSelectedStages]
  );

  const onAfterTrainingDurationChange = useCallback(
    event =>
      setAssessmentScheduleState({
        ...assessmentScheduleState,
        afterTrainingDuration: parseInt(event.target.value, 10),
      }),
    [assessmentScheduleState]
  );

  const onAddAssessment = useCallback(() => {
    userPageLogger.log('on_set_recurring_assessment', { customerId });
    if (shouldShowEmptyAssessmentsTab) {
      switchToNewSymptomTracker();
    } else if (
      !customerInfo.shouldUseNewSymptomTracker &&
      didClinicSetupAssessmentForThisCustomerBefore === null
    ) {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    } else {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    }
  }, [
    customerInfo,
    customerId,
    didClinicSetupAssessmentForThisCustomerBefore,
    shouldShowEmptyAssessmentsTab,
    switchToNewSymptomTracker,
  ]);

  const resetScheduleAssessmentDialogState = useCallback(() => {
    if (deployedAssessment) {
      setAssessmentScheduleState(deployedAssessment);
      if (
        deployedAssessment.selectedStages.some(stage =>
          isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
        )
      ) {
        setAreScheduleAssessmentDialogEEGRegionsEnabled(true);
      } else {
        setAreScheduleAssessmentDialogEEGRegionsEnabled(false);
      }
    } else {
      setAssessmentScheduleState(
        getDefaultAssessmentScheduleState(allAssessmentStages)
      );
    }

    setCustomerGender(customerInfo.privateDetails.gender);
    setCustomerDateOfBirth(
      safeParseDate({
        str: get(customerInfo, 'privateDetails.dateOfBirth'),
      })
    );
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
    setScheduleAssessmentDialogErrorMsg(null);
  }, [
    deployedAssessment,
    customerInfo,
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders,
    allAssessmentStages,
  ]);
  // eslint-disable-next-line consistent-return
  const onScheduleAssessmentDialogSecondaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_secondary_btn_click', {
      customerId,
      scheduleAssessmentDialogRenderedStep,
    });
    if (
      didClinicSetupAssessmentForThisCustomerBefore ||
      customerInfo.shouldUseNewSymptomTracker
    ) {
      setIsScheduleAssessmentDialogOpener(null);
      resetScheduleAssessmentDialogState();
    } else {
      setScheduleAssessmentDialogErrorMsg(null);
      if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
      } else if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
        );
      } else {
        setIsScheduleAssessmentDialogOpener(null);
        resetScheduleAssessmentDialogState();
      }
    }
    // if (tab === 'assessments') {
    //   return window.open(`customers.html`, '_self');
    // }
  }, [
    customerId,
    scheduleAssessmentDialogRenderedStep,
    didClinicSetupAssessmentForThisCustomerBefore,
    customerInfo,
    resetScheduleAssessmentDialogState,
  ]);

  const sortSelectedStages = useCallback(
    (stages, stagesType) => {
      return [
        ...(stages.includes(assessmentStagesInfo.ids.QUESTIONNAIRES)
          ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
          : []),
        // eslint-disable-next-line  no-nested-ternary
        ...(stagesType === 'headsetStages'
          ? areScheduleAssessmentDialogEEGRegionsEnabled
            ? ['Headset']
            : []
          : stages
              .filter(stage =>
                isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
              )
              .sort((stage1, stage2) => {
                const sortedEEGIds = Object.values(assessmentStagesInfo.eegIds);
                return (
                  sortedEEGIds.indexOf(stage1) - sortedEEGIds.indexOf(stage2)
                );
              })),
        ...(stages.includes(assessmentStagesInfo.ids.CPT)
          ? [assessmentStagesInfo.ids.CPT]
          : []),
      ];
    },
    [assessmentStagesInfo, areScheduleAssessmentDialogEEGRegionsEnabled]
  );

  let filteredDoneAssessments;
  if (doneAssessments == null) {
    filteredDoneAssessments = [];
  } else {
    filteredDoneAssessments = Object.values(doneAssessments).filter(
      doneAssessment => !doneAssessment.isDeleted
    );
  }

  const handleScheduleAssessmentDialogPrimaryBtnClickEditMode = useCallback(() => {
    if (!assessmentScheduleState.recurring) {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        numSessions,
        creationDate: Date.now(),
        beforeNextSession: true,
        isAssessmentOpen: true,
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    } else {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        beforeNextSession:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : assessmentScheduleState.beforeNextSession,
        isAssessmentOpen:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : assessmentScheduleState.isAssessmentOpen,
        numSessions,
        creationDate: Date.now(),
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    }

    setIsScheduleAssessmentDialogOpener(null);
  }, [
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);

  const handleScheduleAssessmentDialogPrimaryBtnClickAddMode = useCallback(() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
    ) {
      if (selectedBrainDisordersInEdit.length > 0) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg(
          'Please select at least one disorder tag'
        );
      }
    } else if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
    ) {
      if (customerDateOfBirth) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg('Date of birth is required');
      }
    } else {
      userPageLogger.log('on_schedule_assessment_for_customer', {
        customerId,
        minsUsage,
        ...assessmentScheduleState,
        scheduleAssessmentDialogRenderedStep,
      });
      if (!assessmentScheduleState.recurring) {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          beforeNextSession: true,
          isAssessmentOpen: true,
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      } else {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          beforeNextSession:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.beforeNextSession,
          isAssessmentOpen:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.isAssessmentOpen,
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      }

      setDidClinicSetupAssessmentForThisCustomerBefore(true);
      setSelectedBrainDisorders(
        selectedBrainDisordersInEdit.reduce(
          (acc, brainDisorder) => ({
            ...acc,
            [brainDisorder]: true,
          }),
          {}
        )
      );
      setCustomerInfo({
        ...customerInfo,
        privateDetails: {
          ...customerInfo.privateDetails,
          dateOfBirth: format(customerDateOfBirth, 'yyyy-MM-dd'),
          gender: customerGender,
        },
      });

      setIsScheduleAssessmentDialogOpener(null);
    }
  }, [
    scheduleAssessmentDialogRenderedStep,
    selectedBrainDisordersInEdit,
    customerDateOfBirth,
    customerId,
    minsUsage,
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    setDidClinicSetupAssessmentForThisCustomerBefore,
    setSelectedBrainDisorders,
    setCustomerInfo,
    customerInfo,
    customerGender,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);
  // eslint-disable-next-line consistent-return
  const onScheduleAssessmentDialogPrimaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_primary_btn_click', {
      customerId,
      minsUsage,
      ...assessmentScheduleState,
      scheduleAssessmentDialogRenderedStep,
      entered_from: 'userPage.html',
      modification:
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
          ? 'edit'
          : 'add',
    });
    if (
      scheduleAssessmentDialogOpener === SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    ) {
      handleScheduleAssessmentDialogPrimaryBtnClickEditMode();
    }
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode();
    // if (tab === 'assessments') {
    //   return window.open(`customers.html`, '_self');
    // }
  }, [
    scheduleAssessmentDialogOpener,
    handleScheduleAssessmentDialogPrimaryBtnClickEditMode,
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode,
    customerId,
    scheduleAssessmentDialogRenderedStep,
    assessmentScheduleState,
    minsUsage,
  ]);

  const deleteAssessment = useCallback(assessmentId => {
    // eslint-disable-next-line no-undef
    return fireFunctionPost('removeAssessment', { assessmentId });
  }, []);

  const onDeleteAssessment = useCallback(
    assessmentId => {
      userPageLogger.log('on_delete_assessment', { assessmentId, customerId });
      setSelectedAssessmentIdToBeDeleted(assessmentId);
      setConfirmDeletingDoneAssessmentRenderedStep(
        confirmDeletingDoneAssessmentDialogRenderedSteps.promptForDeletingAssessment
      );
    },
    [customerId]
  );

  const isScheduleAssessmentDialogPrimaryBtnDisabled = (() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
    ) {
      if (
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      ) {
        return (
          (assessmentScheduleState.recurring &&
            (!assessmentScheduleState.frequency ||
              assessmentScheduleState.frequency < 1 ||
              !assessmentScheduleState.intervalType)) ||
          (assessmentScheduleState.selectedStages || []).length === 0
        );
      }

      return (
        !assessmentScheduleState.frequency ||
        assessmentScheduleState.frequency < 1 ||
        (assessmentScheduleState.selectedStages || []).length === 0
      );
    }

    return false;
  })();

  const questionnairesStageDisabledReason = (() => {
    if (!isCustomerAppCompatiableWithSymptomQuestionnaires) {
      return `In order to be able to select questionnaires the user needs to update to a version above ${androidVersionCompatiableWithSymptomQuestionnaires} in Android or above ${iOSVersionCompatiableWithSymptomQuestionnaires} in iOS.`;
    }

    if (deployedQuestionnaires.length === 0) {
      return 'You need to setup questionnaires to enable symptom tracking.';
    }

    return null;
  })();

  const isAssessmentScheduleDeployed =
    deployedAssessment &&
    (deployedAssessment.isAssessmentOpen || deployedAssessment.recurring) &&
    !!deployedAssessment.selectedStages;
  const dayInMins = 86400000;
  let nextRecurringAssessmentTime =
    deployedAssessment &&
    deployedAssessment.assessmentOpenTimestamp &&
    Math.ceil(
      (deployedAssessment.assessmentOpenTimestamp - Date.now()) / dayInMins
    );
  if (deployedAssessment && deployedAssessment.intervalType === 'sessions') {
    nextRecurringAssessmentTime = Math.max(
      deployedAssessment.numSessions +
        deployedAssessment.frequency -
        numSessions,
      0
    );
  }
  if (deployedAssessment && deployedAssessment.intervalType === 'minutes') {
    const duration = deployedAssessment.frequency
      ? deployedAssessment.frequency
      : deployedAssessment.afterTrainingDuration;
    nextRecurringAssessmentTime = Math.round(
      deployedAssessment.minsUsage + duration - minsUsage
    );
  }

  // userPageLogger.log('on_assessment_tab_change', {
  //   customerId,
  //   nextRecurringAssessmentTime,
  //   frequency: deployedAssessment ? deployedAssessment.frequency : 0,
  //   numSessions: deployedAssessment ? deployedAssessment.numSessions : 0,
  //   intervalType: deployedAssessment ? deployedAssessment.intervalType : '',
  // });

  const renderScheduleAssessmentDialog = () => {
    if (brainDisorders) {
      const primaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
          ? t('save')
          : t('next');

      const secondaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm ||
        scheduleAssessmentDialogOpener ===
          SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit ||
        didClinicSetupAssessmentForThisCustomerBefore ||
        customerInfo.shouldUseNewSymptomTracker
          ? t('cancel')
          : t('back');

      return (
        <ScheduleAssessmentDialog
          assessmentStagesInfo={assessmentStagesInfo}
          isOpen={!!scheduleAssessmentDialogOpener}
          onSecondaryBtnClick={onScheduleAssessmentDialogSecondaryBtnClick}
          onPrimaryBtnClick={onScheduleAssessmentDialogPrimaryBtnClick}
          isPrimaryBtnDisabled={isScheduleAssessmentDialogPrimaryBtnDisabled}
          onSelectedStagesChange={onSelectedStagesChange}
          selectedStages={assessmentScheduleState.selectedStages || []}
          afterTrainingDuration={assessmentScheduleState.afterTrainingDuration}
          onAfterTrainingDurationChange={onAfterTrainingDurationChange}
          onRecurringAssessmentCheckboxChange={event => {
            if (!JSON.parse(event.target.value)) {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
                isAssessmentOpen: true,
                beforeNextSession: true,
              });
            } else {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
              });
            }
          }}
          onBeforeNextSessionCheckboxChange={(event, checked) => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              beforeNextSession: checked,
              isAssessmentOpen: checked,
            });
          }}
          isRecurringAssessmentChecked={assessmentScheduleState.recurring}
          isBeforeNextSessionAssessmentChecked={
            assessmentScheduleState.beforeNextSession
          }
          frequency={assessmentScheduleState.frequency}
          intervalType={assessmentScheduleState.intervalType || ''}
          onFrequencyChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              frequency:
                event.target.value === '' ? '' : JSON.parse(event.target.value),
            })
          }
          onIntervalTypeChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              intervalType: event.target.value,
            })
          }
          brainDisorders={brainDisorders}
          selectedBrainDisorders={selectedBrainDisordersInEdit}
          renderedStep={scheduleAssessmentDialogRenderedStep}
          onDisorderClick={onDisorderClick}
          customerDateOfBirth={customerDateOfBirth}
          customerGender={customerGender}
          onCustomerDateOfBirthChange={dateOfBirth =>
            setCustomerDateOfBirth(dateOfBirth)
          }
          onCustomerGenderChange={event =>
            setCustomerGender(event.target.value)
          }
          scheduleAssessmentDialogErrorMsg={scheduleAssessmentDialogErrorMsg}
          primaryBtnText={primaryBtnText}
          secondaryBtnText={secondaryBtnText}
          onClose={() => {
            setIsScheduleAssessmentDialogOpener(null);
            resetScheduleAssessmentDialogState();
          }}
          questionnairesStageDisabledReason={questionnairesStageDisabledReason}
          shouldShowSetupQuestionnairesButton={
            deployedQuestionnaires.length === 0 &&
            isCustomerAppCompatiableWithSymptomQuestionnaires
          }
          areEEGRegionsEnabled={areScheduleAssessmentDialogEEGRegionsEnabled}
          onEEGRegionsCheckboxChange={event => {
            if (!nfFlag) {
              new MyEvent('contact_sales_modal').log({
                from_where: 'assessment',
                action: 'open',
              });
              setAddClientReachedLimitModal(true);
              return;
            }
            setAreScheduleAssessmentDialogEEGRegionsEnabled(
              event.target.checked
            );
            if (!event.target.checked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || []).filter(
                  stage =>
                    !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds) &&
                    stage !== 'Headset'
                )
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []),
                ]),
              ]);
            }
          }}
          shouldUseNewSymptomTracker={shouldUseNewSymptomTracker}
          onSetupQuestionnaires={onSetupQuestionnaires}
          deployedQuestionnaires={deployedQuestionnaires}
          doneAssessments={!!filteredDoneAssessments.length}
          customerId={customerId}
          nfFlag={nfFlag}
          isHeadsetOnly={assessmentScheduleState.stagesType === 'headsetStages'}
          setIsHeadsetOnly={isChecked => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              stagesType: isChecked ? 'headsetStages' : 'stages',
            });
            if (isChecked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || [])
                  .filter(
                    stage =>
                      !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
                  )
                  .concat(['Headset'])
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []).filter(
                    stage => stage !== 'Headset'
                  ),
                ]),
              ]);
            }
          }}
          isHeadsetOnlyAvailable={!!isHeadsetOnlyAvailable}
          usedAssessmentBeforeJune24={usedAssessmentBeforeJune24}
        />
      );
    }

    return null;
  };

  const onCancelScheduleAssessmentCard = useCallback(() => {
    userPageLogger.log('on_recurring_assessment_stop_click', {
      customerId,
    });
    if (currentAssessmentStatus) {
      setCancelAssessmentDialogStep(
        confirmCancelingScheduledAssessmentDialogSteps.clientIsAlreadyDoingAssessment
      );
    } else {
      setCancelAssessmentDialogStep(
        confirmCancelingScheduledAssessmentDialogSteps.confirm
      );
    }
    setIsConfirmCancelingScheduledAssessmentDialogOpen(true);
  }, [currentAssessmentStatus, customerId]);

  const onEditScheduleAssessmentCard = useCallback(() => {
    userPageLogger.log('on_recurring_assessment_edit', {
      customerId,
    });
    setIsScheduleAssessmentDialogOpener(
      SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    );
  }, [customerId]);

  useEffect(() => {
    if (tab === 'assessments') {
      if (shouldShowEmptyAssessmentsTab) {
        onAddAssessment();
      } else {
        if (!isAssessmentScheduleDeployed) {
          onAddAssessment();
        } else {
          onEditScheduleAssessmentCard();
        }
      }
    }
  }, []);

  return isComponentReadyToRender ? (
    <BlurOverlayIfContinuityCustomer
      headsetDetails={headsetDetails}
      onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
    >
      {shouldShowEmptyAssessmentsTab ? (
        <EmptyAssessmentsTab onAddAssessment={onAddAssessment} />
      ) : (
        <div className={styles.root}>
          {!isAssessmentScheduleDeployed && (
            <Paper className={styles.root_paper}>
              <div className={styles.header_container}>
                <div className={styles.header}>
                  <p>
                    {t('brain-assessments')}:{' '}
                    <span style={{ color: 'red' }}>OFF</span>
                  </p>
                  <p>{t('assessments_description')}</p>
                </div>
                <div className={styles.button_container}>
                  <Button
                    variant="contained"
                    onClick={onAddAssessment}
                    color="primary"
                    disabled={isProcessingAssessment}
                  >
                    <span className={styles.btn}>{t('add_assessment')}</span>
                  </Button>
                </div>
              </div>
            </Paper>
          )}
          <div className={styles.tables_container}>
            {(isAssessmentScheduleDeployed ||
              isAnimatingScheduleAssessmentCard) && (
              <Grow
                in={isAssessmentScheduleDeployed}
                onEnter={() => setIsAnimatingScheduleAssessmentCard(true)}
                onExited={() => setIsAnimatingScheduleAssessmentCard(false)}
              >
                <Paper>
                  <ScheduledAssessmentCard
                    assessmentStagesInfo={assessmentStagesInfo}
                    recurringAssessmentInterval={
                      (deployedAssessment && deployedAssessment.frequency) || 0
                    }
                    intervalType={
                      (deployedAssessment && deployedAssessment.intervalType) ||
                      ''
                    }
                    nextRecurringAssessmentTime={nextRecurringAssessmentTime}
                    isRecurringAssessmentEnabled={
                      deployedAssessment && deployedAssessment.recurring
                    }
                    isBeforeNextSessionAssessmentEnabled={
                      deployedAssessment && deployedAssessment.isAssessmentOpen
                    }
                    onCancel={onCancelScheduleAssessmentCard}
                    onEdit={onEditScheduleAssessmentCard}
                    currentAssessmentStatus={
                      currentAssessmentStatus || isProcessingAssessment
                    }
                    assessmentStages={deployedAssessment.selectedStages || []}
                    headsetOnly={
                      deployedAssessment.stagesType === 'headsetStages'
                    }
                  />
                </Paper>
              </Grow>
            )}

            {(doesDoneAssessmentsHasData ||
              isAnimatingDoneAssessmentsTable) && (
              <Grow
                in={doesDoneAssessmentsHasData}
                onEnter={() => setIsAnimatingDoneAssessmentsTable(true)}
                onExited={() => setIsAnimatingDoneAssessmentsTable(false)}
              >
                <Paper>
                  <div className={styles.table_container}>
                    <p>{t('previous_assessments')}</p>
                    <div>
                      <DoneAssessmentsTable
                        doneAssessments={sortedReadyDoneAssessments}
                        onDeleteAssessment={onDeleteAssessment}
                        isNonf={userType === null}
                        symptomsTrackingAnswers={symptomsTrackingAnswers}
                        // assessmentVersion={deployedAssessment.assessmentVersion}
                      />
                    </div>
                  </div>
                </Paper>
              </Grow>
            )}

            {Object.keys(allOverallQuestionnaires).length &&
            filteredOverallQuestionnaires.find(
              questionnaireId =>
                allQuestionnariesData[questionnaireId] &&
                !allQuestionnariesData[questionnaireId].hideResult
            ) ? (
              <Paper className={styles.root_paper}>
                <div className={styles.header}>
                  <p>Overall Symptom Tracking</p>
                  <p>
                    See the overall score for each standardized or custom
                    questionnaire your client completed
                  </p>
                </div>
                {/* <FormControl
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <Select
                    id="select-graph"
                    value={filteredOverallQuestionnaires[0]}
                    onChange={onSelectedQuestionnairesDataChange}
                    style={{ marginBottom: 20, fontSize: 12 }}
                  >
                    {Object.keys(allOverallQuestionnaires).map(
                      questionItemId => {
                        return (
                          <MenuItem key={questionItemId} value={questionItemId}>
                            {allOverallQuestionnaires[questionItemId].fullName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl> */}
                <OverallSymptomsTrackingGraph
                  filteredOverallQuestionnaires={filteredOverallQuestionnaires}
                  allOverallQuestionnaires={allOverallQuestionnaires}
                  graphData={graphData}
                  onSelectedQuestionnairesDataChange={
                    onSelectedQuestionnairesDataChange
                  }
                  // isInPreview
                  // isFullWidht
                />
                <div className={styles.download_btn}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onDownloadOverallSymptomsTrackingDataAsCSV}
                  >
                    <span className={styles.download_btn_content}>
                      <DownloadIcon />
                      <span className={styles.btn}>{t('download-csv')}</span>
                    </span>
                  </Button>
                </div>
              </Paper>
            ) : null}

            {!(
              shouldUseNewSymptomTracker &&
              Object.keys(filteredSymptomsTrackingAnswers).length === 0
            ) ? (
              <SymptomTrackerTab
                {...{
                  allSymptomTrackerQuestions,
                  customerSymptomTrackerQuestions,
                  symptomsTrackingAnswers: filteredSymptomsTrackingAnswers,
                  onDownloadSymptomsTrackingDataAsCSV,
                  headsetDetails,
                  onHeadsetDetailsUpdated,
                  deployedQuestionnaires,
                  shouldUseNewSymptomTracker,
                  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick,
                  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick,
                  isSwitchToNewSymptomTrackerDialogOpen,
                  onSwitchToNewSymptomTrackerDialogClose,
                  onSetupSymptomTracker,
                  currentAssessmentStatus:
                    currentAssessmentStatus || isProcessingAssessment,
                }}
              />
            ) : null}
          </div>
        </div>
      )}

      <div>
        {renderScheduleAssessmentDialog()}
        <NeuroPromoDialog
          addClientReachedLimitModal={addClientReachedLimitModal}
          setAddClientReachedLimitModal={setAddClientReachedLimitModal}
          onViewPricingPlansClicked={onViewPricingPlansClicked}
          fromWhere="assessment"
        />
      </div>

      <ConfirmCancelingScheduledAssessmentDialog
        renderedStep={cancelAssessmentDialogStep}
        isOpen={isConfirmCancelingScheduledAssessmentDialogOpen}
        onConfirm={async () => {
          if (
            cancelAssessmentDialogStep ===
            confirmCancelingScheduledAssessmentDialogSteps.confirm
          ) {
            userPageLogger.log('on_canceling_schedule_assessment_confirm', {
              customerId,
            });
            setIsConfirmCancelingScheduledAssessmentDialogOpen(false);
            setDeployedAssessment({
              ...deployedAssessment,
              isAssessmentOpen: false,
              beforeNextSession: false,
              recurring: false,
              // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
            });

            const currentStage = await database
              .ref(`assessments/currentStage/${customerId}`)
              .once('value')
              .then(snapshot => snapshot.val());

            if (doneAssessments && currentStage) {
              const lastDoneAssessmentId = nth(
                Object.keys(doneAssessments),
                -1
              );
              const latestDoneAssessment =
                doneAssessments[lastDoneAssessmentId];
              if (!latestDoneAssessment.isReady) {
                // eslint-disable-next-line no-undef
                fireFunctionPost('removeAssessment', {
                  assessmentId: lastDoneAssessmentId,
                });
              }
            }
            database.ref(`assessments/currentStage/${customerId}`).set(null);
            database.ref(`symptomTracker/formCache/${customerId}`).set(null);
            database
              .ref(`assessments/deployedAssessments/${customerId}/isProcessing`)
              .set(null);
          } else {
            setCancelAssessmentDialogStep(
              confirmCancelingScheduledAssessmentDialogSteps.confirm
            );
          }
        }}
        onCancel={() => {
          userPageLogger.log('on_canceling_schedule_assessment_cancel', {
            customerId,
          });
          setIsConfirmCancelingScheduledAssessmentDialogOpen(false);
        }}
      />

      <ConfirmDeletingDoneAssessment
        renderedStep={confirmDeletingDoneAssessmentRenderedStep}
        onConfirm={async () => {
          if (
            confirmDeletingDoneAssessmentRenderedStep ===
            confirmDeletingDoneAssessmentDialogRenderedSteps.promptForDeletingAssessment
          ) {
            userPageLogger.log('on_delete_assessment_confirm', {
              assessmentId: selectedAssessmentIdToBeDeleted,
              customerId,
            });
            setConfirmDeletingDoneAssessmentRenderedStep(
              confirmDeletingDoneAssessmentDialogRenderedSteps.loading
            );
            await deleteAssessment(selectedAssessmentIdToBeDeleted);
            setConfirmDeletingDoneAssessmentRenderedStep(
              confirmDeletingDoneAssessmentDialogRenderedSteps.assessmentDeleted
            );
            setSelectedAssessmentIdToBeDeleted(null);
          } else {
            setConfirmDeletingDoneAssessmentRenderedStep(null);
          }
        }}
        onCancel={() => {
          userPageLogger.log('on_delete_assessment_cancel', {
            assessmentId: selectedAssessmentIdToBeDeleted,
            customerId,
          });
          setSelectedAssessmentIdToBeDeleted(null);
          setConfirmDeletingDoneAssessmentRenderedStep(null);
        }}
        onClose={() => {
          if (
            confirmDeletingDoneAssessmentRenderedStep !==
            confirmDeletingDoneAssessmentDialogRenderedSteps.loading
          ) {
            userPageLogger.log('on_delete_assessment_close', {
              customerId,
            });
            setSelectedAssessmentIdToBeDeleted(null);
            setConfirmDeletingDoneAssessmentRenderedStep(null);
          }
        }}
        isBaselineAssessment={
          selectedAssessmentIdToBeDeleted &&
          doneAssessments[selectedAssessmentIdToBeDeleted].isBaseline
        }
      />
    </BlurOverlayIfContinuityCustomer>
  ) : (
    <Loader />
  );
};

AssessmentsTabBase.propTypes = exact({
  customerId: PropTypes.string.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  onHeadsetDetailsUpdated: PropTypes.func.isRequired,
  customerInfo: PropTypes.object.isRequired,
  setCustomerInfo: PropTypes.func.isRequired,
  deployedQuestionnaires: PropTypes.array.isRequired,
  allSymptomTrackerQuestions: PropTypes.object.isRequired,
  customerSymptomTrackerQuestions: PropTypes.arrayOf(PropTypes.string),
  symptomsTrackingAnswers: PropTypes.object.isRequired,
  onDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  onDownloadOverallSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  shouldUseNewSymptomTracker: PropTypes.bool.isRequired,
  onSwitchToNewSymptomTrackerDialogPrimaryBtnClick: PropTypes.func.isRequired,
  onSwitchToNewSymptomTrackerDialogSecondaryBtnClick: PropTypes.func.isRequired,
  isSwitchToNewSymptomTrackerDialogOpen: PropTypes.bool.isRequired,
  onSetupSymptomTracker: PropTypes.func.isRequired,
  onSwitchToNewSymptomTrackerDialogClose: PropTypes.func.isRequired,
  onSetupQuestionnaires: PropTypes.func.isRequired,
  isCustomerAppCompatiableWithSymptomQuestionnaires: PropTypes.bool.isRequired,
  switchToNewSymptomTracker: PropTypes.func.isRequired,
  questionnairesShouldBeEnabledInScheduleAssessmentDialog:
    PropTypes.bool.isRequired,
  assessmentStagesInfo: PropTypes.object.isRequired,
  allQuestionnariesData: PropTypes.object,
});

export const AssessmentsTab = React.memo(
  withEntryComponent(AssessmentsTabBase)
);
AssessmentsTab.displayName = 'AssessmentsTab';
