import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
// import styles from './EegAnalysis.scss';
import { CanvasJSChart } from 'canvasjs-react-charts';
// import { CanvasJSChart } = CanvasJSReact;

const EegGraphBase = ({ options, onScroll }) => {
  return (
    <div onWheel={onScroll}>
      <CanvasJSChart options={options} />;
    </div>
  );
};

EegGraphBase.propTypes = exact({
  options: PropTypes.object.isRequired,
  onScroll: PropTypes.func.isRequired,
});

export const EegGraph = React.memo(EegGraphBase);
EegGraph.displayName = 'EegGraph';
