import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, Button, DialogActions } from '@material-ui/core';
import styles from './NewTermsDialog.scss';

const NewTermsDialogBase = ({ onConfirm, isOpen, parseTermsVersion }) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <Dialog open={isOpen} style={{ zIndex: 9999 }}>
      <div className={styles.content}>
        <p className={styles.content_title}>
          We are changing our terms of service, effective from{' '}
          {month[parseTermsVersion()[1] - 1]} {parseTermsVersion()[2]},{' '}
          {parseTermsVersion()[0] && parseTermsVersion()[0].substring(1)}.
        </p>
        <p>
          By continuing to use the platform on or after this effective date, you
          agree to be bound by the new terms.{' '}
          <span
            className={styles.content_link}
            onClick={() => {
              window.open(
                '/privacy/terms-of-service-for-practitioners.pdf',
                '_blank'
              );
            }}
          >
            Click here
          </span>{' '}
          to read the new terms.
        </p>
      </div>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          <span className={styles.btn}>
            I agree, please continue to the dashboard
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewTermsDialogBase.propTypes = exact({
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  parseTermsVersion: PropTypes.func.isRequired,
});

export const NewTermsDialog = React.memo(NewTermsDialogBase);
NewTermsDialog.displayName = 'NewTermsDialog';
