import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import LaunchIcon from '@material-ui/icons/Launch';
import BoltIcon from '@material-ui/icons/FlashOn';
import classNames from 'classnames';
import SwitchToContinuityIcon from '@material-ui/icons/ExitToApp';
import { Switch, Tooltip, Popover, Button } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YoutubeSearchedFor';
// import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/Help';
// import { capitalize } from 'lodash';
import styles from './UserLinks.scss';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { WrapConditionally } from '../../../Core/Components/WrapConditionally.jsx/WrapConditionally';
import liveSessionIcon from '../../../../assets/live_session_icon_grey.png';
// import liveSessionIconDisabled from '../../../../assets/live_session_icon_grey_disabled.png';

const userPageLogger = new MyEventWrapper('user_page');

const iconStyle = { fontSize: 18 };
const UserLinksBase = ({
  onSwitchToContinuity,
  onResetPassword,
  onEditUserInformation,
  onGoToLiveSession,
  isYoutubeSearchEnabled,
  isYoutubeSearchEnabledForClinic,
  onIsYoutubeSearchEnabledChange,
  onDeleteClient,
  onDownloadDataAsCSV,
  shouldHideSwitchToContinuityBtn,
  disabledReasonForSwitchToContinuityBtn,
  disableReasonForDownloadAsCSV,
  disableReasonForGoToLiveSession,
  // onNotifyByEmailChange,
  // deployedProgram,
  sessionStatus,
  isStreamingEnabled,
  onIsStreamingEnabledChange,
  isStreamingEnabledForClinic,
  userHasDeployedProgram,
  isRapidSessionEnabledForClinic,
  onIsRapidSessionEnabledChange,
  isRapidSessionEnabled,
  isDemo,
}) => {
  const t = useTranslation();

  const [
    morePopoverAnchorElement,
    setMorePopoverAnchorElement,
  ] = React.useState(null);

  const [
    trainingPreferancePopoverAnchorElement,
    setTrainingPreferancePopoverAnchorElement,
  ] = React.useState(null);

  const onMoreBtnClick = useCallback(event => {
    userPageLogger.log('on_more_user_links_click');
    setMorePopoverAnchorElement(event.currentTarget);
  }, []);

  const onTrainingPreferanceBtnClick = useCallback(event => {
    userPageLogger.log('on_training_preferance_user_links_click');
    setTrainingPreferancePopoverAnchorElement(event.currentTarget);
  }, []);

  return (
    <div className={styles.root} data-test-id="user-links">
      <div>
        <WrapConditionally
          condition={false}
          wrap={children => (
            <TooltipWrapper
              title={
                <TooltipTitle>{disableReasonForGoToLiveSession}</TooltipTitle>
              }
            >
              <div>{children}</div>
            </TooltipWrapper>
          )}
        >
          <Button
            onClick={() => onGoToLiveSession(false)}
            // disabled={!!disableReasonForGoToLiveSession}
          >
            <div className={styles.button_container}>
              <img src={liveSessionIcon} />
              <p>{t('go-to-live-session')}</p>
              <LaunchIcon
                onClick={e => {
                  onGoToLiveSession(true);
                  e.stopPropagation();
                }}
                fontSize="small"
                style={{ marginLeft: 5 }}
              />
            </div>
          </Button>
        </WrapConditionally>
      </div>

      {/* <div className={styles.email_acticity}>
        <Button
          onClick={() =>
            onNotifyByEmailChange(
              null,
              deployedProgram ? !deployedProgram.notifyByEmail : true
            )
          }
        >
          <div className={styles.button_container}>
            <EmailIcon style={iconStyle} />
            <p>{capitalize(t('email-activity'))}</p>
            <div className={styles.tooltip_container}>
              <Tooltip
                title={
                  <TooltipTitle>
                    Get updated when the client starts a session or finishes a
                    program.
                  </TooltipTitle>
                }
              >
                <HelpIcon />
              </Tooltip>
            </div>
          </div>
        </Button>
        <Switch
          checked={(deployedProgram && deployedProgram.notifyByEmail) || false}
          onChange={onNotifyByEmailChange}
          color="primary"
          classes={{ switchBase: styles.switch_base }}
        />
      </div> */}

      <TooltipWrapper
        disabled={!disabledReasonForSwitchToContinuityBtn}
        title={
          <TooltipTitle>{disabledReasonForSwitchToContinuityBtn}</TooltipTitle>
        }
      >
        <div
          data-test-id="switch-to-continuity-btn-container"
          style={{
            display: shouldHideSwitchToContinuityBtn ? 'none' : 'revert',
          }}
        >
          <Button
            onClick={onSwitchToContinuity}
            disabled={!!disabledReasonForSwitchToContinuityBtn}
          >
            <div
              className={classNames(styles.button_container, {
                [styles.disabled]: !!disabledReasonForSwitchToContinuityBtn,
              })}
            >
              <SwitchToContinuityIcon style={iconStyle} />
              <p>{t('switch-to-continuity')}</p>
            </div>
          </Button>
        </div>
      </TooltipWrapper>

      {(sessionStatus && userHasDeployedProgram) || userHasDeployedProgram ? (
        <div>
          <Button onClick={onTrainingPreferanceBtnClick}>
            <div className={styles.button_container}>
              <SettingsIcon style={iconStyle} />
              <p>TRAINING PREFERENCES</p>
            </div>
          </Button>
        </div>
      ) : null}

      <div>
        <Button onClick={onMoreBtnClick}>
          <div className={styles.button_container}>
            <MoreVertIcon style={iconStyle} />
            <p>{t('more')}</p>
          </div>
        </Button>
      </div>

      <Popover
        open={!!morePopoverAnchorElement}
        anchorEl={morePopoverAnchorElement}
        onClose={useCallback(() => setMorePopoverAnchorElement(null), [])}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popover_content}>
          <div>
            <WrapConditionally
              condition={!!disableReasonForDownloadAsCSV}
              wrap={children => (
                <TooltipWrapper
                  title={
                    <TooltipTitle>{disableReasonForDownloadAsCSV}</TooltipTitle>
                  }
                >
                  <div>{children}</div>
                </TooltipWrapper>
              )}
            >
              <Button
                onClick={onDownloadDataAsCSV}
                disabled={!!disableReasonForDownloadAsCSV}
              >
                <p>{t('download_data_as_csv')}</p>
              </Button>
            </WrapConditionally>
          </div>
          {isDemo ? null : (
            <div>
              <Button onClick={onDeleteClient}>
                <p>{t('delete_client')}</p>
              </Button>
            </div>
          )}
          <div>
            <Button onClick={onEditUserInformation}>
              <p>EDIT INFORMATION</p>
            </Button>
          </div>
          <div>
            <Button onClick={onResetPassword}>
              <p>{t('reset_password')}</p>
            </Button>
          </div>
        </div>
      </Popover>

      <Popover
        open={!!trainingPreferancePopoverAnchorElement}
        anchorEl={trainingPreferancePopoverAnchorElement}
        onClose={useCallback(
          () => setTrainingPreferancePopoverAnchorElement(null),
          []
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popover_content} style={{ paddingRight: 28 }}>
          {sessionStatus && userHasDeployedProgram ? (
            <div>
              <div className={styles.youtube_search}>
                <Button
                  onClick={() =>
                    onIsYoutubeSearchEnabledChange(
                      null,
                      !isYoutubeSearchEnabled
                    )
                  }
                >
                  <div className={styles.button_container}>
                    <YouTubeIcon style={iconStyle} />
                    <p>{t('enable_youtube_search')}</p>
                    {/* <div style={{ marginLeft: 9 }}>
                    <div role="button" className={styles.chip_custom} tabIndex="-1">
                      <span>PLUS</span>
                    </div>
                  </div> */}
                    <div className={styles.tooltip_container}>
                      <Tooltip
                        title={
                          <TooltipTitle>
                            {isYoutubeSearchEnabledForClinic
                              ? t(
                                  'disable-global-youtube-search-to-disable-for-all-customers'
                                )
                              : t(
                                  'enable-global-youtube-search-to-enable-for-customer'
                                )}
                          </TooltipTitle>
                        }
                      >
                        <HelpIcon />
                      </Tooltip>
                    </div>
                  </div>
                </Button>
                <Switch
                  checked={isYoutubeSearchEnabled}
                  disabled={!isYoutubeSearchEnabledForClinic}
                  onChange={onIsYoutubeSearchEnabledChange}
                  color="primary"
                  classes={{ switchBase: styles.switch_base }}
                />
              </div>
            </div>
          ) : null}

          {userHasDeployedProgram ? (
            <div>
              <WrapConditionally
                condition={!isStreamingEnabledForClinic}
                wrap={children => (
                  <TooltipWrapper
                    title={
                      <TooltipTitle>
                        To enable the Streaming training activity for this
                        client, you’ll need to enable it from your dashboard
                        Preferences first.
                      </TooltipTitle>
                    }
                  >
                    <div>{children}</div>
                  </TooltipWrapper>
                )}
              >
                <div className={styles.youtube_search}>
                  <Button
                    onClick={() => {
                      if (!isStreamingEnabledForClinic) {
                        return;
                      }
                      onIsStreamingEnabledChange(null, !isStreamingEnabled);
                    }}
                  >
                    <div className={styles.button_container}>
                      <LiveTvIcon style={iconStyle} />
                      <p>STREAMING</p>
                      <div className={styles.tooltip_container}>
                        <WrapConditionally
                          condition={isStreamingEnabledForClinic}
                          wrap={children => (
                            <TooltipWrapper
                              title={
                                <TooltipTitle>
                                  If you enable this feature, your client will
                                  have access to the Streaming activity,
                                  allowing them to train while watching content
                                  from any app on their device. (Available on
                                  iOS and Android 13 devices only)
                                </TooltipTitle>
                              }
                            >
                              <div>{children}</div>
                            </TooltipWrapper>
                          )}
                        >
                          <HelpIcon />
                        </WrapConditionally>
                      </div>
                    </div>
                  </Button>
                  <Switch
                    checked={isStreamingEnabled}
                    disabled={!isStreamingEnabledForClinic}
                    onChange={onIsStreamingEnabledChange}
                    color="primary"
                    classes={{ switchBase: styles.switch_base }}
                  />
                </div>
              </WrapConditionally>
            </div>
          ) : null}

          {userHasDeployedProgram ? (
            <div>
              <WrapConditionally
                condition={!isRapidSessionEnabledForClinic}
                wrap={children => (
                  <TooltipWrapper
                    title={
                      <TooltipTitle>
                        To enable the Rapid session training activity for this
                        client, you’ll need to enable it from your dashboard
                        Preferences first.
                      </TooltipTitle>
                    }
                  >
                    <div>{children}</div>
                  </TooltipWrapper>
                )}
              >
                <div className={styles.youtube_search}>
                  <Button
                    onClick={() => {
                      if (!isRapidSessionEnabledForClinic) {
                        return;
                      }
                      onIsRapidSessionEnabledChange(
                        null,
                        !isRapidSessionEnabled
                      );
                    }}
                  >
                    <div className={styles.button_container}>
                      <BoltIcon style={iconStyle} />
                      <p>RAPID SESSION</p>
                      <div className={styles.tooltip_container}>
                        <WrapConditionally
                          condition={isRapidSessionEnabledForClinic}
                          wrap={children => (
                            <TooltipWrapper
                              title={
                                <TooltipTitle>
                                  If you enable this feature, your client can
                                  choose a shorter session duration than the one
                                  you’ve set.
                                </TooltipTitle>
                              }
                            >
                              <div>{children}</div>
                            </TooltipWrapper>
                          )}
                        >
                          <HelpIcon />
                        </WrapConditionally>
                      </div>
                    </div>
                  </Button>
                  <Switch
                    checked={isRapidSessionEnabled}
                    disabled={!isRapidSessionEnabledForClinic}
                    onChange={onIsRapidSessionEnabledChange}
                    color="primary"
                    classes={{ switchBase: styles.switch_base }}
                  />
                </div>
              </WrapConditionally>
            </div>
          ) : null}
        </div>
      </Popover>
    </div>
  );
};

UserLinksBase.propTypes = exact({
  onSwitchToContinuity: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onEditUserInformation: PropTypes.func.isRequired,
  onGoToLiveSession: PropTypes.func.isRequired,
  isYoutubeSearchEnabled: PropTypes.bool.isRequired,
  isYoutubeSearchEnabledForClinic: PropTypes.bool.isRequired,
  onIsYoutubeSearchEnabledChange: PropTypes.func.isRequired,
  // onNotifyByEmailChange: PropTypes.func.isRequired,
  onDeleteClient: PropTypes.func.isRequired,
  onDownloadDataAsCSV: PropTypes.func.isRequired,
  shouldHideSwitchToContinuityBtn: PropTypes.bool.isRequired,
  disabledReasonForSwitchToContinuityBtn: PropTypes.string,
  disableReasonForDownloadAsCSV: PropTypes.string,
  disableReasonForGoToLiveSession: PropTypes.string,
  // deployedProgram: PropTypes.object,
  sessionStatus: PropTypes.string,
  isStreamingEnabled: PropTypes.bool,
  onIsStreamingEnabledChange: PropTypes.func,
  isStreamingEnabledForClinic: PropTypes.bool,
  userHasDeployedProgram: PropTypes.bool,
  isRapidSessionEnabledForClinic: PropTypes.bool,
  onIsRapidSessionEnabledChange: PropTypes.func,
  isRapidSessionEnabled: PropTypes.bool,
  isDemo: PropTypes.bool,
});

export const UserLinks = React.memo(UserLinksBase);
UserLinks.displayName = 'UserLinks';
