import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import TextField from '@material-ui/core/TextField';
import styles from './SettingsSection.scss';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { FeatureNotAllowedDialog } from '../FeatureNotAllowedDialog/FeatureNotAllowedDialog';
import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';

const CustomSwitch = ({ checked, onChange, id, disabled }) => (
  <div className="toggle-switch" data-ts-color="blue">
    <input
      id={id}
      type="checkbox"
      hidden="hidden"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />

    {/* eslint-disable */}
    <label
      id={id}
      htmlFor={id}
      className="ts-helper"
      style={{ marginBottom: 0 }}
    />
    {/* eslint-enable */}
  </div>
);
CustomSwitch.defaultProps = {
  disabled: false,
};
CustomSwitch.propTypes = exact({
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
});

const SettingsInputBase = ({
  path,
  defaultValue,
  label,
  description,
  type,
  options,
  shouldShowHr,
  valueType,
  disabled,
  tooltip,
  note,
  availability,
  eventName,
  isNew,
  additionalPath,
}) => {
  const t = useTranslation();
  const [
    isFeatureNotAllowedDialogOpen,
    setIsFeatureNotAllowedDialogOpen,
  ] = useState(false);
  const [valueInDatabase, setValueInDatabase] = useFirebaseDB({
    path,
    defaultValue,
  });

  const onChange = useCallback(
    // eslint-disable-next-line consistent-return
    event => {
      const value = (() => {
        if (eventName) {
          new MyEvent(eventName).log({
            isEnabled: event.target.checked,
          });
        }
        if (type === 'boolean') {
          return event.target.checked;
        }

        if (valueType === 'integer') {
          return parseInt(event.target.value, 10);
        }

        return event.target.value;
      })();

      if (availability === false) {
        return setIsFeatureNotAllowedDialogOpen(true);
      }
      setValueInDatabase(value);
      if (additionalPath) {
        database.ref(additionalPath).set(value);
      }
    },
    [setValueInDatabase, type, valueType, availability]
  );

  return (
    <div>
      <FeatureNotAllowedDialog
        text={t('upgrade-plan-to-use-youtube-search')}
        isOpen={isFeatureNotAllowedDialogOpen}
        onDismiss={() => setIsFeatureNotAllowedDialogOpen(false)}
      />
      <TooltipWrapper
        title={<TooltipTitle>{tooltip || ''}</TooltipTitle>}
        placement="right"
        classes={{
          tooltip: styles.is_client_real_email_tooltip,
        }}
        disabled={!tooltip}
      >
        <div>
          {shouldShowHr && <hr />}
          {type === 'boolean' && (
            <div className={styles.switch_setting_unit}>
              <div>
                <p>{label}</p>
                {isNew && (
                  <span
                    className={styles.card_new_label}
                    style={{ top: 3, left: -10 }}
                  >
                    NEW
                  </span>
                )}

                <CustomSwitch
                  checked={
                    valueInDatabase === null ? defaultValue : valueInDatabase
                  }
                  id={path}
                  onChange={onChange}
                  disabled={disabled}
                />
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
              {note ? (
                <span style={{ color: 'rgb(191, 144, 0)' }}>
                  <CustomAlert severity="warning">{note}</CustomAlert>
                </span>
              ) : null}
            </div>
          )}
          {type === 'select' && (
            <div className={styles.drop_down_setting_unit}>
              <div>
                <p>{label}</p>
                <p>{description}</p>
              </div>
              <div
                style={{
                  marginBottom: 18,
                  pointerEvents: disabled ? 'none' : undefined,
                }}
              >
                <TextField
                  select
                  value={
                    valueInDatabase === null
                      ? options[0].value
                      : valueInDatabase
                  }
                  onChange={onChange}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth
                  inputProps={{
                    style: { fontSize: 13, color: '#555555' },
                  }}
                  disabled={disabled}
                >
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
          )}
        </div>
      </TooltipWrapper>
    </div>
  );
};

SettingsInputBase.defaultProps = {
  shouldShowHr: true,
  valueType: 'string',
  disabled: false,
  tooltip: false,
};

SettingsInputBase.propTypes = exact({
  path: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array,
  shouldShowHr: PropTypes.bool,
  valueType: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  note: PropTypes.string,
  availability: PropTypes.bool,
  eventName: PropTypes.string,
  isNew: PropTypes.bool,
  additionalPath: PropTypes.string,
});

export const SettingsInput = React.memo(SettingsInputBase);
SettingsInput.displayName = 'SettingsInput';
