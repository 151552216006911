import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import { SessionToSessionReportGenerator } from './SessionToSessionReportGenerator';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { defensiveThrow, injectIDs } from '../../utils/utils';
import { getDerivedPresetFrequencyName } from '../../models/frequencies/frequencies';
import {
  getAllQuestionnaires,
  getQuestionnairesQuestions,
  getAnswersCompatibleWithNewSymptomTracking,
  getSymptomTrackerOldQuestions,
} from '../../models/symptom-tracker/symptom-tracker';
import { getAssessmentStagesInfo } from '../../models/assessments/assessments';

const getSessionsWithFrequencyAndDerivedFrequencyNames = ({
  sessions,
  frequenciesConfig,
}) => {
  const mapFrequencyToFrequencyName = frequency => {
    if (
      typeof frequency.rangeStart === 'undefined' ||
      typeof frequency.rangeEnd === 'undefined'
    ) {
      defensiveThrow(new Error('incorrect frequency object'));
    }

    const frequencyName = extractFrequency(
      frequency.rangeStart,
      frequency.rangeEnd,
      frequenciesConfig
    );

    return (
      frequencyName ||
      `Custom Frequency ${frequency.rangeStart} - ${frequency.rangeEnd}`
    );
  };

  const mapFrequencyToDerivedFrequencyName = frequency => {
    if (
      typeof frequency.rangeStart === 'undefined' ||
      typeof frequency.rangeEnd === 'undefined'
    ) {
      defensiveThrow(new Error('incorrect frequency object'));
    }

    const derivedFrequencyName = getDerivedPresetFrequencyName({
      minFrequency: frequency.rangeStart,
      maxFrequency: frequency.rangeEnd,
    });

    return `${derivedFrequencyName}`;
  };

  sessions.forEach(session => {
    // eslint-disable-next-line no-param-reassign
    session.frequencyNames = session.frequencies.map(
      mapFrequencyToFrequencyName
    );

    // eslint-disable-next-line no-param-reassign
    session.derivedFrequencyNames = session.frequencies.map(
      mapFrequencyToDerivedFrequencyName
    );
  });

  return sessions;
};

const startedLoadingTime = Date.now();

const initialValue = {};
const defaultValue = {};

const SessionToSessionReportGeneratorContainerBase = props => {
  const { patientId, frequenciesConfig } = props;

  const [symptomsTrackingAnswers] = useFirebaseDB({
    path: `symptomTracker/answers/${patientId}`,
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [trainingSessions] = useFirebaseDB({
    path: `trainingSessions/${patientId}`,
    onceListener: true,
    initialValue,
  });

  const [sessionBlocks] = useFirebaseDB({
    path: `sessionBlocks/${patientId}`,
    onceListener: true,
    initialValue,
  });
  const [sessionsAnalysis] = useFirebaseDB({
    path: `sessionAnalysis/${patientId}`,
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [swingleChecks] = useFirebaseDB({
    path: 'assessments/swingle/checks',
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [neuromarkersChecks] = useFirebaseDB({
    path: 'assessments/swingle/checks_v3',
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [headsetChecks] = useFirebaseDB({
    path: 'assessments/swingle/headsetChecks',
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [swingleChecksOrder] = useFirebaseDB({
    path: 'assessments/swingle/order',
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [assessmentResultsWithDeletedAssessments] = useFirebaseDB({
    path: `assessments/results/${patientId}`,
    onceListener: true,
    initialValue,
    defaultValue,
  });

  const [isUserAllowedToUseThisFeature] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/progressReportFeature/${sessionStorage.userId}/isAvailable`,
    initialValue,
  });

  const [patientInfo] = useFirebaseDB({
    path: `userInfo/${patientId}`,
    initialValue,
    onceListener: true,
  });

  const [answerScales] = useFirebaseDB({
    path: 'symptomTracker/answerScales',
    onceListener: true,
    initialValue,
  });

  const [insightItems] = useFirebaseDB({
    path: `insights/${patientId}/items`,
    initialValue,
    defaultValue,
    valueProcessor: useRef(insights => injectIDs(insights)).current,
  });

  const [insightsConfig] = useFirebaseDB({
    path: 'insightsConfig',
    initialValue,
  });

  // const insightsConfig = null;
  // const insightItems = null;

  const [
    symptomsTrackingAllQuestions,
    setSymptomsTrackingAllQuestions,
  ] = useState(initialValue);

  const [allQuestionnaires, setAllQuestionnaires] = useState(initialValue);

  const { shouldUseNewSymptomTracker } = patientInfo;

  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  useEffect(() => {
    (async () => {
      setAssessmentStagesInfo(await getAssessmentStagesInfo());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (patientInfo !== initialValue && allQuestionnaires === initialValue) {
        const questionnaires = shouldUseNewSymptomTracker
          ? await getAllQuestionnaires({
              clinicId: sessionStorage.userId,
            })
          : {};
        setAllQuestionnaires(questionnaires);
        const allQuestions = shouldUseNewSymptomTracker
          ? getQuestionnairesQuestions({
              questionnaires,
            })
          : await getSymptomTrackerOldQuestions();
        setSymptomsTrackingAllQuestions(allQuestions);
      }
    })();
  }, [patientInfo, allQuestionnaires, shouldUseNewSymptomTracker]);

  const [
    wasRemoteConfigValuesFetched,
    setWasRemoteConfigValuesFetched,
  ] = useState(false);

  // eslint-disable-next-line no-undef
  const remoteConfig = firebase.remoteConfig();
  useEffect(() => {
    (async () => {
      try {
        await remoteConfig.fetchAndActivate();
        setWasRemoteConfigValuesFetched(true);
      } catch (error) {
        defensiveThrow({ error });
        setWasRemoteConfigValuesFetched(true);
      }
    })();
  }, [remoteConfig]);

  // const shouldShowBrainMaps = remoteConfig
  //   .getValue('showBrainMaps')
  //   .asBoolean();
  const shouldShowBrainMaps = true;

  const sortedSessionsAnalysis = Object.values(sessionsAnalysis)
    .filter(session => has(session, 'duration'))
    .sort((session1, session2) => session1.timestamp - session2.timestamp);

  const sortedSessionsAnalysisWithFrequencyNames = getSessionsWithFrequencyAndDerivedFrequencyNames(
    { sessions: sortedSessionsAnalysis, frequenciesConfig }
  );

  const isDataLoaded =
    // insightItems !== initialValue &&
    // insightsConfig !== initialValue &&
    symptomsTrackingAnswers !== initialValue &&
    symptomsTrackingAllQuestions !== initialValue &&
    trainingSessions !== initialValue &&
    sessionBlocks !== initialValue &&
    sessionsAnalysis !== initialValue &&
    swingleChecks !== initialValue &&
    neuromarkersChecks !== initialValue &&
    headsetChecks !== initialValue &&
    swingleChecksOrder !== initialValue &&
    assessmentResultsWithDeletedAssessments !== initialValue &&
    isUserAllowedToUseThisFeature !== initialValue &&
    patientInfo !== initialValue &&
    allQuestionnaires !== initialValue &&
    answerScales !== initialValue &&
    assessmentStagesInfo !== null &&
    wasRemoteConfigValuesFetched;

  return (
    <SessionToSessionReportGenerator
      {...props}
      {...{
        assessmentStagesInfo,
        sessionsAnalysis: sortedSessionsAnalysisWithFrequencyNames,
        isDataLoaded,
        symptomsTrackingAnswers: shouldUseNewSymptomTracker
          ? getAnswersCompatibleWithNewSymptomTracking({
              symptomsTrackingAnswers,
            })
          : symptomsTrackingAnswers,
        symptomsTrackingAllQuestions,
        trainingSessions,
        sessionBlocks,
        swingleChecks,
        neuromarkersChecks,
        swingleChecksOrder,
        headsetChecks,
        answerScales,
        assessmentResultsWithDeletedAssessments,
        shouldShowBrainMaps,
        allQuestionnaires,
        shouldUseNewSymptomTracker:
          patientInfo && patientInfo.shouldUseNewSymptomTracker,
        isUserAllowedToUseThisFeature:
          isUserAllowedToUseThisFeature === initialValue ||
          patientInfo === initialValue
            ? false
            : isUserAllowedToUseThisFeature || patientInfo.isDemo,
        insightItems,
        insightsConfig,
        startedLoadingTime,
      }}
    />
  );
};

SessionToSessionReportGeneratorContainerBase.propTypes = {
  patientId: PropTypes.string.isRequired,
  frequenciesConfig: PropTypes.object.isRequired,
};

export const SessionToSessionReportGeneratorContainer = React.memo(
  SessionToSessionReportGeneratorContainerBase
);
SessionToSessionReportGeneratorContainer.displayName =
  'SessionToSessionReportGeneratorContainer';
