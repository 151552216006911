exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DigitalCareItem_item_container_with_warning{width:32%;display:flex;flex-direction:column}@media only screen and (max-width: 960px){.DigitalCareItem_item_container_with_warning{width:75%}}.DigitalCareItem_item_container{box-shadow:0px 3px 6px #00000029;margin-top:10px}.DigitalCareItem_item_data_container{padding:15px;display:flex;flex-direction:column;justify-content:space-between;height:185px}.DigitalCareItem_item_data_text{display:flex;flex-direction:column}.DigitalCareItem_item_title{font-size:13px;font-weight:700;margin-bottom:10px}.DigitalCareItem_item_description{font-size:13px}.DigitalCareItem_item_image_container{width:100%;display:flex;background-color:#F8F8F8}.DigitalCareItem_item_image{-webkit-filter:grayscale(100%);filter:grayscale(100%)}.DigitalCareItem_item_image_text{width:70%}.DigitalCareItem_item_btns_container{display:flex;justify-content:space-between;align-items:center}.DigitalCareItem_multi_select_input{width:75% !important;height:40px !important;border:none !important}\n", ""]);

// Exports
exports.locals = {
	"item_container_with_warning": "DigitalCareItem_item_container_with_warning",
	"item_container": "DigitalCareItem_item_container",
	"item_data_container": "DigitalCareItem_item_data_container",
	"item_data_text": "DigitalCareItem_item_data_text",
	"item_title": "DigitalCareItem_item_title",
	"item_description": "DigitalCareItem_item_description",
	"item_image_container": "DigitalCareItem_item_image_container",
	"item_image": "DigitalCareItem_item_image",
	"item_image_text": "DigitalCareItem_item_image_text",
	"item_btns_container": "DigitalCareItem_item_btns_container",
	"multi_select_input": "DigitalCareItem_multi_select_input"
};