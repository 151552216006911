exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".EegAnalysis_filter_btn{border-radius:16px;border:1px solid #DADCE0;display:flex;justify-content:space-between;align-items:center;padding:5px 10px;color:#000000DE;height:32px;cursor:pointer;margin-right:10px;padding:10px}.EegAnalysis_popover_paper{padding:10px}.EegAnalysis_popover_title{font-size:16px;font-weight:900;margin-left:15px;margin-bottom:10px;margin-top:10px}.EegAnalysis_popover_reset{margin-left:15px}.EegAnalysis_filters_container{display:flex}.EegAnalysis_filter_btn_container{display:flex;justify-content:end;margin-top:20px}.EegAnalysis_reset_filter_container{display:flex;justify-content:end;color:#2196f3;cursor:pointer}.EegAnalysis_graph_container{overflow-x:auto;overflow-y:hidden;width:100%}.EegAnalysis_freq_button{border-radius:16px;border:1px solid #DADCE0;display:flex;justify-content:center;align-items:center;padding:5px 10px;color:#000000DE;height:32px;cursor:pointer;margin:10px 10px 0 0;width:130px}\n", ""]);

// Exports
exports.locals = {
	"filter_btn": "EegAnalysis_filter_btn",
	"popover_paper": "EegAnalysis_popover_paper",
	"popover_title": "EegAnalysis_popover_title",
	"popover_reset": "EegAnalysis_popover_reset",
	"filters_container": "EegAnalysis_filters_container",
	"filter_btn_container": "EegAnalysis_filter_btn_container",
	"reset_filter_container": "EegAnalysis_reset_filter_container",
	"graph_container": "EegAnalysis_graph_container",
	"freq_button": "EegAnalysis_freq_button"
};