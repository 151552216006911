import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { CircularProgress, Paper, TextField } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import { HomePageActionCards } from './HomePageActionCards/HomePageActionCards';
import styles from './HomePage.scss';
import { paragraphFontSize, xsMediaMaxWidth } from '../../cssInJs/constants';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { ListingStatus } from './ListingStatus/ListingStatus';
import { NewTermsDialog } from './NewTermsDialog/NewTermsDialog';
import { ActivateBlockedClinicDialog } from './ActivateBlockedClinicDialog/ActivateBlockedClinicDialog';
import { SelfRegistrationDialog } from '../SelfRegistrationDialog/SelfRegistrationDialog';
import { LogoDialog } from '../WhiteLabelingPopOut/LogoDialog';
import { getLogoImage } from '../../models/logo/logo';
import clinicProfilePlaceholderImage from '../../../assets/clinic-profile-pic-placeholder.png';

import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { useTranslation } from '../../Core/hooks/useTranslation';
import { PACKAGES_TYPES } from '../../models/packages/packages';

const homePageEventLogger = new MyEventWrapper('home_page');
const HomePageBase = ({ onComponentRenderedCompletely }) => {
  const [isMatchingXSSMedia] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const t = useTranslation();
  const clinicId = sessionStorage.userId;
  const usageStatsInitialValueRef = useRef(Symbol('iv'));
  const [usageStats] = useFirebaseDB({
    path: `clinicStats/usageStats/${clinicId}`,
    onceListener: true,
    initialValue: usageStatsInitialValueRef.current,
  });

  const userInfoInitialValueRef = useRef(Symbol('IV'));
  const [userInfo] = useFirebaseDB({
    path: `userInfo/${clinicId}`,
    initialValue: userInfoInitialValueRef.current,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${clinicId}/nfAddOnPlanId`,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
  });

  const [clinicalCurrentVersion] = useFirebaseDB({
    path: 'termsConsent/clinicalCurrentVersion',
  });

  const termsConsentClinicInitialValueRef = useRef(Symbol('Iv'));
  const [termsConsentClinic] = useFirebaseDB({
    path: `termsConsent/clinics/${clinicId}`,
    defaultValue: false,
    initialValue: termsConsentClinicInitialValueRef.current,
  });

  const [dormantPlanData] = useFirebaseDB({
    path: 'config/dormantPlans',
    defaultValue: false,
  });

  const [dormantPackageName] = useFirebaseDB({
    path: `billing/packages/customNONFPackages/${dormantPlanData &&
      dormantPlanData.groupId}/${dormantPlanData &&
      dormantPlanData.packageIndex}/bundleName`,
    defaultValue: false,
    errorListener: () => {},
  });

  const [userPrice] = useFirebaseDB({
    path: `billing/packages/customNONFPackages/${dormantPlanData &&
      dormantPlanData.groupId}/${dormantPlanData &&
      dormantPlanData.packageIndex}/addOnOptions/nfAddOns/${dormantPlanData &&
      dormantPlanData.nfGroupId}/userPrice`,
    defaultValue: false,
    errorListener: () => {},
  });

  const [appLogoSettings] = useFirebaseDB({
    path: `appLogoSettings/${sessionStorage.userId}`,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  const isComponentReadyToRender =
    usageStats !== usageStatsInitialValueRef.current &&
    userInfo !== userInfoInitialValueRef.current;

  const getSortedDaysFromUsageStats = stats =>
    Object.keys(stats).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

  const statsPeriodsOptions = usageStats
    ? getSortedDaysFromUsageStats(usageStats).map(numberOfDaysWithADash => ({
        label: `Last ${parseInt(numberOfDaysWithADash, 10)} days`,
        value: numberOfDaysWithADash,
      }))
    : [];

  const [selectedStatsPeriod, setSelectedStatsPeriod] = useState(null);

  const [shouldShowNewTermsPopup, setShouldShowNewTermsPopup] = useState(false);

  const [showBlockedClinicDialog, setShowBlockedClinicDialog] = useState(false);

  useEffect(() => {
    (async () => {
      const currentPackage = await database
        .ref(`billing/currentPackages/${clinicId}`)
        .once('value')
        .then(snapshot => snapshot.val());

      if (currentPackage.packageType === PACKAGES_TYPES.BLOCKED_PACKAGE) {
        setShowBlockedClinicDialog(true);
      }
    })();
  }, []);
  const [clinicLogoInfo, setClinicLogoInfo] = useState(null);

  const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] = useState(
    false
  );
  const [isLogoDialogOpen, setIsLogoDialogOpen] = useState(false);

  const [isLoadingLogo, setIsLoadingLogo] = useState(false);

  useEffect(() => {
    if (usageStats !== null) {
      setSelectedStatsPeriod(getSortedDaysFromUsageStats(usageStats)[0]);
    }
  }, [usageStats]);

  useEffect(() => {
    if (
      termsConsentClinic !== termsConsentClinicInitialValueRef.current &&
      (!termsConsentClinic || !termsConsentClinic[clinicalCurrentVersion])
    ) {
      setShouldShowNewTermsPopup(true);
    } else {
      setShouldShowNewTermsPopup(false);
    }
  }, [termsConsentClinic, clinicalCurrentVersion]);

  useEffect(() => {
    (async () => {
      if (clinicLogoInfo === null) {
        setIsLoadingLogo(true);
        const logoImageInfo = await getLogoImage({
          clinicId: sessionStorage.userId,
        });

        setClinicLogoInfo(logoImageInfo);
        setIsLoadingLogo(false);
      }
    })();
  });

  useEffect(() => {
    (async () => {
      if (appLogoSettings) {
        const logoImageInfo = await getLogoImage({
          clinicId: sessionStorage.userId,
        });
        setClinicLogoInfo(logoImageInfo);
      } else {
        setClinicLogoInfo(null);
      }
    })();
  }, [appLogoSettings]);

  const onConfirmNewTerms = () => {
    database
      .ref(`termsConsent/clinics/${clinicId}/${clinicalCurrentVersion}`)
      .set(Date.now());
    setShouldShowNewTermsPopup(false);
  };

  const parseTermsVersion = () => {
    return clinicalCurrentVersion ? clinicalCurrentVersion.split('-') : [];
  };

  const shouldShowWhiteLabelingCustomImage =
    clinicLogoInfo && clinicLogoInfo.customLogo && clinicLogoInfo.logoImage;
  const logoImage = shouldShowWhiteLabelingCustomImage
    ? clinicLogoInfo.logoImage
    : clinicProfilePlaceholderImage;

  return (
    isComponentReadyToRender && (
      <div className={styles.root}>
        <div className={styles.stats_paper_container}>
          <div>
            <Paper className={styles.stats_paper}>
              <div style={{ display: 'flex', position: 'relative' }}>
                {isLoadingLogo ? (
                  <div
                    className={styles.logo_image}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size={12} />
                  </div>
                ) : (
                  <img
                    src={logoImage}
                    className={
                      shouldShowWhiteLabelingCustomImage
                        ? styles.custom_logo_image
                        : styles.logo_image
                    }
                    onClick={
                      shouldShowWhiteLabelingCustomImage
                        ? () => {}
                        : event => {
                            event.preventDefault();
                            new MyEvent('on_clinic_profile_click').log();
                            // eslint-disable-next-line no-undef
                            openWhiteLabelingPopOut();
                          }
                    }
                  />
                )}
                {shouldShowWhiteLabelingCustomImage ? (
                  <EditIcon
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      color: '#808080',
                      cursor: 'pointer',
                      border: '1px white solid',
                      borderRadius: '50%',
                      fontSize: 18,
                      backgroundColor: 'white',
                    }}
                    onClick={() => {
                      setIsLogoDialogOpen(true);
                    }}
                  />
                ) : null}
                <div>
                  <h3 style={{ marginTop: 0, fontSize: 18 }}>
                    Welcome, {userInfo.firstName}!
                  </h3>
                  {userInfo.registrationCode ? (
                    <div
                      className={styles.registration_code}
                      onClick={() => {
                        setIsRegistrationDialogOpen(true);
                        new MyEvent('self_reg_registration_code_clicked').log();
                      }}
                    >
                      Code: {userInfo.registrationCode}{' '}
                      <ArrowForwardIosIcon style={{ width: 10, height: 10 }} />
                    </div>
                  ) : null}
                </div>
              </div>
              <p style={{ margin: '20px 0' }}>
                {t('stats-for-selected-period')}
              </p>

              {nfFlag ? (
                <div className={styles.stats_cards_container}>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? parseInt(
                            usageStats[selectedStatsPeriod].minsInPeriod,
                            10
                          )
                        : 0}
                    </span>
                    <span>{t('total-minutes-of-neurofeedback')}</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numSessions
                        : 0}
                    </span>
                    <span>{t('sessions-completed')}</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].userInPeriod
                        : 0}
                    </span>
                    <span>{t('clients-trained')}</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numAssessments
                        : 0}
                    </span>
                    <span>{t('assessments-completed')}</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numClientsAssessed
                        : 0}
                    </span>
                    <span>{t('clients-assessed')}</span>
                  </div>
                </div>
              ) : (
                <div className={styles.stats_cards_container}>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numUsersEnteredApp
                        : 0}
                    </span>
                    <span>Active Clients</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numAssessments
                        : 0}
                    </span>
                    <span>Assessments</span>
                  </div>
                  <div>
                    <span>
                      {selectedStatsPeriod && usageStats[selectedStatsPeriod]
                        ? usageStats[selectedStatsPeriod].numJournalEntries
                        : 0}
                    </span>
                    <span>Entries</span>
                  </div>
                </div>
              )}

              {selectedStatsPeriod && (
                <div className={styles.select_stats_period_container}>
                  <DateRangeIcon />

                  <TextField
                    select
                    value={selectedStatsPeriod}
                    onChange={event => {
                      homePageEventLogger.log('on_change_stats_period', {
                        statsPeriod: event.target.value,
                      });
                      setSelectedStatsPeriod(event.target.value);
                    }}
                    SelectProps={{
                      native: true,
                      style: {
                        fontSize: paragraphFontSize,
                        width: isMatchingXSSMedia ? 'initial' : 209,
                        paddingLeft: 5,
                      },
                    }}
                  >
                    {statsPeriodsOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </div>
              )}
            </Paper>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <HomePageActionCards />
            <div style={!nfFlag ? { opacity: 0 } : null}>
              <ListingStatus
                usageStats={usageStats}
                onComponentRenderedCompletely={onComponentRenderedCompletely}
              />
            </div>
          </div>
        </div>
        <NewTermsDialog
          onConfirm={onConfirmNewTerms}
          isOpen={shouldShowNewTermsPopup}
          parseTermsVersion={parseTermsVersion}
        />
        <ActivateBlockedClinicDialog
          isOpen={showBlockedClinicDialog}
          defaultBlockedUsageCredit={
            dormantPlanData && dormantPlanData.defaultBlockedUsageCredit
          }
          userPrice={userPrice}
          dormantPackageName={dormantPackageName}
        />
        <SelfRegistrationDialog
          isOpen={isRegistrationDialogOpen}
          onClose={() => {
            setIsRegistrationDialogOpen(false);
          }}
          registrationCode={userInfo && userInfo.registrationCode}
          onAddClientClicked={() => {
            // eslint-disable-next-line no-undef
            openAddClientModal();
          }}
          noAddClient
          clinicNonfPlan={nfFlag}
        />
        <LogoDialog
          isOpen={isLogoDialogOpen}
          onClose={() => {
            setIsLogoDialogOpen(false);
          }}
          closeOuterPopup={() => {}}
        />
      </div>
    )
  );
};

HomePageBase.propTypes = exact({
  onComponentRenderedCompletely: PropTypes.func.isRequired,
});

export const HomePage = React.memo(HomePageBase);
HomePage.displayName = 'HomePage';
