import React, { useEffect, useRef, useState, useCallback } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import { getCurrentURLParams, fetchHeadsetDetails } from '../../utils/utils';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { UserPage } from './UserPage';
import { CustomerInfoProvider } from '../../Core/contexts/contexts';
import {
  getAllQuestionnaires,
  getAnswersCompatibleWithNewSymptomTracking,
  getSymptomTrackerOldQuestions,
} from '../../models/symptom-tracker/symptom-tracker';
import { useAsyncState } from '../../Core/hooks/useAsyncState';
import { isAppCompilableWithSymptomQuestionnaires } from '../../models/customer/customer';
import { withErrorBoundary } from '../../Core/hocs/withErrorBoundary/withErrorBoundary';
import { getStandardAssessmentStagesInfo } from '../../models/assessments/assessments';

const { customerId } = getCurrentURLParams();
const initialValue = Symbol('iv');
const UserPageContainerBase = ({
  onComponentReady,
  onDownloadSymptomsTrackingDataAsCSV,
  onDownloadOverallSymptomsTrackingDataAsCSV,
}) => {
  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );

  const [userInfo, setUserInfo] = useFirebaseDB({
    path: `userInfo/${customerId}`,
  });

  const { shouldUseNewSymptomTracker } = userInfo || {};

  const allSymptomTrackerQuestions = useAsyncState(
    useRef(() => getSymptomTrackerOldQuestions()).current
  );

  const customerSymptomTrackerQuestionsInitialValueRef = useRef(Symbol('iv'));
  const [customerSymptomTrackerQuestions] = useFirebaseDB({
    path: `symptomTracker/patients/${customerId}/questions`,
    initialValue: customerSymptomTrackerQuestionsInitialValueRef.current,
    defaultValue: useRef([]).current,
  });

  const symptomsTrackingAnswersInitialValueRef = useRef(Symbol('iv'));
  const [symptomsTrackingAnswers] = useFirebaseDB({
    path: `symptomTracker/answers/${customerId}`,
    initialValue: symptomsTrackingAnswersInitialValueRef.current,
    defaultValue: useRef({}).current,
  });

  const [trainingSessions] = useFirebaseDB({
    path: `trainingSessions/${customerId}`,
    defaultValue: useRef({}).current,
  });

  const [sessionAnalysis] = useFirebaseDB({
    path: `sessionAnalysis/${customerId}`,
    defaultValue: useRef({}).current,
  });

  const [brainDisorders] = useFirebaseDB({
    path: 'brainDisorders',
  });

  const [minsUsage] = useFirebaseDB({
    path: `achievements/usages/${customerId}/totalUsage/minsUsage`,
    defaultValue: useRef({}).current,
  });

  const customerBrainDisordersInitialValueRef = useRef(Symbol('iv'));
  const [customerBrainDisorders] = useFirebaseDB({
    path: `brainDisordersSymptoms/${customerId}`,
    initialValue: customerBrainDisordersInitialValueRef.current,
    defaultValue: useRef({}).current,
  });

  const isStreamingEnabledForClinicInitialValueRef = useRef(Symbol('iv'));
  const [isStreamingEnabledForClinic] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${sessionStorage.userId}/clinicFeatureDefaults/streaming/enableStreaming`,
    initialValue: isStreamingEnabledForClinicInitialValueRef.current,
    defaultValue: true,
  });

  const isStreamingEnabledForCustomerInitialValueRef = useRef(Symbol('yt'));
  const [
    isStreamingEnabledForCustomer,
    setIsStreamingEnabledForCustomer,
  ] = useFirebaseDB({
    path: `appSettings/${customerId}/isStreamingEnabled`,
    initialValue: isStreamingEnabledForCustomerInitialValueRef.current,
    defaultValue: true,
  });

  const isRapidSessionEnabledForClinicInitialValueRef = useRef(Symbol('iv'));
  const [isRapidSessionEnabledForClinic] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${sessionStorage.userId}/clinicFeatureDefaults/isRapidSessionEnabled`,
    initialValue: isRapidSessionEnabledForClinicInitialValueRef.current,
    defaultValue: false,
  });

  const isRapidSessionEnabledForCustomerInitialValueRef = useRef(Symbol('yt'));
  const [
    isRapidSessionEnabledForCustomer,
    setIsRapidSessionEnabledForCustomer,
  ] = useFirebaseDB({
    path: `appSettings/${customerId}/isRapidSessionEnabled`,
    initialValue: isRapidSessionEnabledForCustomerInitialValueRef.current,
    defaultValue: true,
  });
  const isInternalStreamingEnabledForCustomerInitialValueRef = useRef(
    Symbol('yt')
  );
  const [
    // eslint-disable-next-line no-unused-vars
    isInternalStreamingEnabledForCustomer,
    setIsInternalStreamingEnabledForCustomer,
  ] = useFirebaseDB({
    path: `appSettings/${customerId}/isInternalStreamingEnabled`,
    initialValue: isInternalStreamingEnabledForCustomerInitialValueRef.current,
    defaultValue: true,
  });

  const isYoutubeSearchEnabledForClinicInitialValueRef = useRef(Symbol('iv'));
  const [isYoutubeSearchEnabledForClinic] = useFirebaseDB({
    path: `clinicConfig/youtubeSearchEnabled/${sessionStorage.userId}/isEnabled`,
    initialValue: isYoutubeSearchEnabledForClinicInitialValueRef.current,
    defaultValue: true,
  });

  const isYoutubeSearchEnabledForCustomerInitialValueRef = useRef(Symbol('yt'));
  const [
    isYoutubeSearchEnabledForCustomer,
    setIsYoutubeSearchEnabledForCustomer,
  ] = useFirebaseDB({
    path: `youtube/youtubeSearchEnabled/${customerId}/isEnabled`,
    initialValue: isYoutubeSearchEnabledForCustomerInitialValueRef.current,
  });

  const customerNotesInitialValueRef = useRef(Symbol('yt'));
  const [
    customerNotes,
    setCustomerNotes,
    addCustomerNote,
    removeCustomerNote,
  ] = useFirebaseDB({
    path: `customerNotes/${customerId}`,
    initialValue: customerNotesInitialValueRef.current,
  });

  const [isClinicAllowedToUseYoutubeSearch] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/youtubeSearch/${sessionStorage.userId}/isAvailable`,
  });

  const [protocolsConfig] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/configurations/protocols`,
  });

  const [frequenciesConfig] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/configurations/frequencies`,
  });

  const [deployedProgram] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${customerId}`,
    initialValue,
  });

  const [numberOfCompletedSessions] = useFirebaseDB({
    path: `achievements/usages/${customerId}/totalUsage/numOfSessions`,
    defaultValue: 0,
  });

  const [clinicCurrentPackage] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}`,
  });

  const [creditForContinuityHeadset] = useFirebaseDB({
    path: 'billing/dispatched/config/credit',
    defaultValue: '(Error: Value not found in database)',
  });

  const [isContinuityEnabled] = useFirebaseDB({
    path: `clinicConfig/isContinuityEnabled/${sessionStorage.userId}`,
    defaultValue: false,
    initialValue,
  });

  const [userJourneyConfig] = useFirebaseDB({
    path: `lago/educational/users/${customerId}/journeyConfig`,
  });

  const [patientMesseges] = useFirebaseDB({
    path: `humanCentric/conversations/${sessionStorage.userId}/patients/${customerId}/channels/main/messages`,
    initialValue,
  });

  const [
    notifyAutomationsMessagesByEmail,
    setNotifyAutomationsMessagesByEmail,
  ] = useFirebaseDB({
    path: `humanCentric/conversations/${sessionStorage.userId}/patients/${customerId}/channels/main/configs/notifyManualMessagesByEmail`,
    initialValue,
  });

  const onNotifyCustomerOnMessegesChange = () => {
    setNotifyAutomationsMessagesByEmail(!notifyAutomationsMessagesByEmail);
  };

  const [customerCoppaConsent, setCustomerCoppaConsent] = useFirebaseDB({
    path: `termsConsent/coppa/patients/${sessionStorage.customerId}/v2024-06-15`,
    initialValue: 'not-ready',
  });

  const [coppaConfig] = useFirebaseDB({
    path: 'termsConsent/coppa/config/v2024-06-15',
  });

  const [userAppVersion] = useFirebaseDB({
    path: `userAppVersion/${customerId}`,
    initialValue,
  });

  const [humanCentricAppVersion] = useFirebaseDB({
    path: 'humanCentric/configs/appVersion',
    onceListener: true,
  });

  const [isUnseenEntries, setIsUnseenEntries] = useState(false);

  const checkUnseenEntries = () => {
    const allJournalEntries = [];
    const journals = userJourneyConfig && userJourneyConfig.journals;
    // eslint-disable-next-line no-unused-expressions
    journals &&
      Object.keys(journals).forEach(journalId => {
        if (journals[journalId].journalEntries) {
          Object.keys(journals[journalId].journalEntries).forEach(
            journalEntrieTimestamp => {
              allJournalEntries.push({
                itemId: journalId,
                itemEntrieTimestamp: journalEntrieTimestamp,
                data:
                  journals[journalId].journalEntries[journalEntrieTimestamp],
                type: 'journal',
              });
            }
          );
        }
      });

    // filter by shareSettings.isAvailableToClinic where null/undefined not filtered
    const filteredJournalEntries = allJournalEntries.filter(journalEntrie => {
      if (!journalEntrie.data.shareSettings) {
        return true;
      }
      if (
        journalEntrie.data.shareSettings.isAvailableToClinic === null ||
        journalEntrie.data.shareSettings.isAvailableToClinic === undefined
      ) {
        return true;
      }
      return journalEntrie.data.shareSettings.isAvailableToClinic;
    });

    const allCheckinEntries = [];
    const checkins = userJourneyConfig && userJourneyConfig.checkins;
    // eslint-disable-next-line no-unused-expressions
    checkins &&
      Object.keys(checkins).forEach(checkinId => {
        if (checkins[checkinId].journalEntries) {
          Object.keys(checkins[checkinId].journalEntries).forEach(
            checkinEntrieTimestamp => {
              allCheckinEntries.push({
                itemId: checkinId,
                itemEntrieTimestamp: checkinEntrieTimestamp,
                data:
                  checkins[checkinId].journalEntries[checkinEntrieTimestamp],
                type: 'checkin',
              });
            }
          );
        }
      });

    // filter by shareSettings.isAvailableToClinic where null/undefined not filtered
    const filteredCheckinsEntries = allCheckinEntries.filter(checkinEntrie => {
      if (!checkinEntrie.data.shareSettings) {
        return true;
      }
      if (
        checkinEntrie.data.shareSettings.isAvailableToClinic === null ||
        checkinEntrie.data.shareSettings.isAvailableToClinic === undefined
      ) {
        return true;
      }
      return checkinEntrie.data.shareSettings.isAvailableToClinic;
    });

    const allEntries = filteredJournalEntries.concat(filteredCheckinsEntries);

    let res = false;

    allEntries.forEach(entrie => {
      if (!entrie.data.isSeen) {
        res = true;
      }
    });
    setIsUnseenEntries(res);
  };

  useEffect(() => {
    checkUnseenEntries();
  }, [userJourneyConfig]);

  useEffect(() => {
    if (userInfo) {
      // required for the Patient class used by many callbacks like onResetPassword
      sessionStorage.customerId = customerId;
      sessionStorage.customerInfo = JSON.stringify(userInfo);
    }
  }, [userInfo]);

  const [headsetDetails, setHeadsetDetails] = useState(null);
  const updateHeadsetDetails = useCallback(async () => {
    try {
      const details = await fetchHeadsetDetails({ customerId });

      setHeadsetDetails(details);
    } catch (err) {
      setHeadsetDetails({});
    }
  }, []);

  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  useEffect(() => {
    (async () => {
      setAssessmentStagesInfo(
        await getStandardAssessmentStagesInfo(sessionStorage.userId)
      );
    })();
  }, []);

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  const [
    allQuestionnairesOnlyClinic,
    setAllQuestionnairesOnlyClinic,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnaires(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId })
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnaires(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId })
        );
      });
  }, []);
  useEffect(() => {
    (async () => {
      setAllQuestionnairesOnlyClinic(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnairesOnlyClinic(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
        );
      });
  }, []);

  const [
    isCustomerAppCompatiableWithSymptomQuestionnaires,
    setIsCustomerAppCompatiableWithSymptomQuestionnaires,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      const isCompatibleWithSymptomQuestionnaires = await isAppCompilableWithSymptomQuestionnaires(
        { customerId }
      );
      setIsCustomerAppCompatiableWithSymptomQuestionnaires(
        isCompatibleWithSymptomQuestionnaires
      );
    })();
  }, []);

  const [allTags] = useFirebaseDB({
    path: 'symptomTracker/clientTags',
    defaultValue: useRef({}).current,
  });

  const [selectedTags] = useFirebaseDB({
    path: `symptomTracker/selectedTags/${customerId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  const [deployedQuestionnaires] = useFirebaseDB({
    path: `symptomTracker/deployedQuestionnaires/${customerId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  const [deployedAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${customerId}`,
    initialValue,
  });

  const [sessionStatus] = useFirebaseDB({
    path: `clinics/${sessionStorage.userId}/currentSessions/${customerId}/status/text`,
  });

  useEffect(() => {
    updateHeadsetDetails();
  }, [updateHeadsetDetails]);

  useEffect(() => {
    const areSymptomTrackerDataReady = () => {
      return (
        allQuestionnaires !== null &&
        allTags !== null &&
        deployedQuestionnaires !== initialValue &&
        selectedTags !== initialValue &&
        deployedAssessment !== initialValue &&
        allSymptomTrackerQuestions !== undefined
      );
    };

    if (
      userInfo &&
      trainingSessions &&
      brainDisorders &&
      isYoutubeSearchEnabledForClinic !==
        isYoutubeSearchEnabledForClinicInitialValueRef.current &&
      customerBrainDisorders !==
        customerBrainDisordersInitialValueRef.current &&
      isYoutubeSearchEnabledForCustomer !==
        isYoutubeSearchEnabledForCustomerInitialValueRef.current &&
      customerNotes !== customerNotesInitialValueRef.current &&
      deployedProgram !== initialValue &&
      protocolsConfig !== null &&
      frequenciesConfig !== null &&
      headsetDetails !== null &&
      assessmentStagesInfo !== null &&
      clinicCurrentPackage !== null &&
      creditForContinuityHeadset !== null &&
      isCustomerAppCompatiableWithSymptomQuestionnaires !== null &&
      isContinuityEnabled !== initialValue &&
      patientMesseges !== initialValue &&
      areSymptomTrackerDataReady() &&
      customerSymptomTrackerQuestions !==
        customerSymptomTrackerQuestionsInitialValueRef.current &&
      minsUsage !== null &&
      notifyAutomationsMessagesByEmail !== initialValue &&
      customerCoppaConsent !== 'not-ready' &&
      coppaConfig !== null &&
      userAppVersion !== initialValue &&
      humanCentricAppVersion !== null &&
      !isComponentReadyToRender
    ) {
      setIsComponentReadyToRender(true);
      onComponentReady();
    }
  }, [
    allQuestionnaires,
    allSymptomTrackerQuestions,
    allTags,
    brainDisorders,
    clinicCurrentPackage,
    creditForContinuityHeadset,
    customerBrainDisorders,
    customerSymptomTrackerQuestions,
    deployedAssessment,
    deployedProgram,
    deployedQuestionnaires,
    frequenciesConfig,
    headsetDetails,
    isComponentReadyToRender,
    isContinuityEnabled,
    isCustomerAppCompatiableWithSymptomQuestionnaires,
    isYoutubeSearchEnabledForClinic,
    isYoutubeSearchEnabledForCustomer,
    onComponentReady,
    protocolsConfig,
    selectedTags,
    shouldUseNewSymptomTracker,
    trainingSessions,
    userInfo,
    assessmentStagesInfo,
    customerNotes,
    minsUsage,
    patientMesseges,
    notifyAutomationsMessagesByEmail,
    customerCoppaConsent,
    coppaConfig,
    userAppVersion,
    humanCentricAppVersion,
  ]);

  const customerInfo = {
    id: customerId,
  };

  const renderReadyComponent = () => {
    const compataibleAnswersWithNewSymptomTracking = shouldUseNewSymptomTracker
      ? getAnswersCompatibleWithNewSymptomTracking({ symptomsTrackingAnswers })
      : symptomsTrackingAnswers;

    return (
      <CustomerInfoProvider value={customerInfo}>
        <UserPage
          {...{
            assessmentStagesInfo,
            customerId,
            userInfo,
            trainingSessions,
            brainDisorders,
            isClinicAllowedToUseYoutubeSearch,
            isYoutubeSearchEnabledForClinic,
            isYoutubeSearchEnabledForCustomer,
            setIsYoutubeSearchEnabledForCustomer,
            deployedProgram,
            protocolsConfig,
            allSymptomTrackerQuestions: allSymptomTrackerQuestions || {},
            customerSymptomTrackerQuestions,
            symptomsTrackingAnswers: compataibleAnswersWithNewSymptomTracking,
            numberOfCompletedSessions,
            customerBrainDisorders: Object.keys(customerBrainDisorders),
            headsetDetails,
            clinicCurrentPackage,
            creditForContinuityHeadset,
            onDownloadSymptomsTrackingDataAsCSV,
            onDownloadOverallSymptomsTrackingDataAsCSV,
            onHeadsetDetailsUpdated: updateHeadsetDetails,
            isContinuityEnabled,
            allQuestionnaires,
            allTags,
            selectedTags: selectedTags.map(id => ({ ...allTags[id], id })),
            deployedQuestionnaires: deployedQuestionnaires.map(id => ({
              ...allQuestionnaires[id],
              id,
            })),
            deployedAssessment,
            setUserInfo,
            isCustomerAppCompatiableWithSymptomQuestionnaires,
            sessionStatus,
            isUnseenEntries,
            sessionAnalysis,
            isStreamingEnabledForClinic,
            isStreamingEnabledForCustomer,
            setIsStreamingEnabledForCustomer,
            setIsInternalStreamingEnabledForCustomer,
            customerNotes,
            setCustomerNotes,
            addCustomerNote,
            removeCustomerNote,
            minsUsage,
            isRapidSessionEnabledForClinic,
            setIsRapidSessionEnabledForCustomer,
            isRapidSessionEnabledForCustomer,
            allQuestionnairesOnlyClinic,
            patientMesseges,
            onNotifyCustomerOnMessegesChange,
            notifyAutomationsMessagesByEmail,
            customerCoppaConsent,
            setCustomerCoppaConsent,
            coppaConfig,
            userAppVersion,
            humanCentricAppVersion,
          }}
        />
      </CustomerInfoProvider>
    );
  };

  return (
    isComponentReadyToRender && clinicCurrentPackage && renderReadyComponent()
  );
};

UserPageContainerBase.propTypes = exact({
  onComponentReady: PropTypes.func.isRequired,
  onDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  onDownloadOverallSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
});

export const UserPageContainer = withErrorBoundary(
  React.memo(UserPageContainerBase),
  { componentName: 'user page' }
);
UserPageContainer.displayName = 'UserPageContainer';
