import React, { useEffect, useRef, useState } from 'react';
import exact from 'prop-types-exact';
// import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import styles from './LiveCounterPage.scss';

const LiveCounterPageBase = () => {
  const [N_sessions_milestone] = useFirebaseDB({
    path: 'public/N_sessions_milestone',
  });

  const backgroundColorRef = useRef(null);

  const [isData, setIsData] = useState(null);
  const [oldValue, setOldValue] = useState(null);
  const [digitsArr, setDigitsArr] = useState([]);
  // const [animationLocked, setAnimationLocked] = useState(false); // State to lock animation
  // const [queuedUpdate, setQueuedUpdate] = useState(null); // Store queued updates

  const audio = new Audio('../../../../sound/ding_36029.wav');

  const reverseDigitsArray = digitsArray => {
    return digitsArray.reverse();
  };

  const formatNumberWithCommas = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const initCounter = value => {
    // const currentDigits = value.toString().split('');

    const formattedValue = formatNumberWithCommas(value);
    const currentDigits = formattedValue.split('');

    const updatedDigits = [];
    currentDigits.forEach((element, idx) => {
      updatedDigits.push({
        id: idx,
        currentValue: element,
        updatedValue: element,
        shouldAnimate: false,
        isNewDigit: element === '',
      });
    });

    setDigitsArr(updatedDigits);
  };

  const AnimateChangedDigits = (oldNumber, newNumber) => {
    if (oldNumber === newNumber || oldNumber === null) return;

    // if (oldNumber === newNumber || oldNumber === null || animationLocked) {
    //   setQueuedUpdate(newNumber); // Queue update if animation is locked
    //   return;
    // }

    // setAnimationLocked(true); // Lock the animation

    // const oldDigits = oldNumber.toString().split('');
    // const newDigits = newNumber.toString().split('');

    const formattedOldValue = formatNumberWithCommas(oldNumber);
    const formattedNewValue = formatNumberWithCommas(newNumber);

    let oldDigits = formattedOldValue.split('');
    let newDigits = formattedNewValue.split('');

    oldDigits = reverseDigitsArray(oldDigits);
    newDigits = reverseDigitsArray(newDigits);

    const maxLength = Math.max(oldDigits.length, newDigits.length);
    const updatedDigits = [];

    for (let i = 0; i < maxLength; i += 1) {
      const oldDigit = oldDigits[i] || '';
      const newDigit = newDigits[i] || '';

      updatedDigits.push({
        id: i,
        currentValue: oldDigit,
        updatedValue: newDigit,
        shouldAnimate: oldDigit !== newDigit, // Animate if the digit has changed
        isNewDigit: oldDigit === '',
      });
    }

    setDigitsArr(updatedDigits.reverse());

    setTimeout(() => {
      setDigitsArr(prevDigitsArr =>
        prevDigitsArr.map(digit => ({
          ...digit,
          currentValue: digit.updatedValue,
          shouldAnimate: false, // Reset the animation flag
        }))
      );
      // setAnimationLocked(false);
    }, 2000);
    // setTimeout(() => {
    //   if (queuedUpdate !== null) {
    //     // Apply the queued update if any
    //     AnimateChangedDigits(newNumber, queuedUpdate);
    //     setQueuedUpdate(null); // Clear the queued update
    //   }
    // }, 3000);
  };

  useEffect(() => {
    backgroundColorRef.current.click();
    if (
      N_sessions_milestone !== null &&
      N_sessions_milestone?.total_nf_minutes !== oldValue
    ) {
      const newValue = N_sessions_milestone.total_nf_minutes;

      if (!oldValue) {
        initCounter(newValue);
      } else {
        AnimateChangedDigits(oldValue, newValue);
        // startAudioBtnRef.current.click();
        backgroundColorRef.current.style.backgroundColor = '#8A56C3';

        audio.play();
        setTimeout(() => {
          backgroundColorRef.current.style.backgroundColor = '#2596be';
        }, 1000); // Start the transition back after 1 second
      }
      setOldValue(newValue);
      setIsData(N_sessions_milestone);
    } else if (
      N_sessions_milestone !== null &&
      N_sessions_milestone?.total_nf_minutes === oldValue
    ) {
      setIsData(N_sessions_milestone);
    }
  }, [N_sessions_milestone]);

  const Digit = ({ currentValue, updatedValue, shouldAnimate, isNewDigit }) => {
    return (
      <div className={styles.digit_container}>
        <p
          className={classNames(
            styles.upper_digit,
            shouldAnimate ? styles.fade_out_up : '',
            currentValue === ',' ? styles.commaSeparator : ''
          )}
        >
          {currentValue}
        </p>
        <p
          className={classNames(
            styles.lower_digit,
            shouldAnimate
              ? (isNewDigit && styles.fade_in_up_new_digit) || styles.fade_in_up
              : '',
            currentValue === ',' ? styles.commaSeparator : ''
          )}
        >
          {updatedValue}
        </p>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.root)}
      ref={backgroundColorRef}
      onClick={() => {
        // console.log('i was clicked ');
      }}
    >
      {N_sessions_milestone && isData ? (
        <div
          className={styles.counter_container}
          // style={adjustCenterPosition(digitsArr.length)}
        >
          {digitsArr.map(digitObj => (
            <Digit
              key={digitObj.id}
              currentValue={digitObj.currentValue}
              updatedValue={digitObj.updatedValue}
              shouldAnimate={digitObj.shouldAnimate}
              isNewDigit={digitObj.isNewDigit}
            />
          ))}
        </div>
      ) : (
        <CircularProgress
          size={80}
          //   color="warning"
          //   style={{ backgroundColor: 'white' }}
        />
      )}
    </div>
  );
};

LiveCounterPageBase.propTypes = exact({});

export const LiveCounterPage = React.memo(LiveCounterPageBase);
LiveCounterPage.displayName = 'LiveCounterPage';
