exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DeployAssessment_size_17{font-size:17px}.DeployAssessment_size_10{font-size:10px}.DeployAssessment_size_11{font-size:11px}.DeployAssessment_size_13{font-size:13px}.DeployAssessment_size_15{font-size:15px}.DeployAssessment_size_14{font-size:14px}.DeployAssessment_size_16{font-size:16px}.DeployAssessment_size_12{font-size:12px}.DeployAssessment_extra_header{font-size:18px;font-weight:500;color:#000000}.DeployAssessment_header{font-size:17px;font-weight:500;color:#000000}.DeployAssessment_header_only_font_size{font-size:17px;font-weight:500}.DeployAssessment_sub_header{font-size:13px;color:#878787}.DeployAssessment_sub_header_black{font-size:13px;color:black}.DeployAssessment_sub_header_only_font_size{font-size:13px}.DeployAssessment_paragraph_only_font_size{font-size:12px}.DeployAssessment_tab_font_size{font-size:13px}.DeployAssessment_pop_out_header{font-size:20px}.DeployAssessment_title{font-size:15px;font-weight:500;color:#484848}.DeployAssessment_title_black{font-size:15px;font-weight:500;color:black}.DeployAssessment_title_only_font_size{font-size:15px}.DeployAssessment_paragraph{font-size:12px;color:#484848}.DeployAssessment_btn{font-size:14px}.DeployAssessment_options{display:flex;flex-wrap:wrap}.DeployAssessment_options>div{display:flex;flex-direction:column}.DeployAssessment_check_box_label{font-size:13px !important}.DeployAssessment_check_box_label .DeployAssessment_bold{font-size:10px !important;font-weight:900}.DeployAssessment_select_regions_container{max-width:150px}.DeployAssessment_eeg_measures_options{display:flex}@media only screen and (max-width: 768px){.DeployAssessment_eeg_measures_options{flex-wrap:wrap}}.DeployAssessment_alert_wrapper{margin-bottom:20px;margin-top:20px}.DeployAssessment_regions_select_input{margin-top:-5px}.DeployAssessment_cards_container{display:flex;flex-wrap:wrap}\n", ""]);

// Exports
exports.locals = {
	"size_17": "DeployAssessment_size_17",
	"size_10": "DeployAssessment_size_10",
	"size_11": "DeployAssessment_size_11",
	"size_13": "DeployAssessment_size_13",
	"size_15": "DeployAssessment_size_15",
	"size_14": "DeployAssessment_size_14",
	"size_16": "DeployAssessment_size_16",
	"size_12": "DeployAssessment_size_12",
	"extra_header": "DeployAssessment_extra_header",
	"header": "DeployAssessment_header",
	"header_only_font_size": "DeployAssessment_header_only_font_size",
	"sub_header": "DeployAssessment_sub_header",
	"sub_header_black": "DeployAssessment_sub_header_black",
	"sub_header_only_font_size": "DeployAssessment_sub_header_only_font_size",
	"paragraph_only_font_size": "DeployAssessment_paragraph_only_font_size",
	"tab_font_size": "DeployAssessment_tab_font_size",
	"pop_out_header": "DeployAssessment_pop_out_header",
	"title": "DeployAssessment_title",
	"title_black": "DeployAssessment_title_black",
	"title_only_font_size": "DeployAssessment_title_only_font_size",
	"paragraph": "DeployAssessment_paragraph",
	"btn": "DeployAssessment_btn",
	"options": "DeployAssessment_options",
	"check_box_label": "DeployAssessment_check_box_label",
	"bold": "DeployAssessment_bold",
	"select_regions_container": "DeployAssessment_select_regions_container",
	"eeg_measures_options": "DeployAssessment_eeg_measures_options",
	"alert_wrapper": "DeployAssessment_alert_wrapper",
	"regions_select_input": "DeployAssessment_regions_select_input",
	"cards_container": "DeployAssessment_cards_container"
};