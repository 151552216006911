import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import styles from './PackagePlans.scss';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { Loader } from '../../Core/Components/Loader/Loader';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import { xsMediaMaxWidth } from '../../cssInJs/constants';

const GreenCheckIcon = () => (
  <CheckIcon style={{ color: 'rgb(75, 170, 166)', fontSize: 16 }} />
);
const RedCloseIcon = () => <CloseIcon style={{ color: 'red', fontSize: 16 }} />;

const packagePlansLogger = new MyEventWrapper('package_plans');
export const PACKAGE_PLANS_TABS = {
  MONTHLY_PLANS: 'monthlyPlans',
  ANNUALLY_PLANS: 'annuallyPlans',
};

const PackagePlansBase = ({
  plans,
  colors,
  defaultTab,
  shouldShowFreeHardware,
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [showLargerPlans, setShowLargerPlans] = useState(false);

  const [includedFeaturesMonthly] = useFirebaseDB({
    path: 'billing/includedFeatures/monthly',
    onceListener: true,
  });
  const [includedFeaturesAnnually] = useFirebaseDB({
    path: 'billing/includedFeatures/annually',
    onceListener: true,
  });

  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });

  const currentIncludedFeatures =
    currentTab === PACKAGE_PLANS_TABS.MONTHLY_PLANS
      ? includedFeaturesMonthly
      : includedFeaturesAnnually;

  const isDataLoaded = !!currentIncludedFeatures;

  // const numOfRegular = plans
  //   .filter(plan =>
  //     currentTab === PACKAGE_PLANS_TABS.MONTHLY_PLANS
  //       ? plan.type === 'monthly'
  //       : plan.type === 'annually'
  //   )
  //   .filter(plan => !plan.isLargePlan).length;

  useEffect(() => {
    const currentPlan = plans.find(
      plan =>
        plan &&
        plan.action &&
        plan.action.props &&
        plan.action.props.children &&
        plan.action.props.children[0] &&
        plan.action.props.children[0].props &&
        plan.action.props.children[0].props.children &&
        plan.action.props.children[0].props.children === 'Current plan'
    );
    if (currentPlan && currentPlan.isLargePlan) {
      setShowLargerPlans(true);
    }
  }, [plans]);

  const renderPlanTabContent = () =>
    plans
      .filter(plan =>
        currentTab === PACKAGE_PLANS_TABS.MONTHLY_PLANS
          ? plan.type === 'monthly'
          : plan.type === 'annually'
      )
      .filter(plan => (showLargerPlans ? plan.isLargePlan : !plan.isLargePlan))
      .map((plan, index) => {
        const basicPlanFeatures = [
          {
            feature: (
              <p>
                {`Includes: ${plan.headsetsIncluded} active ${
                  plan.headsetsIncluded === 1
                    ? 'headset license'
                    : 'headset licenses'
                } per month`}
              </p>
            ),
            featureKey: 'headsetsIncluded',
            includedAnnually: true,
            includedMonthly: true,
          },
          {
            feature: (
              <p>
                ${plan.headsetPrice}/month for each extra active headset license
              </p>
            ),
            featureKey: 'additionalHeadsets',
            includedAnnually: true,
            includedMonthly: true,
          },
        ];

        const additionalPlanFeatures = plan.features
          ? plan.features.map(featureObject => ({
              ...featureObject,
              feature: (
                <div className={styles.additional_feature}>
                  {featureObject.includedMonthly ? (
                    <GreenCheckIcon />
                  ) : (
                    <RedCloseIcon />
                  )}
                  {featureObject.feature}
                  {featureObject.description && (
                    <TooltipWrapper
                      title={
                        <TooltipTitle>{featureObject.description}</TooltipTitle>
                      }
                    >
                      <HelpIcon
                        style={{
                          fontSize: 16,
                          marginLeft: 2,
                          color: 'grey',
                        }}
                      />
                    </TooltipWrapper>
                  )}
                </div>
              ),
            }))
          : [];

        const planFeatures = basicPlanFeatures.concat(additionalPlanFeatures);

        return (
          <Paper key={`${plan.bundleName}-${plan.type}`}>
            <div className={styles.plan}>
              <div>
                <div
                  className={styles.plan_price}
                  style={{
                    background: colors[index][0],
                  }}
                >
                  <p>
                    $
                    {currentTab === PACKAGE_PLANS_TABS.MONTHLY_PLANS
                      ? plan.basePrice
                      : plan.annualMonthlyBasePrice}
                  </p>
                  <p>per month</p>
                </div>
                <div
                  className={styles.plan_name}
                  style={{
                    background: colors[index][1],
                  }}
                >
                  <p>{plan.bundleName}</p>
                </div>
              </div>
              <div className={styles.plan_features}>
                {planFeatures.map(featureObject => (
                  <div
                    key={`${featureObject.featureKey}-${plan.type}`}
                    className={styles.feature_container}
                  >
                    {featureObject.feature}
                    <hr />
                  </div>
                ))}
              </div>
              <div className={styles.plan_action}>{plan.action}</div>
              {shouldShowFreeHardware &&
                (plan?.freeHardware?.numOfItems ?? 0) > 0 && (
                  <p className={styles.hardware_included_label}>
                    {plan.freeHardware.numOfItems > 1
                      ? `+ ${plan.freeHardware.numOfItems} hardware bundles for FREE`
                      : `+ ${plan.freeHardware.numOfItems} hardware bundle for FREE`}
                  </p>
                )}
            </div>
          </Paper>
        );
      });
  const isLargerPlansExist = plans.find(plan => plan.isLargePlan);
  return isDataLoaded ? (
    <React.Fragment>
      <Tabs
        value={currentTab}
        onChange={(event, newTab) => {
          packagePlansLogger.log('on_tab_change', {
            newTab,
          });
          setCurrentTab(newTab);
        }}
        indicatorColor="primary"
        textColor="primary"
        centered
        scrollButtons="off"
        {...(isMatchingXSScreen ? { variant: 'scrollable' } : {})}
      >
        <Tab label="monthly plans" value={PACKAGE_PLANS_TABS.MONTHLY_PLANS} />
        <Tab
          label="annual plans (2 months free)"
          value={PACKAGE_PLANS_TABS.ANNUALLY_PLANS}
        />
      </Tabs>
      <hr className={styles.plans_tab_hr} />
      {isLargerPlansExist ? (
        <div className={styles.larger_plans_container}>
          <Switch
            checked={showLargerPlans}
            onChange={e => {
              setShowLargerPlans(e.target.checked);
            }}
            color="primary"
          />
          Show me more plans
        </div>
      ) : null}
      <div className={styles.plans_tab_content_container}>
        <div className={styles.plans_row}>{renderPlanTabContent()}</div>
      </div>

      <div className={styles.included_features_in_all_plans}>
        <p>Included In All Plans</p>
        <div>
          {currentIncludedFeatures.map(feature => (
            <div key={feature}>
              <GreenCheckIcon />
              <p>{feature}</p>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Loader />
  );
};

PackagePlansBase.defaultProps = {
  defaultTab: PACKAGE_PLANS_TABS.MONTHLY_PLANS,
  shouldShowFreeHardware: false,
};

PackagePlansBase.propTypes = exact({
  plans: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  defaultTab: PropTypes.oneOf([
    PACKAGE_PLANS_TABS.MONTHLY_PLANS,
    PACKAGE_PLANS_TABS.ANNUALLY_PLANS,
  ]),
  shouldShowFreeHardware: PropTypes.bool,
});

export const PackagePlans = React.memo(PackagePlansBase);
PackagePlans.displayName = 'PackagePlans';
