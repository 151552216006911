import React, { useState, useCallback, useRef, useEffect } from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { format } from 'date-fns';
import dayJS from 'dayjs';

import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import HelpIcon from '@material-ui/icons/Help';
import InfoMUIIcon from '@material-ui/icons/Info';
// import RemoveIcon from '@material-ui/icons/Remove';
import get from 'lodash/get';
import omit from 'lodash/omit';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import FlagIcon from '../flag.png';
import InfoIcon from '../info.png';
import BlueMailIcon from '../mail.png';
import RedMailIcon from '../red-mail-icon.png';
import YellowMailIcon from '../yellow-mail-icon.png';
import { prettifySessionStatus } from '../../../models/sessions/session';
import {
  ScheduleAssessmentDialog,
  SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS,
} from '../../UserPage/AssessmentsTab/ScheduleAssessmentDialog/ScheduleAssessmentDialog';
import {
  getStandardAssessmentStagesInfo,
  defaultAfterTrainingAssessmentIntervalMinutes,
} from '../../../models/assessments/assessments';
import {
  isAnEEGRegionStage,
  getAllQuestionnaires,
} from '../../../models/symptom-tracker/symptom-tracker';
import {
  isAppCompilableWithSymptomQuestionnaires,
  switchCustomerToNewSymptomTracker,
} from '../../../models/customer/customer';
import { safeParseDate } from '../../../utils/utils';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { useClinicInfo } from '../../../Core/hooks/useClinicInfo';
import {
  androidVersionCompatiableWithSymptomQuestionnaires,
  iOSVersionCompatiableWithSymptomQuestionnaires,
} from '../../../models/symptom-tracker/new-symptom-tracker-app-versions';
import { useTranslation } from '../../../Core/hooks/useTranslation';

import {
  AddClientModal,
  addClientSteps,
} from '../../AddClientModal/AddClientModal';

import { CustomDialogSuccessMessage } from '../../../Core/Components/CustomDialogSuccessMessage/CustomDialogSuccessMessage';
import { ModalFooter } from '../../../Core/Components/Modal/ModalFooter';
import styles from './CustomerCard.scss';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { CustomDialog } from '../../../Core/Components/CustomDialog/CustomDialog';
import { PACKAGES_TYPES } from '../../../models/packages/packages';

const userPageLogger = new MyEventWrapper('user_page');
const clientPageEventLogger = new MyEventWrapper('client_page');
const selfRegEventLogger = new MyEventWrapper('self_reg');
const doneAssessmentsInitialValue = Symbol('IV');
const deployedAssessmentInitialValue = Symbol('IV');
const didClinicSetupAssessmentForThisCustomerBeforeInitialValue = Symbol('IV');
const initialValue = Symbol('iv');
const SCHEDULE_ASSESSMENT_DIALOG_OPENERS = {
  add: 'add',
  edit: 'edit',
};

const CustomerCardBase = ({
  userAdditionalData,
  userId,
  archiveCheckboxes,
  setArchiveCheckboxes,
  onEditPrograms,
  // eslint-disable-next-line camelcase
  search_box_value,
  archiveToggle,
  setDisplayRichedLimitModal,
  reachedClientsLimit,
  setAddClientReachedLimitModal,
  nfFlag,
  clinicNonfPlan,
  canAccessNf,
  assessmentAutoDeployableConfigs,
  questionnairesAutoDeployableConfigs,
  isSample,
  isNameMasking,
  maskText,
}) => {
  const t = useTranslation();
  const [minsUsage] = useFirebaseDB({
    path: `achievements/usages/${userId}/totalUsage/minsUsage`,
    defaultValue: 0,
  });

  const [numSessions] = useFirebaseDB({
    path: `achievements/usages/${userId}/totalUsage/numOfSessions`,
    defaultValue: 0,
  });

  const [presence] = useFirebaseDB({
    path: `deployedSession/${sessionStorage.userId}/${userId}/userInfo/presence`,
    defaultValue: 0,
  });

  const [isHeadsetOnlyAvailable] = useFirebaseDB({
    path: `clinicConfig/headsetAssessmentsSensing/${sessionStorage.userId}/Tp9`,
  });

  const [usedAssessmentBeforeJune24] = useFirebaseDB({
    path: `clinicConfig/assessments/usedAssessmentBeforeJune24/${sessionStorage.userId}`,
    onceListener: true,
  });

  const [isShownCheckbox, setIsShownCheckbox] = useState(false);
  const [displayUnarchiveUserModal, setDisplayUnarchiveUserModal] = useState(
    false
  );

  const [beforeSelfRegisterDialog, setBeforeSelfRegisterDialog] = useState(
    false
  );

  //   const camelCaseToSentence = text => {
  //     const result = text.replace(/([A-Z])/g, ' $1');
  //     return result.charAt(0).toUpperCase() + result.slice(1);
  //   };

  function timeConverter(unixTimestamp) {
    const a = new Date(unixTimestamp);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    // const hour = a.getHours();
    // const min = a.getMinutes();
    // const sec = a.getSeconds();
    // eslint-disable-next-line prefer-template
    const time = date + ' ' + month + ' ' + year;
    // + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  const renderDate = date => {
    if (date === 'online') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              borderRadius: '50%',
              backgroundColor: '#4BE0D0',
              width: 10,
              height: 10,
              marginRight: 5,
            }}
          />
          <div className={styles.name_text}>Online</div>
        </div>
      );
    }
    if (date <= 1 || date === null || date === undefined) {
      return <div className={styles.name_text} />;
    }
    return <div className={styles.name_text}>{timeConverter(+date)}</div>;
  };

  const onUnarchiveUserClicked = async () => {
    if (clinicNonfPlan && reachedClientsLimit) {
      setDisplayRichedLimitModal(true);
    } else {
      await database
        .ref(`deployedSession/${sessionStorage.userId}/${userId}/archived`)
        .set(false);
    }
  };

  const calcNextAssessment = () => {
    if (!userAdditionalData.isAssessmentOpen && !userAdditionalData.recurring) {
      return 'None';
    }
    if (userAdditionalData.beforeNextSession) {
      return 'On next app access';
    }
    const dayInMins = 86400000;
    let nextRecurringAssessmentTime =
      userAdditionalData &&
      userAdditionalData.assessmentOpenTimestamp &&
      Math.ceil(
        (userAdditionalData.assessmentOpenTimestamp - Date.now()) / dayInMins
      );
    if (userAdditionalData && userAdditionalData.intervalType === 'sessions') {
      nextRecurringAssessmentTime = Math.max(
        userAdditionalData.numSessions +
          userAdditionalData.frequency -
          numSessions,
        0
      );
      if (nextRecurringAssessmentTime === 0) {
        return 'On next app access';
      }
      return (
        // eslint-disable-next-line prefer-template
        nextRecurringAssessmentTime +
        ' ' +
        (nextRecurringAssessmentTime === 1 ? 'session' : 'sessions')
      );
    }
    if (userAdditionalData && userAdditionalData.intervalType === 'minutes') {
      const duration = userAdditionalData.frequency
        ? userAdditionalData.frequency
        : userAdditionalData.afterTrainingDuration;
      nextRecurringAssessmentTime = Math.round(
        userAdditionalData.minsUsage + duration - minsUsage
      );
      if (nextRecurringAssessmentTime === 0) {
        return 'On next app access';
      }
      return (
        // eslint-disable-next-line prefer-template
        nextRecurringAssessmentTime +
        ' ' +
        (nextRecurringAssessmentTime === 1 ? 'minute' : 'minutes')
      );
    }
    if (userAdditionalData && !userAdditionalData.assessmentOpenTimestamp) {
      return 'None';
    }
    if (userAdditionalData && userAdditionalData.assessmentOpenTimestamp) {
      if (nextRecurringAssessmentTime > 0) {
        return (
          // eslint-disable-next-line prefer-template
          nextRecurringAssessmentTime +
          ' ' +
          (nextRecurringAssessmentTime === 1 ? 'day' : 'days')
        );
      }
      return 'On next app access';
    }
    return 'None';
  };

  const renderNotificationRow = (
    flagType,
    messageCounter,
    greenFlagCounter,
    redFlagCounter
  ) => {
    let icon = '';
    let arrayCounter = [];
    if (flagType === 'message') {
      icon = 'message';
      arrayCounter = messageCounter;
    }
    if (flagType === 'redFlag') {
      icon = InfoIcon;
      arrayCounter = redFlagCounter;
    }
    if (flagType === 'greenFlag') {
      icon = FlagIcon;
      arrayCounter = greenFlagCounter;
    }

    const calcIcon = item => {
      if (item === 'yellowMessage') return YellowMailIcon;
      if (item === 'blueMessage' || item === 'blueMessage') return BlueMailIcon;
      if (item === 'redMessage') return RedMailIcon;
      return BlueMailIcon;
    };

    return (
      <div>
        {// eslint-disable-next-line array-callback-return, consistent-return
        arrayCounter.map((item, index) => {
          if (index < 3) {
            return (
              <img
                key={(Math.random() + 1).toString(36).substring(7) + item}
                src={icon !== 'message' ? icon : calcIcon(item)}
                width={15}
                height={15}
                style={{ marginRight: 5 }}
              />
            );
          }
        })}
        {arrayCounter.length > 3 ? (
          <span>+ {arrayCounter.length - 3}</span>
        ) : null}
      </div>
    );
  };

  const renderNotifications = () => {
    if (userAdditionalData.notifications) {
      if (Object.keys(userAdditionalData.notifications).length <= 3) {
        return Object.keys(userAdditionalData.notifications).map(
          // eslint-disable-next-line array-callback-return, consistent-return
          notificationId => {
            if (
              userAdditionalData.notifications[notificationId] ===
              'yellowMessage'
            ) {
              return (
                <img
                  key={notificationId}
                  src={YellowMailIcon}
                  width={15}
                  height={15}
                  style={{ marginRight: 5 }}
                />
              );
            }
            if (
              userAdditionalData.notifications[notificationId] ===
                'blueMessage' ||
              userAdditionalData.notifications[notificationId] === 'message'
            ) {
              return (
                <img
                  key={notificationId}
                  src={BlueMailIcon}
                  width={15}
                  height={15}
                  style={{ marginRight: 5 }}
                />
              );
            }
            if (
              userAdditionalData.notifications[notificationId] === 'redMessage'
            ) {
              return (
                <img
                  key={notificationId}
                  src={RedMailIcon}
                  width={15}
                  height={15}
                  style={{ marginRight: 5 }}
                />
              );
            }
            if (
              userAdditionalData.notifications[notificationId] === 'redFlag'
            ) {
              return (
                <img
                  key={notificationId}
                  src={InfoIcon}
                  width={15}
                  height={15}
                  style={{ marginRight: 5 }}
                />
              );
            }
            if (
              userAdditionalData.notifications[notificationId] === 'greenFlag'
            ) {
              return (
                <img
                  key={notificationId}
                  src={FlagIcon}
                  width={15}
                  height={15}
                  style={{ marginRight: 5 }}
                />
              );
            }
          }
        );
      }
      const redFlagCounter = [];
      const greenFlagCounter = [];
      const messageCounter = [];
      Object.keys(userAdditionalData.notifications).forEach(notificationId => {
        if (userAdditionalData.notifications[notificationId] === 'redFlag') {
          redFlagCounter.push('redFlag');
        }
        if (userAdditionalData.notifications[notificationId] === 'greenFlag') {
          greenFlagCounter.push('greenFlag');
        }
        if (
          userAdditionalData.notifications[notificationId]
            .toLowerCase()
            .includes('message')
        ) {
          messageCounter.push(userAdditionalData.notifications[notificationId]);
        }
      });
      messageCounter.sort((a, b) => {
        if ((a === 'blueMessage' || a === 'message') && b === 'yellowMessage') {
          return 1;
        }
        if ((a === 'blueMessage' || a === 'message') && b === 'redMessage') {
          return 1;
        }
        if (a === 'yellowMessage' && (b === 'blueMessage' || b === 'message')) {
          return -1;
        }
        if (a === 'yellowMessage' && b === 'redMessage') {
          return 1;
        }
        if (a === 'redMessage' && (b === 'blueMessage' || b === 'message')) {
          return -1;
        }
        if (a === 'redMessage' && b === 'yellowMessage') {
          return -1;
        }
        return 0;
      });
      return ['message', 'redFlag', 'greenFlag'].map(flagType => {
        return (
          <div key={flagType}>
            {renderNotificationRow(
              flagType,
              messageCounter,
              greenFlagCounter,
              redFlagCounter
            )}
          </div>
        );
      });
    }
    // return <RemoveIcon />;
    return null;
  };

  const renderEmail = text => {
    if (!text) {
      return '';
    }

    const textToMatch =
      isNameMasking && !isSample ? maskText(text, 'email') : text;
    const arr = textToMatch.match(/.{1,15}/g);

    return (
      <div className={styles.mail_text_h}>
        {arr.map(str => {
          return <div key={str}>{str}</div>;
        })}
      </div>
    );
  };

  const [isSelfRegisterModalOpen, setIsSelfRegisterModalOpen] = useState(false);
  const [toggleCompleteSetupModal, setToggleCompleteSetupModal] = useState(
    false
  );

  // ************************************************************************************************
  // *************************************ASSESSMEMT*************************************************
  // ************************************************************************************************

  const clinicInfo = useClinicInfo();

  const getDefaultAssessmentScheduleState = assessmentStages => ({
    recurring: false,
    beforeNextSession: true,
    afterTrainingDuration: defaultAfterTrainingAssessmentIntervalMinutes,
    selectedStages: assessmentStages && Object.keys(assessmentStages),
    frequency: 0,
    intervalType: 'weeks',
    isAssessmentOpen: true,
  });

  const [assessmentStagesInfo, setAssessmentStagesInfo] = useState(null);
  useEffect(() => {
    (async () => {
      setAssessmentStagesInfo(
        await getStandardAssessmentStagesInfo(sessionStorage.userId)
      );
    })();
  }, []);

  const [customerInfo, setCustomerInfo] = useFirebaseDB({
    path: `userInfo/${userId}`,
  });

  let allAssessmentStages;
  if (customerInfo && assessmentStagesInfo) {
    allAssessmentStages = customerInfo.shouldUseNewSymptomTracker
      ? assessmentStagesInfo.names
      : omit(
          assessmentStagesInfo.names,
          assessmentStagesInfo.ids.QUESTIONNAIRES
        );
  }

  const [assessmentScheduleState, setAssessmentScheduleState] = useState(
    getDefaultAssessmentScheduleState(allAssessmentStages)
  );

  const setSelectedStages = useCallback(
    selectedStages =>
      setAssessmentScheduleState(prvAssessmentScheduleState => ({
        ...prvAssessmentScheduleState,
        selectedStages,
      })),
    []
  );

  const [
    scheduleAssessmentDialogRenderedStep,
    setScheduleAssessmentDialogRenderedStep,
  ] = useState(SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions);

  const [
    scheduleAssessmentDialogOpener,
    setIsScheduleAssessmentDialogOpener,
  ] = useState(null);

  const [
    scheduleAssessmentDialogErrorMsg,
    setScheduleAssessmentDialogErrorMsg,
  ] = useState(null);

  const [
    selectedBrainDisordersInEdit,
    setSelectedBrainDisordersInEdit,
  ] = useState(null);

  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);

  const [addClientModalFinalStep, setAddClientModalFinalStep] = useState(
    undefined
  );

  const [
    mightEnableQuestionnairesInScheduleAssessmentDialog,
    setMightEnableQuestionnairesInScheduleAssessmentDialog,
  ] = useState(false);

  const [addClientModalInitialStep, setAddClientModalInitialStep] = useState(
    undefined
  );

  const [
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog,
  ] = useState(false);

  const closeAddClientModal = useCallback(() => {
    userPageLogger.log('on_close_edit_user_information_modal');
    setIsAddClientModalOpen(false);
    setAddClientModalInitialStep(undefined);
    setAddClientModalFinalStep(undefined);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(false);
    setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(false);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (questionnairesShouldBeEnabledInScheduleAssessmentDialog) {
      const prvSelectedStages = assessmentScheduleState.selectedStages || [];
      const newSelectedStages = prvSelectedStages.includes(
        assessmentStagesInfo.ids.QUESTIONNAIRES
      )
        ? prvSelectedStages
        : [assessmentStagesInfo.ids.QUESTIONNAIRES, ...prvSelectedStages];
      if (prvSelectedStages !== newSelectedStages) {
        return setSelectedStages(newSelectedStages);
      }
    }
  }, [
    assessmentScheduleState,
    questionnairesShouldBeEnabledInScheduleAssessmentDialog,
    setSelectedStages,
    assessmentStagesInfo,
  ]);

  const [selectedBrainDisorders, setSelectedBrainDisorders] = useFirebaseDB({
    path: `brainDisordersSymptoms/${userId}`,
  });

  const updateBrainDisordersInEditBasedOnSelectedBrainDisorders = useCallback(() => {
    if (selectedBrainDisorders) {
      setSelectedBrainDisordersInEdit(Object.keys(selectedBrainDisorders));
    } else {
      setSelectedBrainDisordersInEdit([]);
    }
  }, [selectedBrainDisorders]);

  useEffect(() => {
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
  }, [updateBrainDisordersInEditBasedOnSelectedBrainDisorders]);

  const [deployedAssessment, setDeployedAssessment] = useFirebaseDB({
    path: `assessments/deployedAssessments/${userId}`,
    initialValue: deployedAssessmentInitialValue,
  });

  const [brainDisorders] = useFirebaseDB({
    path: 'brainDisorders',
    onceListener: true,
  });

  const [
    didClinicSetupAssessmentForThisCustomerBefore,
    setDidClinicSetupAssessmentForThisCustomerBefore,
  ] = useFirebaseDB({
    path: `assessments/didClinicSetupAssessmentForThisCustomerBefore/${clinicInfo.id}/${userId}`,
    initialValue: didClinicSetupAssessmentForThisCustomerBeforeInitialValue,
  });

  const [allTags] = useFirebaseDB({
    path: 'symptomTracker/clientTags',
    defaultValue: useRef({}).current,
  });

  const [selectedTags] = useFirebaseDB({
    path: `symptomTracker/selectedTags/${userId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  //   const [minsUsage] = useFirebaseDB({
  //     path: `achievements/usages/${userId}/totalUsage/minsUsage`,
  //     defaultValue: 0,
  //   });

  //   const [numSessions] = useFirebaseDB({
  //     path: `achievements/usages/${customerId}/totalUsage/numOfSessions`,
  //     defaultValue: 0,
  //   });

  const [deployedQuestionnaires] = useFirebaseDB({
    path: `symptomTracker/deployedQuestionnaires/${userId}`,
    initialValue,
    defaultValue: useRef([]).current,
  });

  const [doneAssessments] = useFirebaseDB({
    path: `assessments/results/${userId}`,
    initialValue: doneAssessmentsInitialValue,
  });

  //   const deployedQuestionnaires = deployedQuestionnaires1 ? deployedQuestionnaires1.map(id => ({
  //     ...allQuestionnaires[id],
  //     id,
  //   })) : []

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnaires(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId })
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnaires(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId })
        );
      });
  }, []);
  const [
    allQuestionnairesOnlyClinic,
    setAllQuestionnairesOnlyClinic,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      setAllQuestionnairesOnlyClinic(
        await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
      );
    })();

    database
      .ref('symptomTracker/questionnaires')
      .orderByChild('clinicId')
      .equalTo(sessionStorage.userId)
      .on('value', async () => {
        setAllQuestionnairesOnlyClinic(
          await getAllQuestionnaires({ clinicId: sessionStorage.userId }, false)
        );
      });
  }, []);

  const [customerGender, setCustomerGender] = useState(null);
  const [customerDateOfBirth, setCustomerDateOfBirth] = useState(null);

  useEffect(() => {
    if (customerInfo) {
      setCustomerGender(
        customerInfo.privateDetails && customerInfo.privateDetails.gender
      );
      setCustomerDateOfBirth(
        safeParseDate({
          str: get(customerInfo, 'privateDetails.dateOfBirth'),
        })
      );
    }
  }, [customerInfo]);

  useEffect(() => {
    if (deployedAssessment) {
      setAssessmentScheduleState(deployedAssessment);
    }
  }, [deployedAssessment]);

  const [
    areScheduleAssessmentDialogEEGRegionsEnabled,
    setAreScheduleAssessmentDialogEEGRegionsEnabled,
  ] = useState(true);

  useEffect(() => {
    if (
      deployedAssessment &&
      assessmentStagesInfo &&
      deployedAssessment !== deployedAssessmentInitialValue
    ) {
      setAssessmentScheduleState(deployedAssessment);
      setAreScheduleAssessmentDialogEEGRegionsEnabled(
        (deployedAssessment.stagesType === 'headsetStages' &&
          !!(deployedAssessment.selectedStages || []).find(
            stage => stage === 'Headset'
          )) ||
          (deployedAssessment.selectedStages || []).some(stage =>
            Object.values(assessmentStagesInfo.eegIds).includes(stage)
          )
      );
    }
  }, [deployedAssessment, assessmentStagesInfo]);

  const resetScheduleAssessmentDialogState = useCallback(() => {
    if (deployedAssessment) {
      setAssessmentScheduleState(deployedAssessment);
      if (assessmentStagesInfo) {
        setAreScheduleAssessmentDialogEEGRegionsEnabled(
          (deployedAssessment.selectedStages || []).some(stage =>
            Object.values(assessmentStagesInfo.eegIds).includes(stage)
          )
        );
      }
    } else {
      setAssessmentScheduleState(
        getDefaultAssessmentScheduleState(allAssessmentStages)
      );
    }

    setCustomerGender(customerInfo.privateDetails.gender);
    setCustomerDateOfBirth(
      safeParseDate({
        str: get(customerInfo, 'privateDetails.dateOfBirth'),
      })
    );
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders();
    setScheduleAssessmentDialogErrorMsg(null);
  }, [
    deployedAssessment,
    customerInfo,
    updateBrainDisordersInEditBasedOnSelectedBrainDisorders,
    allAssessmentStages,
  ]);

  const doneAssessmentsSortedByDecreasingDate =
    doneAssessments !== doneAssessmentsInitialValue &&
    fromPairs(toPairs(doneAssessments).reverse());

  const sortedReadyDoneAssessments = pickBy(
    doneAssessmentsSortedByDecreasingDate,
    assessment => assessment.isReady && !assessment.isDeleted
  );

  const doesDoneAssessmentsHasData = !isEmpty(sortedReadyDoneAssessments);

  const symptomsTrackingAnswersInitialValueRef = useRef(Symbol('iv'));
  const [symptomsTrackingAnswers] = useFirebaseDB({
    path: `symptomTracker/answers/${userId}`,
    initialValue: symptomsTrackingAnswersInitialValueRef.current,
    defaultValue: useRef({}).current,
  });

  const shouldShowEmptyAssessmentsTab =
    symptomsTrackingAnswers &&
    !doesDoneAssessmentsHasData &&
    !deployedAssessment &&
    isEmpty(symptomsTrackingAnswers);

  const switchToNewSymptomTracker = useCallback(async () => {
    userPageLogger.log('on_customer_switched_to_new_symptom_tracker', {
      userId,
    });
    await switchCustomerToNewSymptomTracker(userId);
    setAddClientModalInitialStep(addClientSteps.selectTags);
    setIsAddClientModalOpen(true);
  }, [userId]);

  const onAddAssessment = useCallback(() => {
    userPageLogger.log('on_set_recurring_assessment', { userId });
    if (shouldShowEmptyAssessmentsTab) {
      switchToNewSymptomTracker();
    } else if (
      !customerInfo.shouldUseNewSymptomTracker &&
      didClinicSetupAssessmentForThisCustomerBefore === null
    ) {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    } else {
      setScheduleAssessmentDialogRenderedStep(
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      );
      setIsScheduleAssessmentDialogOpener(
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      );
    }
  }, [
    customerInfo,
    userId,
    didClinicSetupAssessmentForThisCustomerBefore,
    shouldShowEmptyAssessmentsTab,
    switchToNewSymptomTracker,
  ]);

  const onEditScheduleAssessmentCard = useCallback(() => {
    userPageLogger.log('on_recurring_assessment_edit', {
      userId,
    });
    setIsScheduleAssessmentDialogOpener(
      SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    );
  }, [userId]);

  const onScheduleAssessmentDialogSecondaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_secondary_btn_click', {
      userId,
      scheduleAssessmentDialogRenderedStep,
    });
    if (
      didClinicSetupAssessmentForThisCustomerBefore ||
      customerInfo.shouldUseNewSymptomTracker
    ) {
      setIsScheduleAssessmentDialogOpener(null);
      resetScheduleAssessmentDialogState();
    } else {
      setScheduleAssessmentDialogErrorMsg(null);
      if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
      } else if (
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
      ) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
        );
      } else {
        setIsScheduleAssessmentDialogOpener(null);
        resetScheduleAssessmentDialogState();
      }
    }
    // if (tab === 'assessments'){
    //   return window.open(
    //     `customers.html`,
    //     '_self'
    //   );
    // }
  }, [
    userId,
    scheduleAssessmentDialogRenderedStep,
    didClinicSetupAssessmentForThisCustomerBefore,
    customerInfo,
    resetScheduleAssessmentDialogState,
  ]);

  const sortSelectedStages = useCallback(
    (stages, stagesType) => {
      return [
        ...(stages.includes(assessmentStagesInfo.ids.QUESTIONNAIRES)
          ? [assessmentStagesInfo.ids.QUESTIONNAIRES]
          : []),
        // eslint-disable-next-line  no-nested-ternary
        ...(stagesType === 'headsetStages'
          ? areScheduleAssessmentDialogEEGRegionsEnabled
            ? ['Headset']
            : []
          : stages
              .filter(stage =>
                isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
              )
              .sort((stage1, stage2) => {
                const sortedEEGIds = Object.values(assessmentStagesInfo.eegIds);
                return (
                  sortedEEGIds.indexOf(stage1) - sortedEEGIds.indexOf(stage2)
                );
              })),
        ...(stages.includes(assessmentStagesInfo.ids.CPT)
          ? [assessmentStagesInfo.ids.CPT]
          : []),
      ];
    },
    [assessmentStagesInfo, areScheduleAssessmentDialogEEGRegionsEnabled]
  );

  const [
    isCustomerAppCompatiableWithSymptomQuestionnaires,
    setIsCustomerAppCompatiableWithSymptomQuestionnaires,
  ] = useState(null);
  useEffect(() => {
    (async () => {
      const isCompatibleWithSymptomQuestionnaires = await isAppCompilableWithSymptomQuestionnaires(
        { customerId: userId }
      );
      setIsCustomerAppCompatiableWithSymptomQuestionnaires(
        isCompatibleWithSymptomQuestionnaires
      );
    })();
  }, []);

  let filteredDoneAssessments;
  if (doneAssessments == null) {
    filteredDoneAssessments = [];
  } else {
    filteredDoneAssessments = Object.values(doneAssessments).filter(
      doneAssessment => !doneAssessment.isDeleted
    );
  }

  const handleScheduleAssessmentDialogPrimaryBtnClickEditMode = useCallback(() => {
    if (!assessmentScheduleState.recurring) {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        numSessions,
        creationDate: Date.now(),
        beforeNextSession: true,
        isAssessmentOpen: true,
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    } else {
      setDeployedAssessment({
        ...assessmentScheduleState,
        selectedStages: sortSelectedStages(
          assessmentScheduleState.selectedStages,
          assessmentScheduleState.stagesType
        ).filter(
          stage =>
            stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
            deployedQuestionnaires.length
        ),
        beforeNextSession:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : assessmentScheduleState.beforeNextSession,
        isAssessmentOpen:
          !filteredDoneAssessments.length &&
          (assessmentScheduleState.intervalType === 'months' ||
            assessmentScheduleState.intervalType === 'weeks' ||
            assessmentScheduleState.intervalType === 'days')
            ? true
            : !!assessmentScheduleState.isAssessmentOpen,
        numSessions,
        creationDate: Date.now(),
        // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
      });
    }

    setIsScheduleAssessmentDialogOpener(null);
  }, [
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);

  const handleScheduleAssessmentDialogPrimaryBtnClickAddMode = useCallback(() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm
    ) {
      if (selectedBrainDisordersInEdit.length > 0) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg(
          'Please select at least one disorder tag'
        );
      }
    } else if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm
    ) {
      if (customerDateOfBirth) {
        setScheduleAssessmentDialogRenderedStep(
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
        );
        setScheduleAssessmentDialogErrorMsg(null);
      } else {
        setScheduleAssessmentDialogErrorMsg('Date of birth is required');
      }
    } else {
      userPageLogger.log('on_schedule_assessment_for_customer', {
        userId,
        minsUsage,
        ...assessmentScheduleState,
        scheduleAssessmentDialogRenderedStep,
      });
      if (!assessmentScheduleState.recurring) {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          beforeNextSession: true,
          isAssessmentOpen: true,
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      } else {
        setDeployedAssessment({
          ...assessmentScheduleState,
          selectedStages: sortSelectedStages(
            assessmentScheduleState.selectedStages,
            assessmentScheduleState.stagesType
          ).filter(
            stage =>
              stage !== assessmentStagesInfo.ids.QUESTIONNAIRES ||
              deployedQuestionnaires.length
          ),
          beforeNextSession:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.beforeNextSession,
          isAssessmentOpen:
            !filteredDoneAssessments.length &&
            (assessmentScheduleState.intervalType === 'months' ||
              assessmentScheduleState.intervalType === 'weeks' ||
              assessmentScheduleState.intervalType === 'days')
              ? true
              : assessmentScheduleState.isAssessmentOpen,
          minsUsage,
          numSessions,
          creationDate: Date.now(),
          // stagesType: shouldTakeHeadsetOnly ? 'headsetStages' : 'stages',
        });
      }

      setDidClinicSetupAssessmentForThisCustomerBefore(true);
      setSelectedBrainDisorders(
        selectedBrainDisordersInEdit.reduce(
          (acc, brainDisorder) => ({
            ...acc,
            [brainDisorder]: true,
          }),
          {}
        )
      );

      setCustomerInfo({
        ...customerInfo,
        privateDetails: {
          ...customerInfo.privateDetails,
          dateOfBirth: format(customerDateOfBirth, 'yyyy-MM-dd'),
          gender: customerGender,
        },
      });

      setIsScheduleAssessmentDialogOpener(null);
    }
  }, [
    scheduleAssessmentDialogRenderedStep,
    selectedBrainDisordersInEdit,
    customerDateOfBirth,
    userId,
    minsUsage,
    assessmentScheduleState,
    setDeployedAssessment,
    sortSelectedStages,
    setDidClinicSetupAssessmentForThisCustomerBefore,
    setSelectedBrainDisorders,
    setCustomerInfo,
    customerInfo,
    customerGender,
    assessmentStagesInfo,
    deployedQuestionnaires,
  ]);

  const onScheduleAssessmentDialogPrimaryBtnClick = useCallback(() => {
    userPageLogger.log('on_schedule_assessment_dialog_primary_btn_click', {
      customerId: userId,
      minsUsage,
      ...assessmentScheduleState,
      scheduleAssessmentDialogRenderedStep,
      entered_from: 'customers.html',
      modification:
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
          ? 'edit'
          : 'add',
    });
    if (
      scheduleAssessmentDialogOpener === SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit
    ) {
      handleScheduleAssessmentDialogPrimaryBtnClickEditMode();
    }
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode();
    // if (tab === 'assessments'){
    //   return window.open(
    //     `customers.html`,
    //     '_self'
    //   );
    // }
  }, [
    scheduleAssessmentDialogOpener,
    handleScheduleAssessmentDialogPrimaryBtnClickEditMode,
    handleScheduleAssessmentDialogPrimaryBtnClickAddMode,
    userId,
    scheduleAssessmentDialogRenderedStep,
    assessmentScheduleState,
    minsUsage,
  ]);

  const isScheduleAssessmentDialogPrimaryBtnDisabled = (() => {
    if (
      scheduleAssessmentDialogRenderedStep ===
      SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
    ) {
      if (
        scheduleAssessmentDialogOpener ===
        SCHEDULE_ASSESSMENT_DIALOG_OPENERS.add
      ) {
        return (
          (assessmentScheduleState.recurring &&
            (!assessmentScheduleState.frequency ||
              assessmentScheduleState.frequency < 1 ||
              !assessmentScheduleState.intervalType)) ||
          (assessmentScheduleState.selectedStages || []).length === 0
        );
      }

      return (
        !assessmentScheduleState.frequency ||
        assessmentScheduleState.frequency < 1 ||
        (assessmentScheduleState.selectedStages || []).length === 0
      );
    }

    return false;
  })();

  const questionnairesStageDisabledReason = (() => {
    if (!isCustomerAppCompatiableWithSymptomQuestionnaires) {
      return `In order to be able to select questionnaires the user needs to update to a version above ${androidVersionCompatiableWithSymptomQuestionnaires} in Android or above ${iOSVersionCompatiableWithSymptomQuestionnaires} in iOS.`;
    }

    if (deployedQuestionnaires.length === 0) {
      return 'You need to setup questionnaires to enable symptom tracking.';
    }

    return null;
  })();

  const onSelectedStagesChange = useCallback(
    newSelectedStages => {
      setSelectedStages(newSelectedStages);
    },
    [setSelectedStages]
  );

  const onAfterTrainingDurationChange = useCallback(
    event =>
      setAssessmentScheduleState({
        ...assessmentScheduleState,
        afterTrainingDuration: parseInt(event.target.value, 10),
      }),
    [assessmentScheduleState]
  );

  const onDisorderClick = useCallback(
    disorder => {
      let newSelectedBrainDisorders;
      if (selectedBrainDisordersInEdit.includes(disorder)) {
        newSelectedBrainDisorders = selectedBrainDisordersInEdit.filter(
          selectedDisorder => selectedDisorder !== disorder
        );
      } else {
        newSelectedBrainDisorders = [...selectedBrainDisordersInEdit, disorder];
      }

      setSelectedBrainDisordersInEdit(newSelectedBrainDisorders);
    },
    [selectedBrainDisordersInEdit]
  );

  const onSetupQuestionnaires = useCallback(() => {
    userPageLogger.log('on_setup_questionnaires', {
      userId,
    });
    setAddClientModalInitialStep(addClientSteps.selectQuestionnaires);
    setAddClientModalFinalStep(addClientSteps.selectQuestionnaires);
    setMightEnableQuestionnairesInScheduleAssessmentDialog(true);
    setIsAddClientModalOpen(true);
  }, [userId]);

  const renderScheduleAssessmentDialog = () => {
    if (brainDisorders) {
      const primaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
        SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions
          ? t('save')
          : t('next');

      const secondaryBtnText =
        scheduleAssessmentDialogRenderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm ||
        scheduleAssessmentDialogOpener ===
          SCHEDULE_ASSESSMENT_DIALOG_OPENERS.edit ||
        didClinicSetupAssessmentForThisCustomerBefore ||
        (customerInfo && customerInfo.shouldUseNewSymptomTracker)
          ? t('cancel')
          : t('back');

      return (
        <ScheduleAssessmentDialog
          assessmentStagesInfo={assessmentStagesInfo}
          isOpen={!!scheduleAssessmentDialogOpener}
          onSecondaryBtnClick={onScheduleAssessmentDialogSecondaryBtnClick}
          onPrimaryBtnClick={onScheduleAssessmentDialogPrimaryBtnClick}
          isPrimaryBtnDisabled={isScheduleAssessmentDialogPrimaryBtnDisabled}
          onSelectedStagesChange={onSelectedStagesChange}
          selectedStages={assessmentScheduleState.selectedStages || []}
          afterTrainingDuration={assessmentScheduleState.afterTrainingDuration}
          onAfterTrainingDurationChange={onAfterTrainingDurationChange}
          onRecurringAssessmentCheckboxChange={event => {
            if (!JSON.parse(event.target.value)) {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
                isAssessmentOpen: true,
                beforeNextSession: true,
              });
            } else {
              setAssessmentScheduleState({
                ...assessmentScheduleState,
                recurring: JSON.parse(event.target.value),
              });
            }
          }}
          onBeforeNextSessionCheckboxChange={(event, checked) => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              beforeNextSession: checked,
              isAssessmentOpen: checked,
            });
          }}
          isRecurringAssessmentChecked={assessmentScheduleState.recurring}
          isBeforeNextSessionAssessmentChecked={
            assessmentScheduleState.beforeNextSession
          }
          frequency={assessmentScheduleState.frequency}
          intervalType={assessmentScheduleState.intervalType || ''}
          onFrequencyChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              frequency:
                event.target.value === '' ? '' : JSON.parse(event.target.value),
            })
          }
          onIntervalTypeChange={event =>
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              intervalType: event.target.value,
            })
          }
          brainDisorders={brainDisorders}
          selectedBrainDisorders={selectedBrainDisordersInEdit}
          renderedStep={scheduleAssessmentDialogRenderedStep}
          onDisorderClick={onDisorderClick}
          customerDateOfBirth={customerDateOfBirth}
          customerGender={customerGender}
          onCustomerDateOfBirthChange={dateOfBirth =>
            setCustomerDateOfBirth(dateOfBirth)
          }
          onCustomerGenderChange={event =>
            setCustomerGender(event.target.value)
          }
          scheduleAssessmentDialogErrorMsg={scheduleAssessmentDialogErrorMsg}
          primaryBtnText={primaryBtnText}
          secondaryBtnText={secondaryBtnText}
          onClose={() => {
            setIsScheduleAssessmentDialogOpener(null);
            resetScheduleAssessmentDialogState();
          }}
          questionnairesStageDisabledReason={questionnairesStageDisabledReason}
          shouldShowSetupQuestionnairesButton={
            deployedQuestionnaires.length === 0 &&
            isCustomerAppCompatiableWithSymptomQuestionnaires
          }
          areEEGRegionsEnabled={areScheduleAssessmentDialogEEGRegionsEnabled}
          onEEGRegionsCheckboxChange={event => {
            if (!nfFlag) {
              if (canAccessNf) {
                new MyEvent('add_neuro_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              } else {
                new MyEvent('contact_sales_modal').log({
                  from_where: 'clients_page',
                  action: 'open',
                });
              }
              setAddClientReachedLimitModal(true);
              return;
            }
            setAreScheduleAssessmentDialogEEGRegionsEnabled(
              event.target.checked
            );
            if (!event.target.checked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || []).filter(
                  stage =>
                    !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds) &&
                    stage !== 'Headset'
                )
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []),
                ]),
              ]);
            }
          }}
          shouldUseNewSymptomTracker={
            customerInfo && customerInfo.shouldUseNewSymptomTracker
          }
          onSetupQuestionnaires={onSetupQuestionnaires}
          deployedQuestionnaires={
            deployedQuestionnaires && allQuestionnaires
              ? deployedQuestionnaires.map(id => ({
                  ...allQuestionnaires[id],
                  id,
                }))
              : []
          }
          doneAssessments={!!filteredDoneAssessments.length}
          customerId={userId}
          nfFlag={nfFlag}
          isHeadsetOnly={assessmentScheduleState.stagesType === 'headsetStages'}
          setIsHeadsetOnly={isChecked => {
            setAssessmentScheduleState({
              ...assessmentScheduleState,
              stagesType: isChecked ? 'headsetStages' : 'stages',
            });
            if (isChecked) {
              setSelectedStages(
                (assessmentScheduleState.selectedStages || [])
                  .filter(
                    stage =>
                      !isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
                  )
                  .concat(['Headset'])
              );
            } else {
              setSelectedStages([
                ...new Set([
                  ...Object.values(assessmentStagesInfo.eegIds).filter(
                    id => id !== 'Back'
                  ),
                  ...(assessmentScheduleState.selectedStages || []).filter(
                    stage => stage !== 'Headset'
                  ),
                ]),
              ]);
            }
          }}
          isHeadsetOnlyAvailable={!!isHeadsetOnlyAvailable}
          usedAssessmentBeforeJune24={usedAssessmentBeforeJune24}
        />
      );
    }

    return null;
  };

  const renderAddClientModal = () => {
    const renderCustomizedAddClientModalSuccessMessage =
      // eslint-disable-next-line react/prop-types
      ({ assessmentOptions, selectedQuestionnaires }) => {
        const isAssessmentEnabled =
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingBeforeFirstSession ||
          // eslint-disable-next-line react/prop-types
          assessmentOptions.shouldBeTrackingPerMinutes;

        if (
          addClientModalFinalStep === addClientSteps.selectQuestionnaires &&
          (isAssessmentEnabled ||
            mightEnableQuestionnairesInScheduleAssessmentDialog)
        ) {
          return (
            <CustomDialogSuccessMessage
              headerText="Questionnaires updated!"
              content={
                <p style={{ margin: 0 }}>
                  {// eslint-disable-next-line react/prop-types
                  selectedQuestionnaires.length === 0 ? (
                    <>{t('no-questionnaires-will-be-shown')}</>
                  ) : (
                    <>
                      {t(
                        'user-will-be-shown-updated-questionnaires-on-next-assessment-checkpoint'
                      )}
                    </>
                  )}
                  <ModalFooter
                    primaryBtnContent={t('done')}
                    onPrimaryBtnClick={() => {
                      closeAddClientModal();
                    }}
                  />
                </p>
              }
            />
          );
        }

        return (
          <CustomDialogSuccessMessage
            headerText={
              isAssessmentEnabled
                ? t('progress-tracking-is-enabled')
                : t('progress-tracking-is-disabled')
            }
            content={
              <p style={{ margin: 0 }}>
                {isAssessmentEnabled ? (
                  <>{t('user-will-be-asked-to-complete-assessment')}</>
                ) : (
                  <>{t('user-will-not-be-asked-to-complete-assessment')}</>
                )}
                <ModalFooter
                  primaryBtnContent={t('done')}
                  onPrimaryBtnClick={() => {
                    closeAddClientModal();
                  }}
                />
              </p>
            }
          />
        );
      };

    const updateQuestionnairesShouldBeEnabledIfNeeded = ({
      selectedQuestionnaires,
    }) => {
      if (
        mightEnableQuestionnairesInScheduleAssessmentDialog &&
        // eslint-disable-next-line react/prop-types
        selectedQuestionnaires.length > 0
      ) {
        setQuestionnairesShouldBeEnabledInScheduleAssessmentDialog(true);
      }
    };

    const renderSuccessMessage =
      addClientModalInitialStep === addClientSteps.selectQuestionnaires
        ? props => {
            updateQuestionnairesShouldBeEnabledIfNeeded(props);
            return (
              <div style={{ padding: '20px' }}>
                {' '}
                {renderCustomizedAddClientModalSuccessMessage(props)}
              </div>
            );
          }
        : undefined;

    const assessmentSelectedStages =
      (deployedAssessment && deployedAssessment.selectedStages) ||
      Object.values(assessmentStagesInfo.ids);
    const initialAssessmentOptions = deployedAssessment
      ? {
          shouldBeTrackingPerMinutes: deployedAssessment.recurring,
          shouldBeTrackingBeforeFirstSession:
            deployedAssessment.beforeNextSession,
          perMinutesTracking: deployedAssessment.afterTrainingDuration,
          isSymptomQuestionnaireEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.QUESTIONNAIRES
          ),
          isCPTEnabled: assessmentSelectedStages.includes(
            assessmentStagesInfo.ids.CPT
          ),
          isEEGEnabled: Object.values(
            assessmentStagesInfo.eegIds
          ).some(sensingRegionId =>
            assessmentSelectedStages.includes(sensingRegionId)
          ),
        }
      : undefined;

    return (
      <AddClientModal
        isEditingMode
        isModalOpen
        userId={userId}
        userInfo={customerInfo}
        brainDisorders={brainDisorders}
        closeModal={closeAddClientModal}
        allQuestionnaires={allQuestionnairesOnlyClinic}
        allTags={allTags}
        initialSelectedTags={selectedTags}
        initialSelectedQuestionnaires={deployedQuestionnaires.map(id => ({
          ...allQuestionnaires[id],
          id,
        }))}
        shouldUseNewSymptomTracker={customerInfo.shouldUseNewSymptomTracker}
        initialAssessmentOptions={initialAssessmentOptions}
        initialSelectedAssessmentRegions={assessmentSelectedStages.filter(
          stage => Object.values(assessmentStagesInfo.eegIds).includes(stage)
        )}
        initialStep={addClientModalInitialStep}
        finalStep={addClientModalFinalStep}
        isCustomerAppCompatiableWithSymptomQuestionnaires={
          isCustomerAppCompatiableWithSymptomQuestionnaires
        }
        renderSuccessMessage={renderSuccessMessage}
        assessmentStagesInfo={assessmentStagesInfo}
        nfFlag={nfFlag}
      />
    );
  };

  // ************************************************************************************************
  // *************************************ASSESSMEMT-END*********************************************
  // ************************************************************************************************

  const renderSelfRegister = () => {
    return (
      <AddClientModal
        isEditingMode
        isModalOpen
        userId={userId}
        userInfo={customerInfo}
        clinicId={sessionStorage.userId}
        allQuestionnaires={allQuestionnairesOnlyClinic}
        allTags={allTags}
        assessmentStagesInfo={assessmentStagesInfo}
        closeModal={() => {
          setIsSelfRegisterModalOpen(false);
        }}
        initialStep={addClientSteps.createAccount}
        finalStep={addClientSteps.digitalCare}
        shouldUseNewSymptomTracker
        isSelfRegistration
        nfFlag={nfFlag}
        assessmentAutoDeployableConfigs={assessmentAutoDeployableConfigs}
        questionnairesAutoDeployableConfigs={
          questionnairesAutoDeployableConfigs
        }
        doneAssessments={doneAssessments}
      />
    );
  };

  const setupSelfRegister = () => {
    sessionStorage.customerId = userId;
    if (sessionStorage.packageType === PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE) {
      showNotification(
        'danger',
        'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
      );
      return;
    }
    if (archiveToggle) {
      setDisplayUnarchiveUserModal(true);
      return;
    }
    if (clinicNonfPlan && reachedClientsLimit) {
      setDisplayRichedLimitModal(true);
      return;
    }
    selfRegEventLogger.log('setup_now_clicked', {
      patientId: userId,
      from: 'clients',
    });
    // setAddClientModalInitialStep(addClientSteps.selectTags);
    // setAddClientModalFinalStep(addClientSteps.trackingSchedule);
    setIsSelfRegisterModalOpen(true);
  };

  if (userAdditionalData) {
    if (userAdditionalData.clinicianSetUpRequired) {
      return (
        <div className={styles.self_reg_container}>
          <div className={styles.customer_card_container_self_reg}>
            <div
              style={{ width: '16%', cursor: 'pointer', display: 'flex' }}
              onClick={() => {
                sessionStorage.customerId = userId;
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'client_name',
                  team_member_id: sessionStorage.teamMemberId,
                  search_box_value,
                  customerId: userId,
                  client_name: `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
                });
                if (
                  sessionStorage.packageType ===
                  PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
                ) {
                  showNotification(
                    'danger',
                    'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                  );
                  return;
                }
                if (archiveToggle) {
                  setDisplayUnarchiveUserModal(true);
                  return;
                }
                if (clinicNonfPlan && reachedClientsLimit) {
                  setDisplayRichedLimitModal(true);
                  return;
                }
                setToggleCompleteSetupModal(true);
              }}
              onMouseEnter={() => setIsShownCheckbox(true)}
              onMouseLeave={() => setIsShownCheckbox(false)}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Checkbox
                  color="primary"
                  style={
                    isShownCheckbox || archiveCheckboxes[userId]
                      ? { visibility: 'visible' }
                      : { visibility: 'hidden' }
                  }
                  checked={archiveCheckboxes[userId]}
                  onClick={e => e.stopPropagation()}
                  onChange={e => {
                    const copy = JSON.parse(JSON.stringify(archiveCheckboxes));
                    copy[userId] = e.target.checked;
                    setArchiveCheckboxes(copy);
                    clientPageEventLogger.log('item_clicked', {
                      button_clicked: 'client_check_box',
                      checkbox_value: e.target.checked,
                      checkbox_user_id: userId,
                      team_member_id: sessionStorage.teamMemberId,
                      search_box_value,
                      customerId: userId,
                    });
                  }}
                />
              </div>
              <div className={styles.name_mail_container}>
                <div className={styles.name_text_h}>
                  {isNameMasking && !isSample
                    ? maskText(
                        `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
                        'name'
                      )
                    : `${userAdditionalData.firstName} ${userAdditionalData.lastName}`}
                </div>
                {renderEmail(userAdditionalData.email)}
              </div>
              {isShownCheckbox ? (
                <LaunchIcon
                  style={{
                    width: 15,
                    height: 15,
                    color: '#2196f3',
                    alignSelf: 'center',
                    marginLeft: 5,
                  }}
                  onClick={e => {
                    sessionStorage.customerId = userId;
                    e.stopPropagation();
                    clientPageEventLogger.log('item_clicked', {
                      button_clicked: 'client_name',
                      team_member_id: sessionStorage.teamMemberId,
                      search_box_value,
                      customerId: userId,
                    });
                    if (
                      sessionStorage.packageType ===
                      PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
                    ) {
                      showNotification(
                        'danger',
                        'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                      );
                      return;
                    }
                    if (archiveToggle) {
                      setDisplayUnarchiveUserModal(true);
                      return;
                    }
                    setToggleCompleteSetupModal(true);
                  }}
                />
              ) : null}
            </div>
            <div style={{ width: '16%' }} className={styles.name_text}>
              {userAdditionalData.signupTimestamp
                ? renderDate(userAdditionalData.signupTimestamp)
                : ''}
            </div>
            {// eslint-disable-next-line no-nested-ternary
            presence === 'online' &&
            userAdditionalData.statusState &&
            userAdditionalData.statusState === 'offline' ? (
              <div style={{ width: '16%' }} />
            ) : presence !== 'online' ||
              (userAdditionalData.statusState &&
                userAdditionalData.statusState === 'offline') ? (
              <div
                style={{ width: '16%', display: 'flex', alignItems: 'center' }}
              >
                {renderDate(presence)}
              </div>
            ) : (
              <div
                style={{ width: '16%', display: 'flex', alignItems: 'center' }}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#4BE0D0',
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    opacity: userAdditionalData.statusState ? 1 : 0,
                  }}
                />
                {userAdditionalData.statusState
                  ? `Now: ${prettifySessionStatus(
                      userAdditionalData.statusState
                    )}`
                  : null}
              </div>
            )}
            <div style={{ width: '16%' }} className={styles.name_text}>
              {renderNotifications()}
            </div>
            {/* {userAdditionalData.isJourneyEnabled ? (
              <div
                style={{ width: '16%' }}
                className={styles.journey_container}
                onClick={() => {
                  if (clinicNonfPlan && reachedClientsLimit) {
                    setDisplayRichedLimitModal(true);
                    return;
                  }
                  setBeforeSelfRegisterDialog(true);
                }}
              >
                <div>
                  {userAdditionalData.journeyName
                    ? userAdditionalData.journeyName
                    : null}
                </div>
                <div>
                  {userAdditionalData.precentageDone
                    ? userAdditionalData.precentageDone.toFixed(0)
                    : '0'}
                  % journey completion
                </div>
                {userAdditionalData.currentModule ? (
                  <div>Current: {userAdditionalData.currentModule}</div>
                ) : null}
              </div>
            ) : (
              <div style={{ width: '16%' }} />
            )} */}
            {doneAssessments ? (
              <div
                style={{ width: '16%' }}
                className={styles.assessment_container}
                onClick={() => {
                  if (clinicNonfPlan && reachedClientsLimit) {
                    setDisplayRichedLimitModal(true);
                    return;
                  }
                  setBeforeSelfRegisterDialog(true);
                }}
              >
                {userAdditionalData.usersLastAssessmentTime ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Last:{' '}
                    {timeConverter(userAdditionalData.usersLastAssessmentTime)}
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </div>
                ) : null}
                {userAdditionalData.usersLastAssessmentTime ? (
                  <div>Next: {calcNextAssessment()}</div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>Next: {calcNextAssessment()}</div>
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </div>
                )}
                {userAdditionalData.numOfDoneAssessments ? (
                  <div>{userAdditionalData.numOfDoneAssessments} completed</div>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  width: '16%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (clinicNonfPlan && reachedClientsLimit) {
                    setDisplayRichedLimitModal(true);
                    return;
                  }
                  setBeforeSelfRegisterDialog(true);
                }}
              >
                <div className={styles.add_btn} style={{ color: '#0076F5' }}>
                  ADD +
                </div>
              </div>
            )}
            {userAdditionalData.usersNumOfPrograms ? (
              <div
                style={
                  userAdditionalData.isExpired
                    ? {
                        color: '#fa625d',
                        backgroundColor: '#feddcf',
                        width: '16%',
                      }
                    : { width: '16%' }
                }
                className={styles.neurofeedback_container}
                onClick={() => {
                  if (clinicNonfPlan && reachedClientsLimit) {
                    setDisplayRichedLimitModal(true);
                    return;
                  }
                  setBeforeSelfRegisterDialog(true);
                }}
              >
                {userAdditionalData.isExpired ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    The client lost access to programs on{' '}
                    {dayJS(userAdditionalData.isExpired).format(
                      DAY_JS_DATE_FORMATS.american
                    )}
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </div>
                ) : null}
                {userAdditionalData.lastSessionDone ? (
                  <div
                    style={
                      userAdditionalData.isExpired
                        ? { display: 'none' }
                        : {
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }
                    }
                  >
                    Last: {timeConverter(userAdditionalData.lastSessionDone)}
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </div>
                ) : null}
                {userAdditionalData.lastSessionDone ? (
                  <div
                    style={
                      userAdditionalData.isExpired ? { display: 'none' } : null
                    }
                  >
                    {`${userAdditionalData.usersTotalSessions -
                      userAdditionalData.usersProgramsSessionsLeft} / ${
                      userAdditionalData.usersTotalSessions
                    }`}{' '}
                    sessions done
                  </div>
                ) : (
                  <div
                    style={
                      userAdditionalData.isExpired
                        ? { display: 'none' }
                        : {
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }
                    }
                  >
                    <div>
                      {`${userAdditionalData.usersTotalSessions -
                        userAdditionalData.usersProgramsSessionsLeft} / ${
                        userAdditionalData.usersTotalSessions
                      }`}{' '}
                      sessions done
                    </div>
                    <EditIcon style={{ width: 14, height: 14 }} />
                  </div>
                )}
                {userAdditionalData.usersNumOfPrograms > 1 ? (
                  <div
                    style={
                      userAdditionalData.isExpired ? { display: 'none' } : null
                    }
                  >
                    {`${userAdditionalData.usersNumOfPrograms -
                      userAdditionalData.usersProgramsLeft} / ${
                      userAdditionalData.usersNumOfPrograms
                    }`}{' '}
                    programs completed
                  </div>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  width: '16%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (clinicNonfPlan && reachedClientsLimit) {
                    setDisplayRichedLimitModal(true);
                    return;
                  }
                  setBeforeSelfRegisterDialog(true);
                }}
              >
                <div className={styles.add_btn} style={{ color: '#4BE0D0' }}>
                  ADD +
                </div>
              </div>
            )}
            {isSelfRegisterModalOpen && renderSelfRegister()}
            <CustomDialog
              isOpen={toggleCompleteSetupModal}
              onPrimaryBtnClick={() => {
                setToggleCompleteSetupModal(false);
                setIsSelfRegisterModalOpen(true);
                selfRegEventLogger.log('setup_now_clicked', {
                  patientId: userId,
                  from: 'clients',
                });
              }}
              onSecondaryBtnClick={() => {
                setToggleCompleteSetupModal(false);
              }}
              primaryBtnContent="SET UP NOW"
            >
              <p className={styles.dialog_header}>
                This client self-registered using your clinic code!
              </p>
              <p className={styles.dialog_paragraph}>
                You’ll need to complete client setup to be able to view their
                data and create programs.
              </p>
            </CustomDialog>
          </div>
          <div className={styles.self_reg_label}>
            This client has self-registered using your clinic code.
            <TooltipWrapper
              style={{ width: 16, height: 16, position: 'absolute' }}
              title={
                <TooltipTitle>
                  This client currently has access to the basic version of the
                  Myndlift app. To unlock more capabilities, please fill in the
                  client’s details and set them up with the needed program.
                </TooltipTitle>
              }
            >
              <HelpIcon />
            </TooltipWrapper>
          </div>
          <Dialog
            open={beforeSelfRegisterDialog}
            onClose={() => {
              setBeforeSelfRegisterDialog(false);
            }}
          >
            <DialogTitle style={{ alignSelf: 'center' }}>
              <div style={{ fontSize: 16, fontWeight: 400 }}>
                This client self registered using your clinic code!
              </div>
            </DialogTitle>
            <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
              <div style={{ fontSize: 13, textAlign: 'center' }}>
                You'll need to complete client setup to be able to view their
                data and create programs.
              </div>
            </DialogContent>
            <ModalFooter
              primaryBtnContent="set up now"
              secondaryBtnContent="cancel"
              onPrimaryBtnClick={() => {
                setBeforeSelfRegisterDialog(false);
                setupSelfRegister();
              }}
              onSecondaryBtnClick={() => {
                setBeforeSelfRegisterDialog(false);
              }}
            />
          </Dialog>
        </div>
      );
    }
    return (
      <div
        className={styles.customer_card_container}
        style={isSample ? { paddingLeft: 0 } : null}
      >
        <div
          style={
            isSample
              ? {
                  width: '16%',
                  cursor: 'pointer',
                  display: 'flex',
                  backgroundColor: '#f0e8ff',
                  height: '126%',
                  color: '#7d3ff0',
                  alignItems: 'center',
                  borderRadius: '0 50px 50px 0',
                }
              : { width: '16%', cursor: 'pointer', display: 'flex' }
          }
          onClick={() => {
            sessionStorage.customerId = userId;
            clientPageEventLogger.log('item_clicked', {
              button_clicked: 'client_name',
              team_member_id: sessionStorage.teamMemberId,
              search_box_value,
              customerId: userId,
              client_name: `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
            });
            if (archiveToggle) {
              setDisplayUnarchiveUserModal(true);
              return;
            }
            setTimeout(() => {
              sessionStorage.customerId = userId;
              return window.open(
                `userPage.html?customerId=${sessionStorage.customerId}`,
                '_self'
              );
            }, 300);
          }}
          onMouseEnter={() => setIsShownCheckbox(true)}
          onMouseLeave={() => setIsShownCheckbox(false)}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              color="primary"
              style={
                isShownCheckbox || archiveCheckboxes[userId]
                  ? { visibility: 'visible' }
                  : { visibility: 'hidden' }
              }
              checked={archiveCheckboxes[userId]}
              onClick={e => e.stopPropagation()}
              onChange={e => {
                const copy = JSON.parse(JSON.stringify(archiveCheckboxes));
                copy[userId] = e.target.checked;
                setArchiveCheckboxes(copy);
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'client_check_box',
                  checkbox_value: e.target.checked,
                  checkbox_user_id: userId,
                  team_member_id: sessionStorage.teamMemberId,
                  search_box_value,
                  customerId: userId,
                });
              }}
            />
          </div>
          <div className={styles.name_mail_container}>
            <div className={styles.name_text_h}>
              {isNameMasking && !isSample
                ? maskText(
                    `${userAdditionalData.firstName} ${userAdditionalData.lastName}`,
                    'name'
                  )
                : `${userAdditionalData.firstName} ${userAdditionalData.lastName}`}
            </div>
            {renderEmail(userAdditionalData.email)}
          </div>
          {isSample ? (
            <TooltipWrapper
              style={{
                width: 16,
                height: 16,
                position: 'relative',
                right: -10,
              }}
              title={
                <TooltipTitle>
                  This is an auto-filled client profile designed to help you
                  explore various features and functionalities of the clinical
                  dashboard.
                </TooltipTitle>
              }
            >
              <InfoMUIIcon />
            </TooltipWrapper>
          ) : null}
          {isShownCheckbox ? (
            <LaunchIcon
              style={{
                width: 15,
                height: 15,
                color: '#2196f3',
                alignSelf: 'center',
                marginLeft: isSample ? 15 : 5,
              }}
              onClick={e => {
                sessionStorage.customerId = userId;
                e.stopPropagation();
                clientPageEventLogger.log('item_clicked', {
                  button_clicked: 'client_name',
                  team_member_id: sessionStorage.teamMemberId,
                  search_box_value,
                  customerId: userId,
                });
                if (archiveToggle) {
                  setDisplayUnarchiveUserModal(true);
                  return;
                }
                sessionStorage.customerId = userId;
                // eslint-disable-next-line consistent-return
                return window.open(
                  `userPage.html?customerId=${sessionStorage.customerId}`,
                  '_blank'
                );
              }}
            />
          ) : null}
        </div>
        <div style={{ width: '16%' }} className={styles.name_text}>
          {userAdditionalData.signupTimestamp
            ? renderDate(userAdditionalData.signupTimestamp)
            : ''}
        </div>
        {// eslint-disable-next-line no-nested-ternary
        presence === 'online' &&
        userAdditionalData.statusState &&
        userAdditionalData.statusState === 'offline' ? (
          <div style={{ width: '16%' }} />
        ) : presence !== 'online' ||
          (userAdditionalData.statusState &&
            userAdditionalData.statusState === 'offline') ? (
          <div style={{ width: '16%', display: 'flex', alignItems: 'center' }}>
            {renderDate(presence)}
          </div>
        ) : (
          <div style={{ width: '16%', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#4BE0D0',
                width: 10,
                height: 10,
                marginRight: 5,
                opacity: userAdditionalData.statusState ? 1 : 0,
              }}
            />
            {userAdditionalData.statusState
              ? `Now: ${prettifySessionStatus(userAdditionalData.statusState)}`
              : null}
          </div>
        )}
        <div style={{ width: '16%' }} className={styles.name_text}>
          {renderNotifications()}
        </div>
        {/* {userAdditionalData.isJourneyEnabled ? (
          <div
            style={{ width: '16%' }}
            className={styles.journey_container}
            onClick={() => {
              sessionStorage.customerId = userId;
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'education',
                assessment_click_type: 'edit',
                team_member_id: sessionStorage.teamMemberId,
                customerId: userId,
              });
              window.open(
                `userPage.html?customerId=${userId}&tab=behaviouralProgress`,
                '_blank'
              );
            }}
          >
            <div>
              {userAdditionalData.journeyName
                ? userAdditionalData.journeyName
                : null}
            </div>
            <div>
              {userAdditionalData.precentageDone
                ? userAdditionalData.precentageDone.toFixed(0)
                : '0'}
              % journey completion
            </div>
            {userAdditionalData.currentModule ? (
              <div>Current: {userAdditionalData.currentModule}</div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              width: '16%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              sessionStorage.customerId = userId;
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'education',
                assessment_click_type: 'add',
                team_member_id: sessionStorage.teamMemberId,
                customerId: userId,
              });
              return window.open(
                `userPage.html?customerId=${sessionStorage.customerId}&tab=behaviouralProgress`,
                '_blank'
              );
            }}
          >
            <div className={styles.add_btn} style={{ color: '#7F39FB' }}>
              ADD +
            </div>
          </div>
        )} */}
        {// eslint-disable-next-line no-nested-ternary
        userAdditionalData.assessmentCurrentStage ? (
          <div
            style={{ width: '16%', cursor: 'auto', boxShadow: 'none' }}
            className={styles.assessment_container}
          >
            <div>
              There’s an assessment in progress currently in the{' '}
              {userAdditionalData.assessmentCurrentStage.stage === 'cpt'
                ? 'Task'
                : userAdditionalData.assessmentCurrentStage.stage}{' '}
              stage.
            </div>
          </div>
        ) : userAdditionalData.beforeNextSession ||
          userAdditionalData.recurring ||
          doneAssessments ? (
          <div
            style={{ width: '16%' }}
            className={styles.assessment_container}
            onClick={() => {
              sessionStorage.customerId = userId;
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'assessment',
                assessment_click_type: 'edit',
                team_member_id: sessionStorage.teamMemberId,
                search_box_value,
                customerId: userId,
              });
              if (archiveToggle) {
                setDisplayUnarchiveUserModal(true);
                return;
              }
              if (!customerInfo) {
                return;
              }
              onEditScheduleAssessmentCard();
              //   setIsScheduleAssessmentDialogOpener(true)
              //   setTimeout(() => {
              //     sessionStorage.customerId = userId;
              //     return window.open(
              //       `userPage.html?customerId=${sessionStorage.customerId}&tab=assessments`,
              //       '_self'
              //     );
              //   }, 300);
            }}
          >
            {userAdditionalData.usersLastAssessmentTime ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                Last:{' '}
                {timeConverter(userAdditionalData.usersLastAssessmentTime)}
                <EditIcon style={{ width: 14, height: 14 }} />
              </div>
            ) : null}
            {userAdditionalData.usersLastAssessmentTime ? (
              <div>Next: {calcNextAssessment()}</div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div>Next: {calcNextAssessment()}</div>
                <EditIcon style={{ width: 14, height: 14 }} />
              </div>
            )}
            {userAdditionalData.numOfDoneAssessments ? (
              <div>{userAdditionalData.numOfDoneAssessments} completed</div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              width: '16%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              sessionStorage.customerId = userId;
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'assessment',
                assessment_click_type: 'add',
                team_member_id: sessionStorage.teamMemberId,
                search_box_value,
                customerId: userId,
              });
              //   setTimeout(() => {
              //     sessionStorage.customerId = userId;
              //     return window.open(
              //       `userPage.html?customerId=${sessionStorage.customerId}&tab=assessments`,
              //       '_self'
              //     );
              //   }, 300);
              // setIsScheduleAssessmentDialogOpener(true)
              if (archiveToggle) {
                setDisplayUnarchiveUserModal(true);
                return;
              }
              if (!customerInfo) {
                return;
              }
              onAddAssessment();
            }}
          >
            <div className={styles.add_btn} style={{ color: '#0076F5' }}>
              ADD +
            </div>
          </div>
        )}
        {userAdditionalData.usersNumOfPrograms ? (
          <div
            style={
              userAdditionalData.isExpired
                ? { color: '#fa625d', backgroundColor: '#feddcf', width: '16%' }
                : { width: '16%' }
            }
            className={styles.neurofeedback_container}
            onClick={() => {
              sessionStorage.customerId = userId;
              if (
                sessionStorage.packageType ===
                PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
              ) {
                showNotification(
                  'danger',
                  'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                );
                return;
              }
              if (archiveToggle) {
                setDisplayUnarchiveUserModal(true);
                return;
              }
              if (!nfFlag) {
                if (canAccessNf) {
                  new MyEvent('add_neuro_modal').log({
                    from_where: 'clients_page',
                    action: 'open',
                  });
                } else {
                  new MyEvent('contact_sales_modal').log({
                    from_where: 'clients_page',
                    action: 'open',
                  });
                }
                setAddClientReachedLimitModal(true);
                return;
              }
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'neurofeedback',
                neurofeedback_click_type: 'edit',
                team_member_id: sessionStorage.teamMemberId,
                search_box_value,
                customerId: userId,
              });
              onEditPrograms();
            }}
          >
            {userAdditionalData.isExpired ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                The client lost access to programs on{' '}
                {dayJS(userAdditionalData.isExpired).format(
                  DAY_JS_DATE_FORMATS.american
                )}
                <EditIcon style={{ width: 14, height: 14 }} />
              </div>
            ) : null}
            {userAdditionalData.lastSessionDone ? (
              <div
                style={
                  userAdditionalData.isExpired
                    ? { display: 'none' }
                    : {
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }
                }
              >
                Last: {timeConverter(userAdditionalData.lastSessionDone)}
                <EditIcon style={{ width: 14, height: 14 }} />
              </div>
            ) : null}
            {userAdditionalData.lastSessionDone ? (
              <div
                style={
                  userAdditionalData.isExpired ? { display: 'none' } : null
                }
              >
                {`${userAdditionalData.usersTotalSessions -
                  userAdditionalData.usersProgramsSessionsLeft} / ${
                  userAdditionalData.usersTotalSessions
                }`}{' '}
                sessions done
              </div>
            ) : (
              <div
                style={
                  userAdditionalData.isExpired
                    ? { display: 'none' }
                    : {
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }
                }
              >
                <div>
                  {`${userAdditionalData.usersTotalSessions -
                    userAdditionalData.usersProgramsSessionsLeft} / ${
                    userAdditionalData.usersTotalSessions
                  }`}{' '}
                  sessions done
                </div>
                <EditIcon style={{ width: 14, height: 14 }} />
              </div>
            )}
            {userAdditionalData.usersNumOfPrograms > 1 ? (
              <div
                style={
                  userAdditionalData.isExpired ? { display: 'none' } : null
                }
              >
                {`${userAdditionalData.usersNumOfPrograms -
                  userAdditionalData.usersProgramsLeft} / ${
                  userAdditionalData.usersNumOfPrograms
                }`}{' '}
                programs completed
              </div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              width: '16%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              sessionStorage.customerId = userId;
              if (
                sessionStorage.packageType ===
                PACKAGES_TYPES.PRE_ACTIVATION_PACKAGE
              ) {
                showNotification(
                  'danger',
                  'You need to activate your account to use this feature. Please go to the Account and Billing page to activate now.'
                );
                return;
              }
              if (archiveToggle) {
                setDisplayUnarchiveUserModal(true);
                return;
              }
              if (!nfFlag) {
                if (canAccessNf) {
                  new MyEvent('add_neuro_modal').log({
                    from_where: 'clients_page',
                    action: 'open',
                  });
                } else {
                  new MyEvent('contact_sales_modal').log({
                    from_where: 'clients_page',
                    action: 'open',
                  });
                }
                setAddClientReachedLimitModal(true);
                return;
              }
              clientPageEventLogger.log('item_clicked', {
                button_clicked: 'neurofeedback',
                neurofeedback_click_type: 'add',
                team_member_id: sessionStorage.teamMemberId,
                search_box_value,
                customerId: userId,
              });
              onEditPrograms();
            }}
          >
            <div className={styles.add_btn} style={{ color: '#4BE0D0' }}>
              ADD +
            </div>
          </div>
        )}
        {// eslint-disable-next-line no-extra-boolean-cast
        !!scheduleAssessmentDialogOpener
          ? renderScheduleAssessmentDialog()
          : null}
        {isAddClientModalOpen && renderAddClientModal()}
        <Dialog
          open={displayUnarchiveUserModal}
          onClose={() => {
            setDisplayUnarchiveUserModal(false);
          }}
        >
          <DialogTitle style={{ alignSelf: 'center' }}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              Unarchive this client to continue
            </div>
          </DialogTitle>
          <DialogContent style={{ alignSelf: 'center', minWidth: 400 }}>
            <div style={{ fontSize: 13, fontWeight: 300, textAlign: 'center' }}>
              Unarchive this client to see the their page, deploy{' '}
              {nfFlag ? 'programs,' : ''} assessments, give them access to the
              full Myndlift app and more.
            </div>
          </DialogContent>
          <ModalFooter
            primaryBtnContent="unarchive now"
            secondaryBtnContent="cancel"
            onPrimaryBtnClick={onUnarchiveUserClicked}
            onSecondaryBtnClick={() => {
              setDisplayUnarchiveUserModal(false);
            }}
          />
        </Dialog>
      </div>
    );
  }
  return null;
};

CustomerCardBase.propTypes = exact({
  userAdditionalData: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  archiveCheckboxes: PropTypes.object.isRequired,
  setArchiveCheckboxes: PropTypes.func.isRequired,
  onEditPrograms: PropTypes.func,
  search_box_value: PropTypes.string,
  archiveToggle: PropTypes.bool,
  setDisplayRichedLimitModal: PropTypes.func,
  reachedClientsLimit: PropTypes.bool,
  setAddClientReachedLimitModal: PropTypes.func,
  nfFlag: PropTypes.bool,
  clinicNonfPlan: PropTypes.object,
  canAccessNf: PropTypes.bool,
  assessmentAutoDeployableConfigs: PropTypes.object,
  questionnairesAutoDeployableConfigs: PropTypes.object,
  isSample: PropTypes.bool,
  isNameMasking: PropTypes.bool,
  maskText: PropTypes.func,
});

export const CustomerCard = React.memo(CustomerCardBase);
CustomerCard.displayName = 'CustomerCard';
