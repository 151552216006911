import React, { useEffect, useState } from 'react';
import exact from 'prop-types-exact';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import { Range } from 'rc-slider';
import styles from './SettingsSection.scss';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../Core/hooks/useTranslation';
// import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';
import { CheckboxesSelect } from '../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { SettingsInput } from './SettingsInput';
import { CustomAlert } from '../../Core/Components/CustomAlert/CustomAlert';

// const lagoDashboardContentLogger = new MyEventWrapper('lago_dashboard_content');

const handleStyle = {
  background: '#50a0ff',
  borderColor: '#50a0ff',
  width: 22,
  height: 22,
  marginTop: '-9px',
};

const getAppFeatureClinicConfigPath = ({ clinicId, appFeatureKey }) =>
  `clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/${appFeatureKey}`;

const defaultMinimumBrightnessOptions = [
  {
    label: 'Darker, but still visible (15%)',
    value: 15,
  },
  {
    label: 'Total darkness (0%)',
    value: 0,
  },
  {
    label: 'A little dark (30%)',
    value: 30,
  },
];

const SettingsSectionBase = () => {
  const t = useTranslation();
  const clinicId = sessionStorage.userId;

  const [selectedCatagories, setSelectedCatagories] = useState([]);

  const [isClinicAllowedToUseYoutubeSearch] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/youtubeSearch/${sessionStorage.userId}/isAvailable`,
  });

  const areClientsAllowedToAdjustYoutubePath = getAppFeatureClinicConfigPath({
    clinicId,
    appFeatureKey: 'areClientsAllowedToAdjustYoutubeTrainingParameters',
  });
  const areClientsAllowedToAdjustYoutubeDefaultValue = true;
  const [areClientsAllowedToAdjustYoutube] = useFirebaseDB({
    path: areClientsAllowedToAdjustYoutubePath,
    defaultValue: areClientsAllowedToAdjustYoutubeDefaultValue,
  });
  const [isLagoHiddenDb] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${clinicId}/isHidden`,
  });
  const isLagoHidden = isLagoHiddenDb === false ? isLagoHiddenDb : true;

  const [defaultCategories] = useFirebaseDB({
    path: 'lago/educational/defaults/categories',
  });

  const [defaultJourneys] = useFirebaseDB({
    path: 'lago/educational/defaults/journeys',
  });

  const [
    enableDefaultContentJourneys,
    setEnableDefaultContentJourneys,
  ] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${clinicId}/enableDefaultContentJourneys`,
  });

  const [defaultJourneyId, setDefaultJourneyId] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${clinicId}/defaultJourneyId`,
  });

  const [enableDefaultResources, setEnableDefaultResources] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${clinicId}/enableDefaultResources`,
  });

  const [streamingVolumeMin, setStreamingVolumeMin] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/streamingVolumeMin`,
  });

  const [streamingVolumeMax, setStreamingVolumeMax] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/streamingVolumeMax`,
  });

  const [enableStreaming] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/enableStreaming`,
  });

  const [minimumDifference, setMinimumDifference] = useFirebaseDB({
    path: `clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/minimumDifference`,
    defaultValue: 30,
  });

  const [
    defaultResourceCategories,
    setDefaultResourceCategories,
  ] = useFirebaseDB({
    path: `clinicConfig/lagoEnabled/${clinicId}/defaultResourceCategories`,
  });

  const [nfFlagNew] = useFirebaseDB({
    path: `billing/customPlans/nonfPlan/${clinicId}/nfAddOnPlanId`,
  });

  const [nfFlagOld] = useFirebaseDB({
    path: `billing/currentPackages/${sessionStorage.userId}/packageType`,
  });

  const nfFlag = nfFlagOld !== 'nonfPackage' || nfFlagNew !== null;

  const filteredDefaultCategories = defaultCategories
    ? Object.keys(defaultCategories).filter(
        catgoryId => !defaultCategories[catgoryId].isHidden
      )
    : [];

  useEffect(() => {
    if (defaultResourceCategories) {
      const selecetdCategoriesToAssign = [];
      filteredDefaultCategories.forEach(categoryId => {
        if (
          !defaultResourceCategories[categoryId] ||
          defaultResourceCategories[categoryId].isEnabled !== false
        ) {
          selecetdCategoriesToAssign.push(categoryId);
        }
      });
      setSelectedCatagories(selecetdCategoriesToAssign);
    } else {
      setSelectedCatagories(defaultCategories ? filteredDefaultCategories : []);
    }
  }, [defaultResourceCategories, defaultCategories]);

  const nonfDescription = (
    <div>
      Allow clients to request to start a neurofeedback program. You’ll receive
      an email when a request is made.
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://intercom.help/dashboard-myndlift/en/articles/5841009-introducing-in-app-neurofeedback-promotion"
      >
        {' '}
        Learn more here.
      </a>
    </div>
  );

  const journeysToDisplay =
    defaultJourneys !== null
      ? Object.keys(defaultJourneys)
          .filter(journeyId => !defaultJourneys[journeyId].hide)
          .map(journeyId => {
            return {
              value: journeyId,
              text:
                defaultJourneys[journeyId].title &&
                defaultJourneys[journeyId].title.en,
              groupId: defaultJourneys[journeyId].groupId,
              version: defaultJourneys[journeyId].version,
            };
          })
      : [];

  const groupIdsMap = {};
  journeysToDisplay.forEach(journeyObject => {
    if (journeyObject.groupId) {
      if (!groupIdsMap[journeyObject.groupId]) {
        groupIdsMap[journeyObject.groupId] = [
          { value: journeyObject.value, version: journeyObject.version },
        ];
      } else {
        groupIdsMap[journeyObject.groupId].push({
          value: journeyObject.value,
          version: journeyObject.version,
        });
      }
    }
  });

  const journeysWithGroupIdToDisplay = Object.keys(groupIdsMap).map(groupId => {
    let latestVersion = 0;
    let journeyIdToReturn = '';
    groupIdsMap[groupId].forEach(versionObject => {
      if (versionObject.version > latestVersion) {
        journeyIdToReturn = versionObject.value;
        latestVersion = versionObject.version;
      }
    });
    return journeyIdToReturn;
  });

  const filteredJourneysToDisplay = journeysToDisplay.filter(
    journeyObject =>
      !journeyObject.groupId ||
      journeysWithGroupIdToDisplay.find(
        journeyId => journeyId === journeyObject.value
      )
  );

  const categoriesToDisplay =
    defaultCategories !== null
      ? filteredDefaultCategories.map(categoryId => {
          return {
            value: categoryId,
            label:
              defaultCategories[categoryId].name &&
              defaultCategories[categoryId].name.en,
          };
        })
      : [];

  return (
    <div className={styles.settings_section}>
      {!nfFlag ? null : (
        <div>
          <div className={styles.settings_title_container}>
            <div className={styles.settings_title_text}>Training</div>
          </div>
          <SettingsInput
            path={getAppFeatureClinicConfigPath({
              clinicId,
              appFeatureKey: 'showFrequenciesDotsInGame',
            })}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label={t('enable-moving-dots')}
            description={
              <>
                {t('enable-moving-dots-description')}
                <a
                  href="https://intercom.help/dashboard-myndlift/en/articles/3527642-introducing-the-new-guided-training-features"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Learn more here
                </a>
                .
              </>
            }
            userPropertyName="moving_dots_toggled_enabled"
            shouldShowHr={false}
          />
          <SettingsInput
            path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/showInGameTips`}
            defaultValue
            type="boolean"
            label={t('show-in-game-messages')}
            description={<>{t('show-in-game-messages-description')}</>}
            userPropertyName="show_in_game_messages_toggled_enabled"
          />
          <SettingsInput
            path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/nfAppPromo`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={false}
            type="boolean"
            label="Enable Program Requests"
            description={nonfDescription}
            // note="Available on iOS devices only (2.4.9 and later). Will be available on Android soon."
            // disabled={}
            eventName="nonf_promo_toggled"
            userPropertyName="nonf_promo_toggled_enabled"
          />
          <SettingsInput
            path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/isRapidSessionEnabled`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={false}
            type="boolean"
            label="Enable Rapid Session"
            description={
              <>
                Allow clients to run shorter sessions than originally deployed.
                This helps your clients maintain their streak even when
                constrained by time.{' '}
                <a
                  href="https://intercom.help/dashboard-myndlift/en/articles/9207786-rapid-training-session"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more here.
                </a>
              </>
            }
            // note="Available on iOS and Android devices (Android 11 and above)."
            // disabled={}
            eventName="rapid_session_toggled"
            userPropertyName="rapid_session_toggled_enabled"
            isNew
          />
        </div>
      )}
      <div>
        <div className={styles.divider_settings} />
        <div className={styles.settings_title_container}>
          <div className={styles.settings_title_text}>Assessments</div>
        </div>
        <SettingsInput
          path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/skipAssessmentEnabled`}
          // eslint-disable-next-line react/jsx-boolean-value
          defaultValue={true}
          type="boolean"
          label="Enable Bypassing Pending Assessment"
          description={
            <>
              Allow clients to train even when they have a pending assessment.
              If disabled, clients will need to complete the assessment before
              resuming their training.{' '}
              <a
                href="https://intercom.help/dashboard-myndlift/en/articles/9286027-training-when-an-assessment-is-pending"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
          // note="Available on iOS and Android devices (Android 11 and above)."
          // disabled={}
          eventName="train_with_assessment_toggled"
          userPropertyName="train_with_assessment_toggled_enabled"
          isNew
          shouldShowHr={false}
        />
        {!nfFlag ? null : (
          <SettingsInput
            path={`clinicConfig/hideAppAssessmentsResults/${clinicId}`}
            defaultValue={false}
            type="boolean"
            label={t('hide-app-assessments-results')}
            description={t('hide-app-assessments-results-description')}
            userPropertyName="hide_app_assessments_results_toggled_enabled"
          />
        )}
      </div>
      {!nfFlag ? null : (
        <div>
          <div className={styles.divider_settings} />
          <div className={styles.settings_title_container}>
            <div className={styles.settings_title_text}>Insights</div>
          </div>
          <SettingsInput
            path={getAppFeatureClinicConfigPath({
              clinicId,
              appFeatureKey: 'isInsightsEnabled',
            })}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label={t('enable-insights')}
            description={t('enable-insights-description')}
            userPropertyName="insights_toggled_enabled"
            shouldShowHr={false}
          />
          <SettingsInput
            // path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/newAlphaPeakEnabled`}
            path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/newAlphaPeakEnabledWriteOnly`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label="Enable Alpha Peak"
            description={
              <>
                The Alpha Peak graph appears in the app and shows the user how
                their Alpha Peak is changing in comparison to their baseline.
                <br />
                It takes 10 eyes-open, clean sessions to start showing in the
                app.
                <br />
                {/* {' '} */}
                <a
                  href="https://www.myndlift.com/post/announcing-alpha-peak-your-brain-performance-tracker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more here.
                </a>
              </>
            }
            eventName="alpha_peak_toggled"
            userPropertyName="alpha_peak_toggle_enabled"
            isNew
          />
        </div>
      )}
      {!nfFlag ? null : (
        <div>
          <div className={styles.divider_settings} />
          <div className={styles.settings_title_container}>
            <div className={styles.settings_title_text}>Streaming</div>
          </div>
          <SettingsInput
            path={`clinicConfig/youtubeSearchEnabled/${clinicId}/isEnabled`}
            defaultValue
            type="boolean"
            label={t('is-youtube-search-enabled')}
            description={<>{t('is-youtube-search-enabled-description')}</>}
            shouldShowHr={false}
            availability={isClinicAllowedToUseYoutubeSearch}
            userPropertyName="youtube_search_toggled_enabled"
          />
          <SettingsInput
            path={areClientsAllowedToAdjustYoutubePath}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={areClientsAllowedToAdjustYoutubeDefaultValue}
            type="boolean"
            label={t('allow-clients-to-adjust-youtube-training')}
            description={t(
              'allow-clients-to-adjust-youtube-training-description'
            )}
            userPropertyName="allow_clients_to_adjust_youtube_training_toggled_enabled"
          />
          <SettingsInput
            path={getAppFeatureClinicConfigPath({
              clinicId,
              appFeatureKey: 'defaultMinimumBrightness',
            })}
            defaultValue={15}
            type="select"
            valueType="integer"
            options={defaultMinimumBrightnessOptions}
            label={t('youtube-video-minimum-brightness')}
            description={t('youtube-video-minimum-brightness-description')}
            disabled={areClientsAllowedToAdjustYoutube}
            tooltip={
              areClientsAllowedToAdjustYoutube &&
              t('youtube-video-minimum-brightness-disabled-reason')
            }
            userPropertyName="youtube_video_minimum_brightness_toggled_enabled"
          />
          <SettingsInput
            path={getAppFeatureClinicConfigPath({
              clinicId,
              appFeatureKey: 'isYoutubeAudioFeedbackEnabled',
            })}
            defaultValue={false}
            type="boolean"
            label={t('youtube-video-enable-audio-feedback')}
            description={t('youtube-video-enable-audio-feedback-description')}
            shouldShowHr={false}
            disabled={areClientsAllowedToAdjustYoutube}
            tooltip={
              areClientsAllowedToAdjustYoutube &&
              t('youtube-video-enable-audio-feedback-disabled-reason')
            }
            userPropertyName="youtube_video_enable_audio_feedback_toggled_enabled"
          />
          <SettingsInput
            path={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/enableStreaming`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label="Enable Streaming"
            description="When enabled, users will have access to the Streaming activity, which allows them to train while watching content from any app on their device. You can disable Streaming for specific users from their user page."
            note="Available on iOS and Android devices (Android 11 and above)."
            // disabled={}
            // eventName="nonf_promo_toggled"
            // isNew
            additionalPath={`clinicConfig/appFeaturesConfig/${clinicId}/clinicFeatureDefaults/streaming/isInternalStreamingEnabled`}
            userPropertyName="streaming_toggled_enabled"
          />
          <TooltipWrapper
            title={
              <TooltipTitle>
                You must enable Streaming to set default volumes
              </TooltipTitle>
            }
            // placement="right"
            classes={{
              tooltip: styles.is_client_real_email_tooltip,
            }}
            disabled={enableStreaming !== false}
          >
            <div className={styles.switch_setting_unit}>
              <div>
                <p>Streaming: Default Maximum and Minimum Volumes</p>
                {/* <span
                  className={styles.card_new_label}
                  style={{ top: 3, left: -10 }}
                >
                  NEW
                </span> */}
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                Set the default maximum and minimum volumes for the Streaming
                audio, along with the minimum difference between both volume
                levels. Users can change the volume levels with the ability to
                reset to the default, while the minimum difference will remain
                enforced.
              </p>
              <div className={styles.volume_container}>
                <div className={styles.volume_range_container}>
                  <div style={{ marginRight: 20 }}>
                    <div>Min</div>
                    <div>{streamingVolumeMin || 0}%</div>
                  </div>
                  <Range
                    value={[streamingVolumeMin || 0, streamingVolumeMax || 100]}
                    onChange={values => {
                      if (values[0] + minimumDifference > values[1]) {
                        return;
                      }
                      setStreamingVolumeMin(values[0]);
                      setStreamingVolumeMax(values[1]);
                    }}
                    handleStyle={[handleStyle, handleStyle]}
                    trackStyle={[{ background: '#50a0ff', width: '80%' }]}
                    disabled={enableStreaming === false}
                    className={styles.range_root}
                  />
                  <div style={{ marginLeft: 20 }}>
                    <div>Max</div>
                    <div>{streamingVolumeMax || 100}%</div>
                  </div>
                </div>
                <Select
                  onChange={e => {
                    if (
                      streamingVolumeMax - streamingVolumeMin <
                      e.target.value
                    ) {
                      const amountToChange =
                        e.target.value -
                        (streamingVolumeMax - streamingVolumeMin);
                      if (streamingVolumeMax + amountToChange <= 100) {
                        setStreamingVolumeMax(
                          streamingVolumeMax + amountToChange
                        );
                      } else {
                        setStreamingVolumeMin(
                          streamingVolumeMin - amountToChange
                        );
                      }
                    }
                    setMinimumDifference(e.target.value);
                  }}
                  value={minimumDifference}
                  style={
                    window.innerWidth < 960
                      ? { fontSize: 13, maxWidth: 250 }
                      : { fontSize: 13, marginLeft: 20 }
                  }
                >
                  {[30, 40, 50].map(selectItem => (
                    <MenuItem value={selectItem} style={{ fontSize: 13 }}>
                      Minimum difference: {selectItem}%
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </TooltipWrapper>
        </div>
      )}
      <div>
        <div className={styles.divider_settings} />
        <div className={styles.settings_title_container}>
          <div className={styles.settings_title_text}>Content</div>
        </div>
        <div className={styles.switch_setting_unit} style={{ marginTop: 20 }}>
          <div>
            <p>Enable Digital Journeys by Default</p>
          </div>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            An in-app digital care tool that provides your clients with
            bite-sized lessons to learn about a specific topic and how to form
            habits. You can choose a digital journey to enable for all clients
            by default.
          </p>
          <div>
            <Select
              onChange={e => {
                setDefaultJourneyId(e.target.value);
              }}
              value={defaultJourneyId}
              style={
                window.innerWidth < 960
                  ? { fontSize: 13, maxWidth: 250 }
                  : { fontSize: 13 }
              }
              disabled={!enableDefaultContentJourneys}
            >
              {filteredJourneysToDisplay.map(selectItem => (
                <MenuItem value={selectItem.value} style={{ fontSize: 13 }}>
                  {selectItem.text}
                </MenuItem>
              ))}
            </Select>
            <Switch
              checked={!!enableDefaultContentJourneys}
              onChange={e => {
                setEnableDefaultContentJourneys(e.target.checked);
              }}
              color="primary"
            />
          </div>
          <div style={{ color: 'rgb(191, 144, 0)', marginBottom: 20 }}>
            <CustomAlert severity="warning">
              {'Available on iOS only.'}
            </CustomAlert>
          </div>
        </div>
        <hr />
        <div className={styles.switch_setting_unit}>
          <div>
            <p>Enable Exercises & Resources by Default</p>
          </div>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            An in-app digital care tool that helps keep your clients engaged
            through interactive educational material, exercises, meditations,
            and journals. You can choose specific categories to enable for all
            clients by default.
          </p>
          <div>
            <div style={{ position: 'relative', top: -10 }}>
              <CheckboxesSelect
                onChange={e => {
                  setSelectedCatagories(e.target.value);
                  const copy = {};
                  filteredDefaultCategories.forEach(categoryId => {
                    copy[categoryId] = {
                      isEnabled: !!e.target.value.find(
                        idToFind => idToFind === categoryId
                      ),
                    };
                  });
                  setDefaultResourceCategories(copy);
                }}
                allOptions={categoriesToDisplay}
                selectedOptions={selectedCatagories}
                // style={{ width: '67%', fontSize: 14, top: 6 }}
                disabled={!enableDefaultResources}
                inputType="outlinedInputs"
                displayEmpty
                renderValue={() => {
                  if (!enableDefaultResources) {
                    return 'All categories are disabled';
                  }
                  if (
                    selectedCatagories.length ===
                    filteredDefaultCategories.length
                  ) {
                    return 'All categories are enabled';
                  }
                  return `${selectedCatagories.length} categories are enabled`;
                }}
              />
            </div>
            <Switch
              checked={!!enableDefaultResources}
              onChange={e => {
                setEnableDefaultResources(e.target.checked);
              }}
              color="primary"
            />
          </div>
        </div>
        {!isLagoHidden ? (
          <SettingsInput
            path={`clinicConfig/lagoEnabled/${clinicId}/enableDefaultJournalSharing`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label="Enable Entry Sharing by Default"
            description="When enabled, users will be able to share their journal/exercise/check-in entries with you by default."
            // note="Available on iOS devices only (2.3.9 and later). Will be available on Android soon."
            // disabled={!contentJourneyEnabled && contentJourneyEnabled !== null}
            eventName="Lago_dashboard_content_preferences_client_entry_disable_toggle-clicked"
            userPropertyName="Lago_dashboard_content_preferences_client_entry_disable_toggle-clicked_toggled_enabled"
          />
        ) : null}
        {!isLagoHidden ? (
          <SettingsInput
            path={`clinicConfig/lagoEnabled/${clinicId}/journalSharingSettings/enableDefaultTextBasedFeedbackRequest`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label="Enable Feedback Requests on Entries by Default"
            description="When enabled, users will be able to request that you provide text-based feedback on entries by default. When feedback is requested, they will be notified that you will reply at your own discretion."
            // note="Available on iOS devices only (2.4.9 and later). Will be available on Android soon."
            // disabled={!contentJourneyEnabled && contentJourneyEnabled !== null}
            eventName="lago_dashboard_preferences_allow_feedback_requests_toggled"
            userPropertyName="lago_dashboard_preferences_allow_feedback_requests_toggled_enabled"
          />
        ) : null}
        {!isLagoHidden ? (
          <SettingsInput
            path={`clinicConfig/lagoEnabled/${clinicId}/journalSharingSettings/enableDefaultJournalReply`}
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            type="boolean"
            label="Enable Client Replies on Feedback by Default"
            description="When enabled, users will be able to reply to the feedback you provide on their entries by default."
            // note="Available on iOS devices only (2.4.9 and later). Will be available on Android soon."
            // disabled={!contentJourneyEnabled && contentJourneyEnabled !== null}
            eventName="lago_dashboard_preferences_allow_feedback_replies_toggled"
            userPropertyName="lago_dashboard_preferences_allow_feedback_replies_toggled_enabled"
          />
        ) : null}
      </div>
    </div>
  );
};

SettingsSectionBase.propTypes = exact({});

export const SettingsSection = React.memo(SettingsSectionBase);
SettingsSection.displayName = 'SettingsSection';
