import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cloneDeep from 'lodash/cloneDeep';
import { Paper, TextField, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import EMailIcon from '@material-ui/icons/Email';
// import CloseIcon from '@material-ui/icons/Close';
import { getOnlyNumbersFromString } from '../../../utils/utils';
import { withEntryComponent } from '../../../Core/hocs/withEntryComponent/withEntryComponent';
import styles from './ReportsTab.scss';
import { ReportsTable } from '../../ReportsTable/ReportsTable';
import { NotesTable } from '../../NotesTable/NotesTable';
import { EmptyReportsReportTab } from './EmptyReportsReportTab';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { DeleteReportDialog } from './DeleteReportDialog';
import { DeleteNoteDialog } from './DeleteNoteDialog';
import { CancelNoteDialog } from './CancelNoteDialog';
import { EmptyNotes } from './EmptyNotes';
import { AddNoteDialog } from './AddNoteDialog';
import { ShareReportDialog } from '../../SessionToSessionReportGenerator/ShareReportDialog/ShareReportDialog';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { FeatureNotAllowedDialog } from '../../FeatureNotAllowedDialog/FeatureNotAllowedDialog';
import { BlurOverlayIfContinuityCustomer } from '../BlurOverlayIfContinuityCustomer/BlurOverlayIfContinuityCustomer';

import enabledNotifByEmailPNG from '../../../../assets/enabled-noti-by-email-with-border.png';
import disabledEmailNotifPNG from '../../../../assets/disabled-email-notif-with-border.png';
// import enabledEmailNotifActivityPNG from '../../../../assets/email-me-on-activity-enabled.png';
// import disabledEmailNotifActivityPNG from '../../../../assets/email-me-on-activity-disabled.png';
import emptyActivityPNG from '../../../../assets/empty-activity.png';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
// import FlagIcon from '../../CustomersList/flag.png';
// import InfoIcon from '../../CustomersList/info.png';
// import BlueMailIcon from '../../CustomersList/mail.png';
// import RedMailIcon from '../../CustomersList/red-mail-icon.png';
// import YellowMailIcon from '../../CustomersList/yellow-mail-icon.png';

const stylesFn = () => ({
  button: {
    margin: 0,
    color: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '14px',
  },
});

const userPageLogger = new MyEventWrapper('user_page');
const AutomationsLogger = new MyEventWrapper('human_centric');

const ReportsTabBase = ({
  classes,
  patientId,
  headsetDetails,
  onHeadsetDetailsUpdated,
  notificationsAlerts,
  notificationsData,
  editNotificationsAlerts,
  // deleteNotificationsAlerts,
  // clearAllNotifications,
  customerNotes,
  setCustomerNotes,
  addCustomerNote,
  removeCustomerNote,
  trainingSessions,
  patientMesseges,
  onNotifyByEmailChange,
  // deployedProgram,
  onNotifyCustomerOnMessegesChange,
  notifyAutomationsMessagesByEmail,
  notifyByEmail,
  userAppVersion,
  humanCentricAppVersion,
}) => {
  const t = useTranslation();

  const [reports, setReports] = useState(null);
  const [reportToBeDeleted, setReportToBeDeleted] = useState(null);
  const [isDeleteReportDialogOpen, setIsDeleteReportDialogOpen] = useState(
    false
  );
  const [
    isFeatureNotAllowedDialogOpen,
    setIsFeatureNotAllowedDialogOpen,
  ] = useState(false);

  const [isShareReportDialogOpen, setIsShareReportDialogOpen] = useState(false);

  const [reportToBeShared, setReportToBeShared] = useState(null);

  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const [noteContent, setNoteContent] = useState(EditorState.createEmpty());

  const [editNoteId, setEditNoteId] = useState('');

  const [isDeleteNoteDialogOpen, setIsDeleteNoteDialogOpen] = useState('');

  const [isCancelNoteDialogOpen, setIsCancelNoteDialogOpen] = useState(false);

  const [clinicianMessageInput, setClinicianMessageInput] = useState('');

  const onFeatureNotAllowedDialogDismiss = useCallback(() => {
    userPageLogger.log('on_report_feature_not_allowed_dialog_dismiss');
    setIsFeatureNotAllowedDialogOpen(false);
  }, []);

  const scrollToBottom = () => {
    const objDiv = document.getElementById('scroll_div');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  const [isClinicAllowedToUseThisFeature] = useFirebaseDB({
    path: `clinicConfig/additionalFeatures/progressReportFeature/${sessionStorage.userId}/isAvailable`,
  });

  const [customerInfo] = useFirebaseDB({
    path: `userInfo/${patientId}`,
    onceListener: true,
  });

  const [humanCentricAutomatedTriggers] = useFirebaseDB({
    path: `experimentsVariants/users/${patientId}/humanCentricAutomatedTriggers`,
    onceListener: true,
  });

  const [mtrAutomatedTriggersDisabledVariant] = useFirebaseDB({
    path: 'humanCentric/configs/experiment/mtrAutomatedTriggersDisabledVariant',
    onceListener: true,
  });

  const [doneAssessments] = useFirebaseDB({
    path: `assessments/results/${patientId}`,
  });

  const numOfDoneAssessment = doneAssessments
    ? Object.keys(doneAssessments).filter(assessment => !assessment.isDeleted)
        .length
    : 0;
  const numOfSessions = trainingSessions
    ? Object.keys(trainingSessions).length
    : 0;

  useEffect(() => {
    const reportsDatabaseRef = database.ref(`reports/${patientId}`);
    const onReportsValue = snapshot => {
      setReports(snapshot.val() || {});
    };
    reportsDatabaseRef.on('value', onReportsValue);

    return () => reportsDatabaseRef.off('value', onReportsValue);
  }, [patientId]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [customerInfo, reports, patientMesseges]);

  function timeConverter(unixTimestamp) {
    const a = new Date(unixTimestamp);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    if (hour < 10) {
      // eslint-disable-next-line prefer-template
      hour = '0' + hour;
    }
    if (min < 10) {
      // eslint-disable-next-line prefer-template
      min = '0' + min;
    }
    if (sec < 10) {
      // eslint-disable-next-line prefer-template
      sec = '0' + sec;
    }
    const time =
      // eslint-disable-next-line prefer-template
      date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  const onReportView = useCallback(
    report => {
      if (isClinicAllowedToUseThisFeature || customerInfo.isDemo) {
        window.open(
          `session-to-session-report-viewer.html?reportId=${report.id}&customerId=${patientId}`
        );
      } else {
        setIsFeatureNotAllowedDialogOpen(true);
      }
    },
    [patientId, isClinicAllowedToUseThisFeature, customerInfo]
  );

  const onReportDelete = useCallback(deletedReport => {
    userPageLogger.log('on_trying_to_delete_report', {
      reportId: deletedReport.id,
    });
    setIsDeleteReportDialogOpen(true);
    setReportToBeDeleted(deletedReport);
  }, []);

  const onReportShare = useCallback(sharedReport => {
    setIsShareReportDialogOpen(true);
    const sharedReportWithLink = {
      reportLink: `${window.location.origin}/session-to-session-report-viewer.html?reportId=${sharedReport.id}&customerId=${patientId}&clinicId=${sessionStorage.userId}&viewer=customer `,
      ...sharedReport,
    };
    setReportToBeShared(sharedReportWithLink);
  }, []);

  const onToggleShareInAppReport = useCallback((isShareInApp, reportId) => {
    database
      .ref(`reports/${patientId}/${reportId}/shareInAppWithClient`)
      .set(isShareInApp);
  }, []);

  const onCancelDeleteReportDialog = useCallback(() => {
    userPageLogger.log('on_cancel_delete_report');
    setIsDeleteReportDialogOpen(false);
    setReportToBeDeleted(null);
  }, []);

  const onReportDeleteConfirm = useCallback(() => {
    const clonedDeletedReport = cloneDeep(reportToBeDeleted);
    clonedDeletedReport.isDeleted = true;
    const reportDatabaseRef = database.ref(
      `reports/${patientId}/${reportToBeDeleted.id}`
    );
    reportDatabaseRef.set(clonedDeletedReport);
    reportDatabaseRef.off();

    // here we should handle network errors
    setIsDeleteReportDialogOpen(false);
    userPageLogger.log('on_delete_report', {
      reportId: reportToBeDeleted.id,
    });
  }, [patientId, reportToBeDeleted]);

  const onReportGenerate = useCallback(() => {
    // if (!isClinicAllowedToUseThisFeature) {
    //   setIsFeatureNotAllowedDialogOpen(true);
    //   return;
    // }
    // eslint-disable-next-line no-undef
    if (shouldNotShowBlockedMessage()) {
      window.open(
        `session-to-session-report-generator.html?customerId=${patientId}`,
        '_blank'
      );
    }
  }, [patientId, isClinicAllowedToUseThisFeature]);

  const notDeletedReports = reports
    ? Object.entries(reports)
        // eslint-disable-next-line no-unused-vars
        .filter(([reportId, report]) => !report.isDeleted)
        .reverse()
        .map(([reportId, report]) => ({
          id: reportId,
          ...report,
        }))
    : null;

  // const removeActionNeededClicked = notificationId => {
  //   deleteNotificationsAlerts(notificationId);
  // };

  const onAddNoteClicked = () => {
    setIsNoteDialogOpen(true);
    userPageLogger.log('clinet_notes_add_btn_clicked', {
      customerId: patientId,
    });
  };

  const onSaveNote = text => {
    const getNoteWriter = () =>
      sessionStorage.teamMemberId
        ? `${JSON.parse(sessionStorage.intercomInfo).firstName} ${
            JSON.parse(sessionStorage.intercomInfo).lastName
          }`
        : `${JSON.parse(sessionStorage.userInfo).firstName} ${
            JSON.parse(sessionStorage.userInfo).lastName
          }`;

    if (editNoteId) {
      const copy = { ...customerNotes };
      copy[editNoteId].text = text;
      copy[editNoteId].by = getNoteWriter();
      setCustomerNotes(copy);
      userPageLogger.log('clinet_notes_saved', {
        customerId: patientId,
        action: 'edit',
      });
    } else {
      addCustomerNote({
        isPinned: false,
        timestamp: Date.now(),
        text,
        by: getNoteWriter(),
      });
      userPageLogger.log('clinet_notes_saved', {
        customerId: patientId,
        action: 'add',
      });
    }
  };

  const onEditNote = noteId => {
    const contentBlock = htmlToDraft(customerNotes[noteId].text);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setNoteContent(EditorState.createWithContent(contentState));
    setEditNoteId(noteId);
    setIsNoteDialogOpen(true);
  };

  const onPinNote = noteId => {
    const copy = { ...customerNotes };
    copy[noteId].isPinned = !copy[noteId].isPinned;
    copy[noteId].pinDate = Date.now();
    setCustomerNotes(copy);
  };

  const onDeleteNoteClicked = noteId => {
    setIsDeleteNoteDialogOpen(noteId);
  };

  const onDeleteNote = () => {
    removeCustomerNote(isDeleteNoteDialogOpen);
    setIsDeleteNoteDialogOpen('');
    userPageLogger.log('clinet_notes_saved', {
      customerId: patientId,
      action: 'delete',
    });
  };

  const onSendMessage = () => {
    if (!clinicianMessageInput) {
      return;
    }
    try {
      database
        .ref(
          `humanCentric/conversations/${sessionStorage.userId}/patients/${patientId}/channels/main/messages`
        )
        .push({
          sender: 'clinic',
          timestamp: Date.now(),
          message: clinicianMessageInput,
          seen: false,
        });
      AutomationsLogger.log('message_sent', {
        by: 'dashboard',
        user_Id: patientId,
      });
    } catch (e) {
      showNotification(`Something went wrong, ${e}`);
    }
    setClinicianMessageInput('');
    // scrollToBottom();
  };

  // const handleKeyDown = event => {
  //   if (event.key === 'Enter') {
  //     setTimeout(() => {
  //       onSendMessage();
  //     }, 100);
  //   }
  // };

  const renderTabContent = () =>
    notDeletedReports.length === 0 ? (
      <EmptyReportsReportTab onReportGenerate={onReportGenerate} />
    ) : (
      <Paper className={styles.root_paper}>
        <div>
          <div className={styles.header_container}>
            <div>
              <p className={styles.header_font}>{t('saved_client_reports')}</p>
              <p>{t('create_a_report_short_description')}</p>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={onReportGenerate}
                color="primary"
                className={classes.button}
              >
                {t('generate_new_report')}
              </Button>
            </div>
          </div>
          <div>
            <ReportsTable
              reports={notDeletedReports}
              onReportView={onReportView}
              onReportDelete={onReportDelete}
              isDeleteIconShown
              isShareReportIconShown
              onToggleShareInAppReport={onToggleShareInAppReport}
              onReportShare={onReportShare}
            />
          </div>
        </div>
      </Paper>
    );

  const renderNotes = () => {
    if (!customerNotes) {
      return <EmptyNotes onAddNoteClicked={onAddNoteClicked} />;
    }
    return (
      <Paper className={styles.root_paper} style={{ marginBottom: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className={styles.header_font}>Notes</p>
          <Button
            onClick={() => {
              onAddNoteClicked();
            }}
            color="primary"
            variant="contained"
            style={{ height: 35 }}
          >
            <span className={styles.btn}>Add a note</span>
          </Button>
        </div>
        <NotesTable
          customerNotes={customerNotes}
          onEditNote={onEditNote}
          onPinNote={onPinNote}
          onDeleteNoteClicked={onDeleteNoteClicked}
          userPageLogger={userPageLogger}
          patientId={patientId}
        />
      </Paper>
    );
  };

  // const renderActionContent = () => {
  //   const sortedAlerts =
  //     notificationsData && notificationsAlerts
  //       ? Object.keys(notificationsAlerts).sort((a, b) => {
  //           if (!notificationsData[a]) {
  //             return 1;
  //           }
  //           if (!notificationsData[b]) {
  //             return -1;
  //           }
  //           return (
  //             notificationsData[b].timestamp - notificationsData[a].timestamp
  //           );
  //         })
  //       : [];
  //   return (
  //     <Paper className={styles.root_paper} style={{ marginTop: 10 }}>
  //       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //         <p className={styles.header_font}>Action Needed</p>
  //         <div
  //           onClick={() => {
  //             clearAllNotifications();
  //           }}
  //           style={{ fontWeight: 700, cursor: 'pointer', paddingRight: 16 }}
  //         >
  //           Clear All
  //         </div>
  //       </div>
  //       {sortedAlerts.map(notificationId => {
  //         let icon;
  //         if (notificationsAlerts[notificationId] === 'redFlag') {
  //           icon = InfoIcon;
  //         }
  //         if (notificationsAlerts[notificationId] === 'greenFlag') {
  //           icon = FlagIcon;
  //         }
  //         if (notificationsAlerts[notificationId] === 'yellowMessage') {
  //           icon = YellowMailIcon;
  //         }
  //         if (
  //           notificationsAlerts[notificationId] === 'blueMessage' ||
  //           notificationsAlerts[notificationId] === 'message'
  //         ) {
  //           icon = BlueMailIcon;
  //         }
  //         if (notificationsAlerts[notificationId] === 'redMessage') {
  //           icon = RedMailIcon;
  //         }

  //         return (
  //           <div key={notificationId} className={styles.action_item}>
  //             <div className={styles.action_item_data}>
  //               <img
  //                 src={icon}
  //                 width={20}
  //                 height={20}
  //                 style={{ marginRight: 15 }}
  //               />
  //               <div>
  //                 <div className={styles.action_item_description}>
  //                   {notificationsData[notificationId] &&
  //                     notificationsData[notificationId].description}
  //                 </div>
  //                 <div className={styles.action_item_date}>
  //                   {notificationsData[notificationId] &&
  //                     timeConverter(
  //                       notificationsData[notificationId].timestamp
  //                     )}
  //                 </div>
  //               </div>
  //             </div>
  //             {/* <CloseIcon
  //               onClick={() => {
  //                 removeActionNeededClicked(notificationId);
  //               }}
  //               style={{
  //                 color: '#9F9F9F',
  //                 marginRight: 40,
  //                 cursor: 'pointer',
  //                 marginBottom: 20,
  //               }}
  //             /> */}
  //             <Button
  //               onClick={() => {
  //                 removeActionNeededClicked(notificationId);
  //                 userPageLogger.log('notifications_clicked_as_done', {
  //                   notificationId,
  //                   notificationType: notificationsAlerts[notificationId],
  //                   teamMemberId: sessionStorage.teamMemberId,
  //                 });
  //               }}
  //               style={{
  //                 color: '#0076F5',
  //                 textTransform: 'none',
  //                 fontSize: 13,
  //               }}
  //             >
  //               Done
  //             </Button>
  //           </div>
  //         );
  //       })}
  //     </Paper>
  //   );
  // };

  const extractContent = s => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const onClearNotifications = () => {
    const updatedNotifications = {};
    Object.keys(notificationsAlerts).forEach(notificationId => {
      if (
        notificationsAlerts[notificationId] === 'blueMessage' ||
        notificationsAlerts[notificationId] === 'message' ||
        notificationsAlerts[notificationId] === 'redMessage' ||
        notificationsAlerts[notificationId] === 'yellowMessage'
      ) {
        updatedNotifications[notificationId] =
          notificationsAlerts[notificationId];
      }
    });
    editNotificationsAlerts(updatedNotifications);
  };

  const renderActivityContent = () => {
    // eslint-disable-next-line no-console

    const patientMessegesObj = patientMesseges ? { ...patientMesseges } : {};
    const notificationsDataObj = notificationsData
      ? { ...notificationsData }
      : {};

    const messagesAndNotifications = {
      ...patientMessegesObj,
      ...notificationsDataObj,
    };

    const sortedMessagesAndNotifications = messagesAndNotifications
      ? Object.keys(messagesAndNotifications).sort((a, b) => {
          return (
            messagesAndNotifications[a].timestamp -
            messagesAndNotifications[b].timestamp
          );
        })
      : [];

    const renderActivityText = activityId => {
      if (!messagesAndNotifications[activityId]) {
        return null;
      }
      return (
        <>
          {messagesAndNotifications[activityId].message ? (
            <>
              <div className={styles.activity_item_sender}>
                {messagesAndNotifications[activityId].sender === 'clinic' ||
                messagesAndNotifications[activityId].sender === 'server'
                  ? 'You'
                  : customerInfo.firstName}
              </div>
              <div
                className={styles.activity_item_description}
                style={
                  messagesAndNotifications[activityId].sender === 'clinic' ||
                  messagesAndNotifications[activityId].sender === 'server'
                    ? {
                        backgroundColor: '#ebf4ff',
                        borderRadius: '20px 20px 0 20px',
                      }
                    : null
                }
              >
                {messagesAndNotifications[activityId].message
                  .split('\\n')
                  .map(str => (
                    <div
                      style={{
                        minHeight: 20,
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {str}
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Tooltip
              title={<TooltipTitle>This is only visible to you.</TooltipTitle>}
            >
              <div className={styles.activity_item_description}>
                {messagesAndNotifications[activityId].description}
              </div>
            </Tooltip>
          )}
        </>
      );
    };

    const renderActivityMessage = activityId => {
      return (
        <div className={styles.activity_item} key={activityId}>
          <div style={{ maxWidth: '70%' }}>
            <div>{renderActivityText(activityId)}</div>
            <div className={styles.activity_item_date}>
              {messagesAndNotifications?.[activityId]?.sender === 'server' ? (
                <span
                  style={{ fontWeight: 900, color: '#737381', marginRight: 10 }}
                >
                  Auto-message
                </span>
              ) : null}
              {messagesAndNotifications[activityId] &&
                timeConverter(messagesAndNotifications[activityId].timestamp)}
            </div>
          </div>
        </div>
      );
    };

    const isNotifyByEmailEnabled = notifyByEmail || false;

    const shouldShowABTestError =
      humanCentricAutomatedTriggers === mtrAutomatedTriggersDisabledVariant &&
      getOnlyNumbersFromString(userAppVersion).length === 3;

    const userHasUnreadNotifications =
      notificationsAlerts &&
      Object.keys(notificationsAlerts).find(
        notificationId =>
          notificationsAlerts[notificationId] === 'redFlag' ||
          notificationsAlerts[notificationId] === 'greenFlag'
      );

    return (
      <Paper
        className={styles.root_paper}
        style={
          userHasUnreadNotifications
            ? { marginTop: 10, cursor: 'pointer' }
            : { marginTop: 10 }
        }
        onClick={userHasUnreadNotifications ? onClearNotifications : () => {}}
      >
        <div className={styles.activity_title_container}>
          <div style={{ position: 'relative' }}>
            <p className={styles.header_font} style={{ marginBottom: 0 }}>
              Activity
              {userHasUnreadNotifications && (
                <div
                  style={{
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    width: 10,
                    height: 10,
                    position: 'absolute',
                    top: 0,
                    left: 60,
                  }}
                />
              )}
            </p>
            <div style={{ marginTop: 10 }}>
              Note: Client can't reply to messages.
            </div>
          </div>
          <Tooltip
            title={
              <TooltipTitle>
                Get updated when the client starts a session or finishes a
                program.
              </TooltipTitle>
            }
          >
            <div
              className={styles.email_on_activity}
              style={
                isNotifyByEmailEnabled
                  ? {
                      backgroundColor: '#e5f1ff',
                      color: '#41a5f5',
                      border: 'solid 1px #41a5f5',
                    }
                  : {
                      backgroundColor: 'white',
                      color: 'gray',
                      border: 'solid 1px gray',
                    }
              }
              onClick={() => {
                onNotifyByEmailChange(
                  null,
                  notifyByEmail ? !notifyByEmail : true
                );
              }}
            >
              {/* <img
                src={
                  isNotifyByEmailEnabled
                    ? enabledEmailNotifActivityPNG
                    : disabledEmailNotifActivityPNG
                }
                style={
                  isNotifyByEmailEnabled
                    ? { color: '#41a5f5' }
                    : { color: 'gray' }
                }
                className={styles.email_activity_icon}
              /> */}
              <EMailIcon
                style={
                  isNotifyByEmailEnabled
                    ? { color: '#41a5f5', fontSize: 14 }
                    : { color: 'gray', fontSize: 14 }
                }
              />
              <span style={{ marginLeft: 5 }}>Email me on activity</span>
            </div>
          </Tooltip>
        </div>
        <div className={styles.activity_list} id="scroll_div">
          {sortedMessagesAndNotifications.length ? (
            // eslint-disable-next-line array-callback-return, consistent-return
            sortedMessagesAndNotifications.map(activityId => {
              return renderActivityMessage(activityId);
            })
          ) : (
            <div className={styles.empty_messages_container}>
              <img
                src={emptyActivityPNG}
                className={styles.empty_messages_img}
              />
              <div className={styles.empty_messages_title}>
                Nothing here yet
              </div>
              <div className={styles.empty_messages_description}>
                Send the client a message and view their activity logs once they
                start using the app.
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            // backgroundColor: '#f5f5f560',
            marginTop: 10,
            justifySelf: 'flex-end',
            position: 'relative',
          }}
          className={styles.inputs}
        >
          <TextField
            multiline
            value={clinicianMessageInput}
            onChange={e => {
              if (e.key === 'Enter') {
                return;
              }
              setClinicianMessageInput(e.target.value);
            }}
            // label="Meesage"
            placeholder="Write your message here"
            variant="outlined"
            color="primary"
            fullWidth
            style={{
              margin: '10px 15px 10px',
              maxWidth: '95%',
            }}
            InputProps={{
              style: {
                fontSize: 14,
                lineHeight: 1.5,
                paddingRight: 30,
              },
              // classes: {
              //   input: styles.resize,
              //   root: styles.outlinedInput,
              // },
            }}
            margin="dense"
            // onKeyDown={handleKeyDown}
          />
          <span
            style={{
              position: 'absolute',
              // top: 28,
              bottom: 20,
              right: 40,
              color: clinicianMessageInput.trim() ? '#0076F5' : 'gray',
              cursor: clinicianMessageInput.trim() ? 'pointer' : 'auto',
            }}
            onClick={onSendMessage}
          >
            <SendIcon />
          </span>
        </div>
        <div
          className={styles.messeges_bottom_part_container}
          style={
            !shouldShowABTestError
              ? {
                  marginLeft: 15,
                  // eslint-disable-next-line no-nested-ternary
                  justifyContent:
                    userAppVersion &&
                    (getOnlyNumbersFromString(userAppVersion).length === 3
                      ? getOnlyNumbersFromString(userAppVersion) <
                        getOnlyNumbersFromString(humanCentricAppVersion.iOS.min)
                      : getOnlyNumbersFromString(userAppVersion) <
                        getOnlyNumbersFromString(
                          humanCentricAppVersion.Android.min
                        ))
                      ? 'space-between'
                      : 'end',
                }
              : null
          }
        >
          {shouldShowABTestError ? (
            <div style={{ color: 'red', marginLeft: 20 }}>
              This client is not part of the one-way messaging experiment and
              will not receive the messages you send.
            </div>
          ) : null}
          {// eslint-disable-next-line no-nested-ternary
          userAppVersion &&
          (getOnlyNumbersFromString(userAppVersion).length === 3
            ? getOnlyNumbersFromString(userAppVersion) <
              getOnlyNumbersFromString(humanCentricAppVersion.iOS.min)
            : getOnlyNumbersFromString(userAppVersion) <
              getOnlyNumbersFromString(humanCentricAppVersion.Android.min)) ? (
            shouldShowABTestError ? null : (
              <div style={{ marginRight: 10 }}>
                <CustomAlert severity="warning">
                  Client needs to update their app in order to receive these
                  messages.
                </CustomAlert>
              </div>
            )
          ) : null}
          <div className={styles.messeges_btns_container}>
            <Tooltip
              title={<TooltipTitle>Also notify client via email</TooltipTitle>}
            >
              <img
                src={
                  notifyAutomationsMessagesByEmail
                    ? enabledNotifByEmailPNG
                    : disabledEmailNotifPNG
                }
                className={styles.messeges_btn}
                onClick={() => {
                  AutomationsLogger.log('notify_clinet_email_toggle', {
                    enalbled: !notifyAutomationsMessagesByEmail,
                    user_Id: patientId,
                  });
                  onNotifyCustomerOnMessegesChange();
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Paper>
    );
  };

  return notDeletedReports === null || customerInfo === null ? (
    <div className={styles.loading_container}>
      <CircularProgress />
    </div>
  ) : (
    <React.Fragment>
      <BlurOverlayIfContinuityCustomer
        headsetDetails={headsetDetails}
        onHeadsetDetailsUpdated={onHeadsetDetailsUpdated}
      >
        {renderNotes()}
        {numOfDoneAssessment || numOfSessions ? renderTabContent() : null}
        {/* {notificationsData &&
          notificationsAlerts &&
          Object.keys(notificationsAlerts).length &&
          renderActionContent()} */}
        {renderActivityContent()}
      </BlurOverlayIfContinuityCustomer>
      <DeleteReportDialog
        isOpen={isDeleteReportDialogOpen}
        onDelete={onReportDeleteConfirm}
        onCancel={onCancelDeleteReportDialog}
      />
      <FeatureNotAllowedDialog
        text="You need to upgrade your plan in order to view the saved the report and share it with your client"
        isOpen={isFeatureNotAllowedDialogOpen}
        onDismiss={onFeatureNotAllowedDialogDismiss}
      />
      {reportToBeShared && (
        <ShareReportDialog
          isOpen={isShareReportDialogOpen}
          onClose={() => setIsShareReportDialogOpen(false)}
          patientName={reportToBeShared.patientName}
          reportLink={reportToBeShared.reportLink}
          onCopyLinkToClipboard={inputElm => {
            inputElm.select();
            document.execCommand('copy');
            showNotification('success', 'Copied to clipboard');
          }}
          onNotifyByEmail={async () => {
            await fireFunctionPost('clinic_apis-sessionsReportEmail', {
              link: reportToBeShared.reportLink,
              email: reportToBeShared.patientEmail,
            });
            showNotification('success', 'Email was just sent');
          }}
          patientEmail={reportToBeShared.patientEmail}
          shouldShowViewReportBtn={false}
          onPatientEmailChange={event => {
            const newReportToBeShared = { ...reportToBeShared };
            newReportToBeShared.patientEmail = event.target.value;
            setReportToBeShared(newReportToBeShared);
          }}
          shouldHideShareInAppToggle
          shouldShowNameOnTitle
        />
      )}
      <AddNoteDialog
        isOpen={isNoteDialogOpen}
        onSave={onSaveNote}
        onClose={() => {
          setIsNoteDialogOpen(false);
          setNoteContent(EditorState.createEmpty());
          setEditNoteId('');
        }}
        onCancel={() => {
          if (
            !editNoteId &&
            extractContent(
              draftToHtml(convertToRaw(noteContent.getCurrentContent()))
            ).trim(' ')
          ) {
            setIsCancelNoteDialogOpen(true);
            return;
          }
          if (
            editNoteId &&
            customerNotes[editNoteId].text !==
              draftToHtml(convertToRaw(noteContent.getCurrentContent()))
          ) {
            setIsCancelNoteDialogOpen(true);
            return;
          }
          setIsNoteDialogOpen(false);
          setNoteContent(EditorState.createEmpty());
          setEditNoteId('');
          userPageLogger.log('clinet_notes_saved', {
            customerId: patientId,
            action: 'cancel_attempt',
          });
        }}
        noteContent={noteContent}
        setNoteContent={setNoteContent}
        isEdit={!!editNoteId}
        extractContent={extractContent}
      />
      <DeleteNoteDialog
        isOpen={!!isDeleteNoteDialogOpen}
        onDelete={onDeleteNote}
        onCancel={() => {
          setIsDeleteNoteDialogOpen('');
        }}
      />
      <CancelNoteDialog
        isOpen={!!isCancelNoteDialogOpen}
        onDiscard={() => {
          setIsCancelNoteDialogOpen(false);
          setIsNoteDialogOpen(false);
          setNoteContent(EditorState.createEmpty());
          setEditNoteId('');
          userPageLogger.log('clinet_notes_saved', {
            customerId: patientId,
            action: 'cancel_after_confirm',
          });
        }}
        onCancel={() => {
          setIsCancelNoteDialogOpen(false);
          userPageLogger.log('clinet_notes_saved', {
            customerId: patientId,
            action: 'cancel_the_cancel',
          });
        }}
      />
    </React.Fragment>
  );
};

ReportsTabBase.propTypes = exact({
  patientId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  headsetDetails: PropTypes.object.isRequired,
  onHeadsetDetailsUpdated: PropTypes.func.isRequired,
  notificationsAlerts: PropTypes.object,
  notificationsData: PropTypes.object,
  editNotificationsAlerts: PropTypes.func,
  // deleteNotificationsAlerts: PropTypes.func,
  // clearAllNotifications: PropTypes.func,
  customerNotes: PropTypes.object,
  setCustomerNotes: PropTypes.func,
  addCustomerNote: PropTypes.func,
  removeCustomerNote: PropTypes.func,
  trainingSessions: PropTypes.object,
  patientMesseges: PropTypes.object,
  // deployedProgram: PropTypes.object,
  onNotifyCustomerOnMessegesChange: PropTypes.func,
  onNotifyByEmailChange: PropTypes.func,
  notifyAutomationsMessagesByEmail: PropTypes.bool,
  notifyByEmail: PropTypes.bool,
  userAppVersion: PropTypes.string,
  humanCentricAppVersion: PropTypes.object,
});

export const ReportsTab = withEntryComponent(
  withStyles(stylesFn)(ReportsTabBase)
);
