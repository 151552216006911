import React, { useRef } from 'react';
import exact from 'prop-types-exact';
import {
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { capitalize, groupBy } from 'lodash';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import { useFirebaseDB } from '../../../Core/hooks/useFirebaseDB';
import { useCustomerInfo } from '../../../Core/hooks/useCustomerInfo';
import { injectIDs } from '../../../utils/utils';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import styles from './InsightsTab.scss';
import { Loader } from '../../../Core/Components/Loader/Loader';
import { getInsightItemDetails } from '../../../models/insights/insights';
import { InsightsBarsGraph } from '../../InsightsGraphs/InsightsBarsGraph/InsightsBarsGraph';
import { DAY_JS_DATE_FORMATS } from '../../../utils/constants';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';

dayjs.extend(dayjsCustomParseFormat);

const initialValue = Symbol('IV');
const InsightsTabBase = () => {
  const customerInfo = useCustomerInfo();
  const t = useTranslation();

  const [insightItems] = useFirebaseDB({
    path: `insights/${customerInfo.id}/items`,
    initialValue,
    defaultValue: useRef({}).current,
  });

  const [insightsConfig] = useFirebaseDB({
    path: 'insightsConfig',
    initialValue,
  });

  const isComponentReadyToRender =
    insightItems !== initialValue && insightsConfig !== initialValue;

  const insights = Object.values(injectIDs({ ...insightItems }))
    .filter(insight => insight.timestamp)
    .sort((insight1, insight2) => insight2.timestamp - insight1.timestamp);

  const insightsGroupedByStartDate = groupBy(insights, insight => {
    return dayjs(insight.timestamp)
      .startOf('month')
      .format(DAY_JS_DATE_FORMATS.american);
  });

  const renderInsightItem = insightItem => {
    const insightItemDetails = getInsightItemDetails({
      insightItem,
      insightsConfig,
    });

    if (insightItemDetails) {
      const {
        titleParts,
        message,
        graphData,
        protocolName,
        date,
        icon,
        unit,
        iconWidth,
        iconTopMargin,
        yAxisDashboard,
        valueColor,
        isGraphHidden,
      } = insightItemDetails;

      return (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              expanded: styles.expansion_panel_expanded,
              content: styles.expansion_panel_summary_content,
            }}
          >
            <div className={styles.insight_overview}>
              <div className={styles.icon_container}>
                <img
                  src={icon}
                  style={{ width: iconWidth, marginTop: iconTopMargin }}
                />
              </div>
              <div>
                <p className={styles.insight_title}>
                  {titleParts[0]} -{' '}
                  <span style={{ color: valueColor || 'initial' }}>
                    {titleParts[1]}
                  </span>
                </p>
                <p className={styles.insight_message}>{message}</p>
              </div>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <InsightsBarsGraph
              data={graphData}
              yAxisLabel={yAxisDashboard}
              {...{
                protocolName,
                date,
                unit,
                isGraphHidden,
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    return null;
  };

  return isComponentReadyToRender ? (
    <>
      <Paper className={styles.paper}>
        <p className={styles.paper_header}>{capitalize(t('insights'))}</p>
        <p>
          {Object.entries(insightsGroupedByStartDate).length === 0
            ? t('insights-empty-description')
            : t('insights-description')}
        </p>
        {Object.entries(insightsGroupedByStartDate).length !== 0 && (
          <div className={styles.insights_release_warning}>
            <CustomAlert severity="info">
              {t('insights-can-be-added-to-report')}
            </CustomAlert>
          </div>
        )}
      </Paper>
      {Object.entries(insightsGroupedByStartDate).map(([startDate, items]) => {
        const endDate = dayjs(startDate, DAY_JS_DATE_FORMATS.american)
          .endOf('month')
          .format(DAY_JS_DATE_FORMATS.american);

        return (
          items.some(item =>
            getInsightItemDetails({
              insightItem: item,
              insightsConfig,
            })
          ) && (
            <div key={startDate}>
              <p className={styles.date_header}>
                {startDate} - {endDate}
              </p>
              <div className={styles.insights}>
                {items.map(insightItem => (
                  <div key={insightItem.id}>
                    {renderInsightItem(insightItem)}
                  </div>
                ))}
              </div>
            </div>
          )
        );
      })}
    </>
  ) : (
    <Loader />
  );
};

InsightsTabBase.propTypes = exact({});

export const InsightsTab = React.memo(InsightsTabBase);
InsightsTab.displayName = 'InsightsTab';
